import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import '../../App.css';
import InputAutoComplete from '../../components/Inputs/InputAutoComplete/InputAutoComplete';
import InputData from '../../components/Inputs/InputData/InputData';
import InputSelect from '../../components/Inputs/InputSelect/InputSelect';
import Pagina from '../../components/Pagina/Pagina';
import api from '../../services/api';
import { getFiltroPadraoRelatorioNotasCompra } from '../../utils/filtros';
import { gridColumn } from '../../utils/gridColumn';
import { ajustaDatas, optionsDatas } from '../../utils/utils';
import { baixarArquivoBase64 } from '../../utils/download';
import InputFiltro from '../../components/Inputs/InputFiltro/InputFiltro';
import Filtros from '../../components/Filtros/Filtros';
import FormikPadrao from '../../components/FormikPadrao/FormikPadrao';
import BarraBotoes from '../../components/BarraBotoes/BarraBotoes';
import TotalizadorRelatorio from '../../components/TotalizadorRelatorio/TotalizadorRelatorio';
import Tabela from '../../components/Tabela/Tabela';

function RelNotasCompra() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [search] = useSearchParams();
  const [searchBar, setSearchBar] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [relNotasCompra, setRelNotasCompra] = useState([]);
  const [totalIPI, setTotalIPI] = useState(0);
  const [totalICMS, setTotalICMS] = useState(0);
  const [totalDescontos, setTotalDescontos] = useState(0);
  const [totalNotas, setTotalNotas] = useState(0);

  async function GetRelNotasCompra(filtros) {
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(filtros, null, 2),
    };
    let res = await api.post('/NotasCompraReport/GetList', payload);
    let data = res.data;
    if (res.status === 200) {
      setRelNotasCompra(data.Notas);
      if (data.Notas && Object.keys(data.Notas).length > 0) {
        let IPI = 0;
        let ICMS = 0;
        let descontos = 0;
        let notas = 0;

        data.Notas.map(rel => (IPI += rel.IPIValor));

        data.Notas.map(rel => (ICMS += rel.ICMS_Soma));

        data.Notas.map(rel => (descontos += rel.Desconto));

        data.Notas.map(rel => (notas += rel.ValorTotalDaNota));

        setTotalIPI(IPI);
        setTotalICMS(ICMS);
        setTotalDescontos(descontos);
        setTotalNotas(notas);
      } else {
        setTotalIPI(0);
        setTotalICMS(0);
        setTotalDescontos(0);
        setTotalNotas(0);
      }
      setLoading(false);
    } else {
      alert('Erro ao buscar dados');
    }
  }

  useEffect(() => {
    GetRelNotasCompra(getFiltroPadraoRelatorioNotasCompra());
  }, []);

  useEffect(() => {
    if (search.get('filter')) {
      setShowFilters(search.get('filter') === 'true');
      GetRelNotasCompra(getFiltroPadraoRelatorioNotasCompra());
    }
  }, [search]);

  function handleEdit(item) {
    navigate(`/NotasCompra/Editar/${item.Id}`);
  }

  const getPDF = async () => {
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(relNotasCompra, null, 2),
    };
    baixarArquivoBase64('/NotasCompraReport/GetPDF', payload);
  };

  const getExcel = async () => {
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(relNotasCompra, null, 2),
    };
    baixarArquivoBase64('/NotasCompraReport/GetExcel', payload);
  };

  return (
    <Pagina loading={loading}>
      <FormikPadrao
        initialValues={getFiltroPadraoRelatorioNotasCompra()}
        onSubmit={async values => {
          await GetRelNotasCompra(values);
        }}
      >
        <BarraBotoes
          maxWindowSize={1030}
          botoes={[
            {
              children: 'Exportar Planilha',
              onClick: getExcel,
              variant: 'verde',
            },
            {
              children: 'Exportar PDF',
              onClick: getPDF,
              variant: 'rosa',
            },
          ]}
          search={{
            placeholder: 'Procurar por lançamentos',
            onChange: e => setSearchBar(e.target.value),
          }}
          showFilters={showFilters}
          setShowFilters={setShowFilters}
        />
        <FilterMenu showFilters={showFilters} setShowFilters={setShowFilters} />
        <TotalizadorRelatorio
          items={[
            {
              Titulo: 'TOTAL DE IPI',
              Valor: totalIPI,
              type: 'money',
            },
            {
              Titulo: 'TOTAL DE ICMS',
              Valor: totalICMS,
              type: 'money',
            },
            {
              Titulo: 'TOTAL DE DESCONTOS',
              Valor: totalDescontos,
              type: 'money',
            },
            {
              Titulo: 'VALOR TOTAL DAS NOTAS',
              Valor: totalNotas,
              type: 'money',
            },
          ]}
        />
        <Tabela
          titulo={'RELATÓRIO DE NOTAS DE COMPRAS'}
          setFunction={setRelNotasCompra}
          data={relNotasCompra}
          columns={[
            {
              placeholder: 'CÓDIGO',
              name: 'Codigo',
              type: 'text',
            },
            {
              placeholder: 'EMPRESA',
              name: 'Empresa',
              type: 'text',
            },
            {
              placeholder: 'NÚMERO',
              name: 'NumeroNota',
              type: 'text',
            },
            {
              placeholder: 'DATA DE ENTRADA',
              name: 'DataEntradaNota',
              type: 'date',
            },
            {
              placeholder: 'FORNECEDOR',
              name: 'Fornecedor',
              type: 'text',
            },
            {
              placeholder: 'TRANSPORTADORA',
              name: 'Transportadora',
              type: 'text',
            },
            {
              placeholder: 'SÉRIE',
              name: 'SerieNota',
              type: 'text',
            },
            {
              placeholder: 'CFOP',
              name: 'CFOP',
              type: 'text',
            },
            {
              placeholder: 'IPI',
              name: 'IPIValor',
              type: 'money',
            },
            {
              placeholder: 'ICMS',
              name: 'ICMSValor',
              type: 'money',
            },
            {
              placeholder: 'ICMS ST',
              name: 'ICMS_STValor',
              type: 'money',
            },
            {
              placeholder: 'ICMS + ICMS ST',
              name: 'ICMS_Soma',
              type: 'money',
            },
            {
              placeholder: 'DESPESAS',
              name: 'Despesas',
              type: 'money',
            },
            {
              placeholder: 'DESCONTO',
              name: 'Desconto',
              type: 'money',
            },
            {
              placeholder: 'FRETE',
              name: 'ValorFrete',
              type: 'money',
            },
            {
              placeholder: 'VALOR TOTAL',
              name: 'ValorTotalDaNota',
              type: 'money',
            },
          ]}
          searchBar={searchBar}
          handleEdit={handleEdit}
        />
      </FormikPadrao>
    </Pagina>
  );
}

function FilterMenu({ showFilters, setShowFilters }) {
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    ajustaDatas(values.PeriodoLancamento, setFieldValue);
  }, [values.PeriodoLancamento, setFieldValue]);

  return (
    <Filtros showFilters={showFilters} setShowFilters={setShowFilters}>
      <InputFiltro
        titulo="FORNECEDOR"
        id="Fornecedor"
        name="Fornecedor"
        component={InputAutoComplete}
        autocomplete={true}
        path={'ClienteFornecedor'}
        database={localStorage.getItem('database')}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="EMPRESA"
        id="Empresa"
        name="Empresa"
        component={InputAutoComplete}
        autocomplete={true}
        path={'Empresa'}
        database={localStorage.getItem('database')}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="TRANSPORTADORA"
        id="Transportadora"
        name="Transportadora"
        component={InputAutoComplete}
        autocomplete={true}
        path={'Transportadoras'}
        database={localStorage.getItem('database')}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="PERÍODO"
        id="PeriodoLancamento"
        name="PeriodoLancamento"
        component={InputSelect}
        options={optionsDatas}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="Data Inicial"
        id="DataInicial"
        name="DataInicial"
        component={InputData}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="Data Final"
        id="DataFinal"
        name="DataFinal"
        component={InputData}
        gridColumn={gridColumn.gc4}
      />
    </Filtros>
  );
}

export default RelNotasCompra;
