import { Box } from '@chakra-ui/react';
import React from 'react';
import { gridColumn } from '../../utils/gridColumn';

import '../../App.css';
import './DashboardCard.css';

function DashboardCard({ titulo, subtitulo, children, borderColor }) {
  return (    
    <Box className="dashboardCardContainer" height='100%'>
      <Box className="dashboardCardContainerCabecario" borderColor={borderColor}>
        <h3 className="titulo">{titulo}</h3>
        <p className="paragrafo">{subtitulo}</p>
      </Box>
      <Box gridColumn={gridColumn.gc8} className="conteudo" height="100%">
        {children}
      </Box>
    </Box>
  );
}

export default DashboardCard;
