import React, { useEffect, useState } from 'react';
import Alerta from '../../components/Alerta/Alerta';
import Pagina from '../../components/Pagina/Pagina';
import { Box, useDisclosure } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid'
import FormPadrao from '../../components/FormPadrao/FormPadrao';
import Card from '../../components/Card/Card';
import { gridColumn } from '../../utils/gridColumn';
import ptBrLocale from '@fullcalendar/core/locales/pt-br';
import api from '../../services/api';
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'

function Agenda() {
  const navigate = useNavigate();
  const [eventos, setEventos] = useState([]);
  const [loading, setLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false });
  const [alertStatus, setAlertStatus] = useState('success');
  const [alertTitle, setAlertTitle] = useState('');

  useEffect(() => {
    async function GetEventos() {
      let payload = {
        database: localStorage.getItem('database'),
      };
      let res = await api.post('/ListEventos/Get', payload);
      if (res.status === 200) {
        console.log(res.data.Eventos)
        setEventos(res.data.Eventos);
        setLoading(false);
      } else {
        alert('Erro ao buscar dados');
      }
    }
    GetEventos();
  }, []);
  return (
    <>
      <Alerta
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        alertStatus={alertStatus}
        title={alertTitle}
      />
      <Pagina loading={loading}>
        <FormPadrao>
          <Card titulo={"Calendário"}>
            <Box p={2} gridColumn={gridColumn.gc12}>
              <div style={{ 'flex-grow': 1 }}>

                <FullCalendar
                  locale={ptBrLocale}
                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                  headerToolbar={{
                    left: 'prev,next today',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay'
                  }}
                  initialView='dayGridMonth'
                  weekends={true}
                  events={eventos}
                  eventContent={renderEventContent}
                  aspectRatio={2.5}
                />
              </div>
            </Box>
          </Card>
        </FormPadrao>

      </Pagina >
    </>
  );
}

function renderEventContent(eventInfo) {
  return (
    <>
      <b>{eventInfo.timeText}</b>
      <i>{eventInfo.event.title}</i>
    </>
  )
}
export default Agenda;
