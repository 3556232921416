import '../../App.css';
import Loader from '../Loader/Loader';
import './PaginaPDV.css';
import  HeaderPDV  from './HeaderPDV/HeaderPDV';
import { Flex } from '@chakra-ui/react';

function PaginaSemHeader({ loading, children }) {
  return (
    <div className="paginaInteiraPDV">     
        <Flex className="cadastroInteiroPDV" flexDirection="column" justifyContent="flex-start">
            <HeaderPDV />
            {!loading && { ...children }}
            {loading && <Loader />}
        </Flex>
    </div>
  );
}

export default PaginaSemHeader;
