import React, { useEffect, useState } from 'react';
import './InputImagens.css';

function InputImagens() {
  const [imagens, setImagens] = useState([]);
  const [imagensURL, setImagensURL] = useState([]);

  useEffect(() => {
    if (imagens.length < 1) return;
    const novaImagemURL = [];
    imagens.forEach(imagem => novaImagemURL.push(URL.createObjectURL(imagem)));
    setImagensURL(novaImagemURL);
  }, [imagens]);

  function onImageChange(event) {
    setImagens([...event.target.files]);
  }

  return (
    <div>
      <input
      type="file"
      multiple accept="image/*"
      onChange={onImageChange}
    />
    { imagensURL.map(imageSrc => <img className="inputimagem" src={imageSrc} alt="imagem carregada" />) }
    </div>
  );
}

export default InputImagens;
