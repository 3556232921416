import {
  useDisclosure
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import '../../App.css';
import Alerta from '../../components/Alerta/Alerta';
import AlertaConfirmacao from '../../components/AlertaConfirmacao/AlertaConfirmacao';
import Pagina from '../../components/Pagina/Pagina';
import api from '../../services/api';
import BarraBotoes from './../../components/BarraBotoes/BarraBotoes';
import FormPadrao from './../../components/FormPadrao/FormPadrao';
import Tabela from './../../components/Tabela/Tabela';
import './OperacoesFiscais.css';

function ListarOperacoesFiscais() {
  const [operacoesFiscais, setOperacoesFiscais] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchBar, setSearchBar] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false });
  const [alertStatus, setAlertStatus] = useState('success');
  const {
    isOpen: isOpenExcluir,
    onOpen: onOpenExcluir,
    onClose: onCloseExcluir,
  } = useDisclosure({
    defaultIsOpen: false,
  });
  const [alertTitle, setAlertTitle] = useState('');
  const [message, setMessage] = useState('');
  const [selectedItem, setSelectedItem] = useState('');

  useEffect(() => {
    async function GetOperacoesFiscais() {
      let payload = {
        database: localStorage.getItem('database'),
        data: '{}',
      };
      let res = await api.post('/ListOperacoesFiscais/Get', payload);
      if (res.status === 200) {
        let data = res.data;
        data.OperacoesFiscais.forEach(b => {
          b.Selecionado = false;
        });
        setOperacoesFiscais(data.OperacoesFiscais);
        setLoading(false);
      } else {
        alert('Erro ao buscar dados');
      }
    }
    GetOperacoesFiscais();
  }, []);

  function handleDelete(item) {
    setSelectedItem(item.Id);
    setAlertTitle('Excluir');
    setMessage('Deseja excluir a operação fiscal?');
    onOpenExcluir();
  }

  let deleteItem = async () => {
    let payload = {
      data: selectedItem,
      database: localStorage.getItem('database'),
    };
    let res = await api.post('/ListOperacoesFiscais/Delete', payload);
    let data = res.data;
    onCloseExcluir();
    if (data.Success) {
      setAlertStatus('success');
      setAlertTitle(data.MSG);
      onOpen();
    } else {
      setOperacoesFiscais(operacoesFiscais.filter(item => item.Id !== selectedItem));
      setAlertStatus('error');
      setAlertTitle(data.MSG);
      onOpen();
    }
  };
  return (
    <>
      <AlertaConfirmacao
        isOpen={isOpenExcluir}
        onClose={onCloseExcluir}
        description={message}
        title={alertTitle}
        onConfirm={() => deleteItem()}
      />
      <Alerta
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        alertStatus={alertStatus}
        title={alertTitle}
      />
      <Pagina loading={loading}>
        <FormPadrao>
          <BarraBotoes
            botoes={[]}
            search={{
              placeholder: 'Procurar por operações fiscais',
              onChange: e => setSearchBar(e.target.value),
            }}
          />
          <Tabela
            useCheck={false}
            titulo={'LISTAGEM DE OPERAÇÕES FISCAIS'}
            setFunction={setOperacoesFiscais}
            data={operacoesFiscais}
            columns={[
              {
                placeholder: 'NOME',
                name: 'Nome',
                type: 'text',
              },
              {
                placeholder: 'SITUAÇÃO TRIBUTÁRIA',
                name: 'SituacaoTributaria',
                type: 'text',
              },
              {
                placeholder: 'ESTADO',
                name: 'DestinoEstado',
                type: 'text',
              },
              {
                placeholder: 'PIS',
                name: 'PIS',
                type: 'number',
              },
              {
                placeholder: 'COFINS',
                name: 'COFINS',
                type: 'number',
              },
              {
                placeholder: 'ICMS',
                name: 'ICMS_Interestadual',
                type: 'number',
              },
            ]}
            searchBar={searchBar}
            handleDelete={handleDelete}
          />
        </FormPadrao>
      </Pagina>
    </>
  );
}

export default ListarOperacoesFiscais;
