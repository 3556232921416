import React from 'react';
import './InputTexto.css';

import { Input } from '@chakra-ui/react';

function InputTexto({ placeholder, ...rest }) {
  return (
    <Input
      className="inputtexto"
      type="text"
      placeholder={placeholder}
      {...rest}
    />
  );
}

export default InputTexto;
