import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import '../../App.css';
import BarraBotoes from '../../components/BarraBotoes/BarraBotoes';
import FormPadrao from '../../components/FormPadrao/FormPadrao';
import Pagina from '../../components/Pagina/Pagina';
import Tabela from '../../components/Tabela/Tabela';
import api from '../../services/api';
import './Servicos.css';
import Importacao from '../../components/Importacao/Importacao';
import { useDisclosure } from '@chakra-ui/react';
import Alerta from '../../components/Alerta/Alerta';

function ListarServicos() {
  const navigate = useNavigate();
  const [servicos, setServicos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchBar, setSearchBar] = useState('');
  const [importacao, setImportacao] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false });
  const [alertStatus, setAlertStatus] = useState('success');
  const [alertTitle, setAlertTitle] = useState('');

  useEffect(() => {
    async function GetServicos() {
      let payload = {
        database: localStorage.getItem('database'),
        data: '{IsServico: true}',
      };
      let res = await api.post('/ListProdutos/Get', payload);
      if (res.status === 200) {
        let data = res.data;
        data.Produtos.forEach(b => {
          b.Selecionado = false;
        });
        setServicos(data.Produtos);
        setLoading(false);
      } else {
        alert('Erro ao buscar dados');
      }
    }
    GetServicos();
  }, []);

  function handleEdit(item) {
    navigate(`/Servicos/Editar/${item.Id}`);
  }

  function handleClick() {
    navigate('/Servicos/Editar');
  }

  const uploadEmLote = async event => {
    const files = event.target.files;
    Array.from(files).forEach(async file => {
      const formData = new FormData();
      formData.append('file', file);

      try {
        let res = await api.post(
          `EditProdutos/ImportExcelServicos?database=${localStorage.getItem(
            'database'
          )}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        let data = res.data;
        if (data.Success) {
          setAlertStatus('success');
          setAlertTitle('Arquivo carregado com sucesso');
          onOpen();
        } else if (!data.Success) {
          setAlertStatus('error');
          setAlertTitle(data.MSG);
          onOpen();
        }
      } catch (error) { }
    });

    return;
  };

  return (
    <>
      <Alerta
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        alertStatus={alertStatus}
        title={alertTitle}
      />
      {importacao ? (
        <>
          <Importacao
            tipo={'Serviços'}
            funcaoVoltar={() => setImportacao(false)}
            funcaoNovo={() => handleClick()}
            funcaoUpload={uploadEmLote}
          />
        </>
      ) : (
        <Pagina loading={loading}>
          <FormPadrao>
            <BarraBotoes
              botoes={[
                {
                  children: 'Novo',
                  onClick: handleClick,
                  variant: 'turquesa',
                },
                {
                  children: 'Importar em Lote',
                  onClick: () => setImportacao(true),
                  variant: 'azul',
                },
              ]}
              search={{
                placeholder: 'Procurar por serviços',
                onChange: e => setSearchBar(e.target.value),
              }}
            />
            <Tabela
              titulo={'LISTAGEM DE SERVIÇOS'}
              setFunction={setServicos}
              data={servicos}
              columns={[
                {
                  placeholder: 'Código',
                  name: 'Codigo',
                  type: 'text',
                },
                {
                  placeholder: 'Nome',
                  name: 'Nome',
                  type: 'text',
                },
                {
                  placeholder: 'Preço de Venda',
                  name: 'PrecoVenda',
                  type: 'money',
                },
                {
                  placeholder: 'Ativo',
                  name: 'Ativo',
                  type: 'bool',
                },
              ]}
              searchBar={searchBar}
              handleEdit={handleEdit}
              showDelete={false}
              useCheck={false}
            />
          </FormPadrao>
        </Pagina>
      )
      }
    </>
  );
}

export default ListarServicos;
