import { Input } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import React from 'react';
import CurrencyInput from 'react-currency-input-field';

import './InputFloat.css';

function InputFloat({ placeholder, onValueChange, onChange, decimalScale = 2, ...rest }) {
  const { setFieldValue } = useFormikContext();

  return (
    <CurrencyInput
      disableAbbreviations
      decimalSeparator=","
      groupSeparator="."
      placeholder=""
      className="inputfloat"
      customInput={Input}
      decimalScale={decimalScale}
      decimalsLimit={decimalScale}
      onValueChange={value => {
        setFieldValue(rest.name, value);
      }}
      {...rest}
    />
  );
}

export default InputFloat;
