import api from '../../services/api';

const getPagamentos = async lancamentoId => {
  let payload = {
    database: localStorage.getItem('database'),
    data: lancamentoId,
  };
  let res = await api.post('/ListPagamentos/Get', payload);
  if (res.status === 200) {
    let data = res.data;
    return data.Pagamentos;
  } else {
    alert('Erro ao buscar dados');
  }
};

const baixarArquivo = async (lancamentoId, nomeArquivo) => {
  let database = localStorage.getItem('database');
  const linkSource = `${database}/lancamentos/${lancamentoId}/${nomeArquivo}`;

  let payload = {
    database: localStorage.getItem('database'),
    data: linkSource,
  };
  let res = await api.post('/EditLancamentos/GetUrlDownload', payload);
  if (res.status === 200) {
    var data = res.data;
    const downloadLink = document.createElement('a');

    downloadLink.href = data.URL;
    downloadLink.download = nomeArquivo;
    downloadLink.target = '_blank';
    downloadLink.click();
  } else {
    alert('Erro ao buscar dados');
  }
};

const getPDF = async lancamentos => {
  let payload = {
    database: localStorage.getItem('database'),
    data: JSON.stringify(lancamentos, null, 2),
  };
  let res = await api.post('/ListLancamentos/GetPDF', payload);
  let data = res.data;

  var pdf = data.ItemID;

  const linkSource = `data:application/pdf;base64,${pdf}`;
  const downloadLink = document.createElement('a');
  const fileName = 'Lancamentos.pdf';

  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

const getExcel = async lancamentos => {
  let payload = {
    database: localStorage.getItem('database'),
    data: JSON.stringify(lancamentos, null, 2),
  };
  let res = await api.post('/ListLancamentos/GetExcel', payload);
  let data = res.data;

  var pdf = data.ItemID;

  const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${pdf}`;
  const downloadLink = document.createElement('a');
  const fileName = 'Lancamentos.xlsx';

  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

const optionsCentroCustos = [
  'Financeiro',
  'Comercial',
  'DESPESAS COMERCIAL',
  'Jurídico',
  'Juros Bancário',
  'Salários',
  'Despesas Escritório',
  'Comissóes',
  'Receitas Avulsas',
];
const optionsFormaPagamento = [
  { value: 'Dinheiro', name: 'Dinheiro' },
  { value: 'Carne', name: 'Carnê' },
  { value: 'CartaoDebito', name: 'Cartão de Débito' },
  { value: 'CartaoCredito', name: 'Cartão de Crédito' },
  { value: 'Cheque', name: 'Cheque' },
  { value: 'Deposito', name: 'Depósito Bancário' },
  { value: 'Transferencia', name: 'Transferência Bancária' },
  { value: 'Boleto', name: 'Boleto Bancário' },
  { value: 'PIX', name: 'PIX' },
  { value: 'DebitoConta', name: 'Débito em Conta' },
  { value: 'CreditoConta', name: 'Crédito em Conta' },
  { value: 'MercadoPago', name: 'Mercado Pago' },
];

const optionsTipo = [
  { value: 'Receita', name: 'Receita' },
  { value: 'Despesa', name: 'Despesa' },
  { value: 'Investimento', name: 'Investimento' },
];

const valor_inicial = {
  Codigo: 246,
  Cliente: '',
  ClienteId: '',
  Empresa: '',
  EmpresaId: '',
  Banco: '',
  BancoId: '',
  Tipo: 'Receita',
  Documento: '',
  Valor: 0,
  Desconto: 0,
  DescontoPercentual: 0,
  Multa: 0,
  MoraDiaria: 0,
  ValorTotal: 0,
  FormaPagamento: 'Dinheiro',
  DataCompetencia: '0001-01-01',
  DataVencimento: '0001-01-01',
  DataPagamento: '0001-01-01',
  Pago: false,
  NaoConsiderarMulta: false,
  RecorenciaouParcelamento: false,
  Categoria: '',
  Observacoes: '',
  Descricao: '',
  LancamentoPaiId: '',
  NumeroBoleto: '',
  VendaId: '',
  OrdemServicoId: '',
  NotaCompraId: '',
  PlanoConta: '',
  PlanoContaId: '',
  CentroCustos: '',
  CentroCustosId: '',
  TransferenciaId: '',
  Total: 0,
  Pendencias: false,
  ValorRestante: 0,
  CentrosCustos: '',
  Arquivos: [],
  Id: '',
};

export {
  baixarArquivo,
  getExcel,
  getPDF,
  getPagamentos,
  optionsCentroCustos,
  optionsFormaPagamento,
  optionsTipo,
  valor_inicial,
};
