import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import '../../App.css';
import InputAutoComplete from '../../components/Inputs/InputAutoComplete/InputAutoComplete';
import InputTexto from '../../components/Inputs/InputTexto/InputTexto';
import Pagina from '../../components/Pagina/Pagina';
import api from '../../services/api';
import {  getFiltroPadraoRelatorioInadimplentes} from '../../utils/filtros';
import { gridColumn } from '../../utils/gridColumn';
import { baixarArquivoBase64 } from '../../utils/download';
import InputFiltro from '../../components/Inputs/InputFiltro/InputFiltro';
import Filtros from '../../components/Filtros/Filtros';
import InputData from '../../components/Inputs/InputData/InputData';
import InputFloat from './../../components/Inputs/InputFloat/InputFloat';
import FormikPadrao from '../../components/FormikPadrao/FormikPadrao';
import BarraBotoes from '../../components/BarraBotoes/BarraBotoes';
import TotalizadorRelatorio from '../../components/TotalizadorRelatorio/TotalizadorRelatorio';
import Tabela from '../../components/Tabela/Tabela';

function RelInadimplentes() {
  const [loading, setLoading] = useState(true);
  const [search] = useSearchParams();
  const [searchBar, setSearchBar] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [relInadimplentes, setRelInadimplentes] = useState([]);
  const [totalDevido, setTotalDevido] = useState(0);

  async function GetRelInadimplentes(filtros) {
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(filtros, null, 2),
    };
    let res = await api.post('/InadimplentesReport/GetList', payload);
    let data = res.data;
    if (res.status === 200) {
      setRelInadimplentes(data.Lancamentos);
      if (data.Lancamentos && Object.keys(data.Lancamentos).length > 0) {
        let devido = 0;
        data.Lancamentos.map(rel => (devido += rel.ValorRestante));
        setTotalDevido(devido);
      } else {
        setTotalDevido(0);
      }
      setLoading(false);
    } else {
      alert('Erro ao buscar dados');
    }
  }

  useEffect(() => {
    GetRelInadimplentes(getFiltroPadraoRelatorioInadimplentes());
  }, []);

  useEffect(() => {
    if (search.get('filter')) {
      setShowFilters(search.get('filter') === 'true');
      GetRelInadimplentes(getFiltroPadraoRelatorioInadimplentes());
    }
  }, [search]);

  const getPDF = async () => {
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(relInadimplentes, null, 2),
    };
    baixarArquivoBase64('/InadimplentesReport/GetPDF', payload);
  };

  const getExcel = async () => {
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(relInadimplentes, null, 2),
    };
    baixarArquivoBase64('/InadimplentesReport/GetExcel', payload);
  };


  return (
    <Pagina loading={loading}>
      <FormikPadrao
        initialValues={getFiltroPadraoRelatorioInadimplentes()}
        onSubmit={async values => {
          await GetRelInadimplentes(values);
        }}
      >
        <BarraBotoes
          maxWindowSize={1030}
          botoes={[
            {
              children: 'Exportar Planilha',
              onClick: getExcel,
              variant: 'verde',
            },
            {
              children: 'Exportar PDF',
              onClick: getPDF,
              variant: 'rosa',
            },
          ]}
          search={{
            placeholder: 'Procurar por lançamentos',
            onChange: e => setSearchBar(e.target.value),
          }}
          showFilters={showFilters}
          setShowFilters={setShowFilters}
        />
        <FilterMenu showFilters={showFilters} setShowFilters={setShowFilters} />
        <TotalizadorRelatorio
          items={[
            {
              Titulo: 'VALOR TOTAL DEVIDO',
              Valor: totalDevido,
              type: 'money',
            },
          ]}
        />
        <Tabela
          titulo={'RELATÓRIO DE INADIMPLENTES'}
          setFunction={setRelInadimplentes}
          data={relInadimplentes}
          columns={[
            {
              placeholder: 'EMPRESA',
              name: 'Empresa',
              type: 'text',
            },
            {
              placeholder: 'CLIENTE',
              name: 'Cliente',
              type: 'text',
            },
            {
              placeholder: 'CÓDIGO PEDIDO',
              name: 'VendaCodigo',
              type: 'text',
            },
            {
              placeholder: 'CÓDIGO LANÇAMENTO',
              name: 'Codigo',
              type: 'text',
            },
            {
              placeholder: 'VALOR DEVIDO',
              name: 'ValorRestante',
              type: 'money',
            },
          ]}
          searchBar={searchBar}
        />
      </FormikPadrao>
    </Pagina>
  );
}

function FilterMenu({ showFilters, setShowFilters }) {
  return (
    <Filtros showFilters={showFilters} setShowFilters={setShowFilters}>
      <InputFiltro
        titulo="CLIENTE/FORNECEDOR"
        id="Cliente"
        name="Cliente"
        component={InputAutoComplete}
        autocomplete={true}
        path={'ClienteFornecedor'}
        database={localStorage.getItem('database')}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="EMPRESA"
        id="Empresa"
        name="Empresa"
        component={InputAutoComplete}
        autocomplete={true}
        path={'Empresa'}
        database={localStorage.getItem('database')}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="CÓDIGO"
        id="NumeroLancamento"
        name="NumeroLancamento"
        component={InputTexto}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="PEDIDO"
        id="Pedido"
        name="Pedido"
        component={InputTexto}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="VALOR MÍNIMO"
        id="ValorMinimo"
        name="ValorMinimo"
        component={InputFloat}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="VALOR MÁXIMO"
        id="ValorMaximo"
        name="ValorMaximo"
        component={InputFloat}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="Data Final"
        id="DataFinal"
        name="DataFinal"
        component={InputData}
        gridColumn={gridColumn.gc4}
      />
    </Filtros>
  );
}

export default RelInadimplentes;
