import React from 'react';
import './InputHorario.css';

import { Input } from '@chakra-ui/react';

function InputHorario({ placeholder, ...rest }) {
  return <Input className="inputhorario" type="time" {...rest} />;
}

export default InputHorario;
