import { Box, Grid } from '@chakra-ui/react';
import React from 'react';
import Botao from '../../components/Botao/Botao';
import { gridColumn } from '../../utils/gridColumn';

import '../../App.css';

function Filtros({ children, showFilters }) {
  return (
    <>
      {showFilters && (
        <Box mb={4} gridColumn={gridColumn.gc12}>
          <Grid
            gridTemplateColumns="repeat(12, 1fr)"
            p={4}
            width="100%"
            height="fit-content"
            className="filtersBox"
          >
            {children}
            <Box flexGrow={1} p={2} mt={5} gridColumn={gridColumn.gc4}>
              <Botao mr={2} variant="azulEscuro" type="submit">
                FILTRAR
              </Botao>
              <Botao
                variant="amarelo"
                type="button"
                onClick={() => {
                  var url = window.location.href.split('?')[0] + '?filter=true';
                  window.location.href = url;
                }}
              >
                LIMPAR FILTROS
              </Botao>
            </Box>
          </Grid>
        </Box>
      )}
    </>
  );
}

export default Filtros;
