import api from '../services/api';
import FileDownload from 'js-file-download';

const baixarArquivoURLFileDownload = async (path, payload, nomeArquivo) => {
  let res = await api.post(path, payload);
  if (res.status === 200) {
    var data = res.data.URL;
    FileDownload(data, nomeArquivo);
  } else {
    alert('Erro ao buscar dados');
  }
};

const baixarArquivoURL = async (path, payload, nomeArquivo) => {
  let res = await api.post(path, payload);
  if (res.status === 200) {
    const downloadLink = document.createElement('a');
    downloadLink.href = res.data.URL;
    downloadLink.download = nomeArquivo;
    downloadLink.click();
  } else {
    alert('Erro ao buscar dados');
  }
};

const baixarArquivoBase64 = async (path, payload) => {
  let res = await api.post(path, payload);
  if (res.status === 200) {
    const downloadLink = document.createElement('a');
    downloadLink.href = res.data.url;
    downloadLink.download = res.data.filename;
    downloadLink.click();
  } else {
    alert('Erro ao buscar dados');
  }
};

export { baixarArquivoURL, baixarArquivoURLFileDownload, baixarArquivoBase64 };
