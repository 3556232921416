import { useDisclosure } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import '../../App.css';
import Alerta from '../../components/Alerta/Alerta';
import BarraBotoes from '../../components/BarraBotoes/BarraBotoes';
import Card from '../../components/Card/Card';
import FormikPadrao from '../../components/FormikPadrao/FormikPadrao';
import InputAutoComplete from '../../components/Inputs/InputAutoComplete/InputAutoComplete';
import InputFormik from '../../components/Inputs/InputFormik/InputFormik';
import InputTexto from '../../components/Inputs/InputTexto/InputTexto';
import InputToggle from '../../components/Inputs/InputToggle/InputToggle';
import Pagina from '../../components/Pagina/Pagina';
import api from '../../services/api';
import { gridColumn } from '../../utils/gridColumn';
import { addIDtoURL } from '../../utils/utils';
import './CentrosCustos.css';

function EditarCentrosCustos() {
  const navigate = useNavigate();
  const [centrosCusto, setCentroCustos] = useState({
    Nome: '',
    EhPai: false,
    CentroPai: '',
    CentroPaiId: '',
  });

  const [loading, setLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false });
  const [alertStatus, setAlertStatus] = useState('success');
  const [alertTitle, setAlertTitle] = useState('');

  let { id } = useParams();

  useEffect(() => {
    async function GetCentrosCustos() {
      let payload = {
        database: localStorage.getItem('database'),
        data: id,
      };
      let res = await api.post('/EditCentroCustos/Get', payload);
      if (res.status === 200) {
        let data = res.data;
        setCentroCustos(data.CentroCustos);
        setLoading(false);
      } else {
        alert('Erro ao buscar dados');
      }
    }
    GetCentrosCustos();
  }, [id]);

  let save = async value => {
    setLoading(true);
    let payload = {
      data: value,
      database: localStorage.getItem('database'),
    };
    let res = await api.post('/EditCentroCustos/Save', payload);
    let data = res.data;
    setLoading(false);
    if (data.Success) {
      setAlertStatus('success');
      setAlertTitle(data.MSG);
      onOpen();
      addIDtoURL(id, data.ItemID);
    } else {
      setAlertStatus('error');
      setAlertTitle(data.MSG);
      onOpen();
    }
  };

  return (
    <>
      <Alerta
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        alertStatus={alertStatus}
        title={alertTitle}
      />
      <Pagina loading={loading}>
        <FormikPadrao
          validateOnBlur={true}
          validateOnChange={true}
          enableReinitialize={true}
          initialValues={centrosCusto}
          onSubmit={async values => {
            await save(JSON.stringify(values, null, 2));
          }}
        >
          <Interno navigate={navigate} />
        </FormikPadrao>
      </Pagina>
    </>
  );
}

function Interno({ navigate }) {
  const formik = useFormikContext();
  let titulo = `${formik.values.Id !== '' ? 'Editar' : 'Novo'
    } Centro de Custos`;

  return (
    <>
      <BarraBotoes
        botoes={[
          {
            variant: 'vermelho',
            onClick: () => navigate('/CentrosCustos/Listar'),
            children: 'Cancelar',
          },
          {
            variant: 'turquesa',
            type: 'submit',
            children: 'Salvar',
          },
        ]}
        caminho={[
          { nome: 'Centro de Custos', path: '/CentrosCustos/Listar' },
          { nome: titulo },
        ]}
      />

      <Card titulo="NOVO CENTRO DE CUSTOS">
        <InputFormik
          titulo="Nome"
          id="Nome"
          name="Nome"
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />
        <InputFormik
          titulo="Centro Pai?"
          id="EhPai"
          name="EhPai"
          component={InputToggle}
          gridColumn={gridColumn.gc4}
        />
        {!formik.values.EhPai && (
          <InputFormik
            titulo="Hierarquia"
            id="CentroPai"
            name="CentroPai"
            component={InputAutoComplete}
            gridColumn={gridColumn.gc4}
            path="CentroCustoPai"
            autocomplete={true}
            database={localStorage.getItem('database')}
          />
        )}
      </Card>
    </>
  );
}

export default EditarCentrosCustos;
