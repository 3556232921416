import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ChevronUpDown from '../../Icones/ChevronUpDown/ChevronUpDown';
import '../MenuLateral.css';

function ItemMenu({ titulo, Icone, submenus, relatorios }) {
  const [showRelatorio, setShowRelatorio] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  return (
    <>
      <button
        className="linkmenu"
        type="button"
        onClick={() => setShowMenu(!showMenu)}
      >
        <Icone className="icone" />
        {titulo} <ChevronUpDown down={!showMenu} />
      </button>
      {
        showMenu &&
        <div className="linksmenuinterno">
          {submenus.map(item => (
            <Link
              key={item.url}
              to={item.url}
              className="linkinterno"
            >
              {item.nome}
            </Link>
          ))}
          {relatorios &&
            <>
              <button
                type="button"
                className="linkinterno"
                onClick={() => setShowRelatorio(!showRelatorio)}
              >
                Relatórios <ChevronUpDown down={!showRelatorio} />
              </button>
              {showRelatorio &&
                <div className="linksmenuinternorel">
                  {relatorios.map(item => (
                    <Link
                      key={item.url}
                      to={item.url}
                      className="linkinterno"
                    >
                      {item.nome}
                    </Link>
                  ))}
                </div>
              }
            </>
          }
        </div>
      }
    </>
  );
}

export default ItemMenu;
