import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import '../../App.css';
import InputAutoComplete from '../../components/Inputs/InputAutoComplete/InputAutoComplete';
import Pagina from '../../components/Pagina/Pagina';
import api from '../../services/api';
import { getFiltroPadraoRelatorioValorEstoque } from '../../utils/filtros';
import { gridColumn } from '../../utils/gridColumn';
import { baixarArquivoBase64 } from '../../utils/download';
import Filtros from '../../components/Filtros/Filtros';
import InputFiltro from '../../components/Inputs/InputFiltro/InputFiltro';
import FormikPadrao from '../../components/FormikPadrao/FormikPadrao';
import BarraBotoes from '../../components/BarraBotoes/BarraBotoes';
import TotalizadorRelatorio from '../../components/TotalizadorRelatorio/TotalizadorRelatorio';
import Tabela from '../../components/Tabela/Tabela';

function RelValorEstoque() {
  const [loading, setLoading] = useState(true);
  const [search] = useSearchParams();
  const [searchBar, setSearchBar] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [relValorEstoque, setRelValorEstoque] = useState([]);
  const [totalCusto, setTotalCusto] = useState(0);
  const [totalVenda, setTotalVenda] = useState(0);

  async function GetRelValorEstoque(filtros) {
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(filtros, null, 2),
    };
    let res = await api.post('/ValorEstoqueReport/GetList', payload);
    let data = res.data;
    setRelValorEstoque(data.Produtos);
    if (data.Produtos && Object.keys(data.Produtos).length > 0) {
      let custo = 0;
      let venda = 0;

      data.Produtos.map(rel => (custo += rel.TotalPrecoCusto));

      data.Produtos.map(rel => (venda += rel.TotalPrecoVenda));

      setTotalCusto(custo);
      setTotalVenda(venda);
    }

    setLoading(false);
  }

  useEffect(() => {
    GetRelValorEstoque(getFiltroPadraoRelatorioValorEstoque());
  }, []);

  useEffect(() => {
    if (search.get('filter')) {
      setShowFilters(search.get('filter') === 'true');
      GetRelValorEstoque(getFiltroPadraoRelatorioValorEstoque());
    }
  }, [search]);

  const getPDF = async () => {
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(relValorEstoque, null, 2),
    };
    baixarArquivoBase64('/ValorEstoqueReport/GetPDF', payload);
  };

  const getExcel = async () => {
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(relValorEstoque, null, 2),
    };
    baixarArquivoBase64('/ValorEstoqueReport/GetExcel', payload);
  };

  return (
    <Pagina loading={loading}>
      <FormikPadrao
        initialValues={getFiltroPadraoRelatorioValorEstoque()}
        onSubmit={async values => {
          await GetRelValorEstoque(values);
        }}
      >
        <BarraBotoes
          maxWindowSize={1030}
          botoes={[
            {
              children: 'Exportar Planilha',
              onClick: getExcel,
              variant: 'verde',
            },
            {
              children: 'Exportar PDF',
              onClick: getPDF,
              variant: 'rosa',
            },
          ]}
          search={{
            placeholder: 'Procurar',
            onChange: e => setSearchBar(e.target.value),
          }}
          showFilters={showFilters}
          setShowFilters={setShowFilters}
        />
        <FilterMenu showFilters={showFilters} setShowFilters={setShowFilters} />
        <TotalizadorRelatorio
          items={[
            {
              Titulo: 'VALOR TOTAL DE CUSTO',
              Valor: totalCusto,
              type: 'money',
            },
            {
              Titulo: 'VALOR TOTAL DE VENDA',
              Valor: totalVenda,
              type: 'money',
            },
          ]}
        />
        <Tabela
          titulo={'RELATÓRIO DE VALOR TOTAL EM ESTOQUE'}
          setFunction={setRelValorEstoque}
          data={relValorEstoque}
          columns={[
            {
              placeholder: 'CÓDIGO',
              name: 'Codigo',
              type: 'text',
            },
            {
              placeholder: 'PRODUTO',
              name: 'Descricao',
              type: 'text',
            },
            {
              placeholder: 'SALDO',
              name: 'Saldo',
              type: 'text',
            },
            {
              placeholder: 'PREÇO CUSTO',
              name: 'PrecoCusto',
              type: 'money',
            },
            {
              placeholder: 'TOTAL PREÇO CUSTO',
              name: 'TotalPrecoCusto',
              type: 'money',
            },
            {
              placeholder: 'PREÇO VENDA',
              name: 'PrecoVenda',
              type: 'money',
            },
            {
              placeholder: 'TOTAL PREÇO VENDA',
              name: 'TotalPrecoVenda',
              type: 'money',
            },
            {
              placeholder: 'ESTOQUE',
              name: 'Estoque',
              type: 'text',
            },
            {
              placeholder: 'EMPRESA',
              name: 'Empresa',
              type: 'text',
            },
          ]}
          searchBar={searchBar}
        />
      </FormikPadrao>
    </Pagina>
  );
}

function FilterMenu({ showFilters, setShowFilters }) {
  return (
    <Filtros showFilters={showFilters} setShowFilters={setShowFilters}>
      <InputFiltro
        titulo="EMPRESA"
        id="Empresa"
        name="Empresa"
        component={InputAutoComplete}
        autocomplete={true}
        path={'Empresa'}
        database={localStorage.getItem('database')}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="DEPÓSITO"
        id="Estoque"
        name="Estoque"
        component={InputAutoComplete}
        autocomplete={true}
        path={'Deposito'}
        database={localStorage.getItem('database')}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="PRODUTO"
        id="Produto"
        name="Produto"
        component={InputAutoComplete}
        autocomplete={true}
        path={'Produtos'}
        database={localStorage.getItem('database')}
        gridColumn={gridColumn.gc4}
      />
    </Filtros>
  );
}

export default RelValorEstoque;
