import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import '../../App.css';
import Pagina from '../../components/Pagina/Pagina';
import api from '../../services/api';
import BarraBotoes from './../../components/BarraBotoes/BarraBotoes';
import FormikPadrao from './../../components/FormikPadrao/FormikPadrao';
import TotalizadorRelatorio from './../../components/TotalizadorRelatorio/TotalizadorRelatorio';
import Tabela from './../../components/Tabela/Tabela';
import { baixarArquivoBase64 } from '../../utils/download';

function RelComissoes() {
  const [relComissoes, setRelComissoes] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [totalComissoes, setTotalComissoes] = useState(0);
  const [searchBar, setSearchBar] = useState('');

  async function GetRelComissoes() {
    let payload = {
      database: localStorage.getItem('database'),
      data: '{}',
    };
    let res = await api.post('/ComissoesReport/GetList', payload);
    let data = res.data;
    if (res.status === 200) {
      setRelComissoes(data.Comissoes);
      if (data.Comissoes && Object.keys(data.Comissoes).length > 0) {
        let comissoes = 0;
        data.Comissoes.map(rel => (comissoes += rel.Valor));
        setTotalComissoes(comissoes);
      }
      setLoading(false);
    } else {
      alert('Erro ao buscar dados');
      setTotalComissoes(0);
    }
  }

  useEffect(() => {
    GetRelComissoes();
  }, []);

  function handleEdit(item) {
    navigate(`/Lancamentos/Editar/${item.Id}`);
  }

  const getPDF = async () => {
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(relComissoes, null, 2),
    };
    baixarArquivoBase64('/ComissoesReport/GetPDF', payload);
  };

  const getExcel = async () => {
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(relComissoes, null, 2),
    };
    baixarArquivoBase64('/ComissoesReport/GetExcel', payload);
  };

  return (
    <Pagina loading={loading}>
      <FormikPadrao>
        <BarraBotoes
          maxWindowSize={1030}
          botoes={[
            {
              children: 'Exportar Planilha',
              onClick: getExcel,
              variant: 'verde',
            },
            {
              children: 'Exportar PDF',
              onClick: getPDF,
              variant: 'rosa',
            },
          ]}
          search={{
            placeholder: 'Procurar por clientes',
            onChange: e => setSearchBar(e.target.value),
          }}
        />
        <TotalizadorRelatorio
          items={[
            { Titulo: 'QUANTIDADE DE COMISSÕES', Valor: relComissoes.length },
            {
              Titulo: 'VALOR TOTAL DE COMISSÕES',
              Valor: totalComissoes,
              type: 'money',
            },
          ]}
        />
        <Tabela
          titulo={'RELATÓRIO DE COMISSÕES'}
          setFunction={setRelComissoes}
          data={relComissoes}
          columns={[
            {
              placeholder: 'PEDIDO',
              name: 'CodigoPedido',
              type: 'text',
            },
            {
              placeholder: 'VENDEDOR',
              name: 'Vendedor',
              type: 'text',
            },
            {
              placeholder: 'DATA DE VENCIMENTO',
              name: 'DataVencimento',
              type: 'date',
            },
            {
              placeholder: 'VALOR',
              name: 'Valor',
              type: 'money',
            },
          ]}
          searchBar={searchBar}
          handleEdit={handleEdit}
        />
      </FormikPadrao>
    </Pagina>
  );
}

export default RelComissoes;
