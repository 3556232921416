import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  Grid,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import get from 'lodash/get';
import React from 'react';
import { GoCheck, GoX } from 'react-icons/go';
import '../../App.css';
import IconeDeletar from '../../components/Icones/IconeDeletar/IconeDeletar';
import { formataData, formataDinheiro } from '../../utils/formatador';
import { gridColumn } from '../../utils/gridColumn';
import InputData from '../Inputs/InputData/InputData';
import InputDinheiro from '../Inputs/InputDinheiro/InputDinheiro';
import InputFloat from '../Inputs/InputFloat/InputFloat';
import InputFormik from '../Inputs/InputFormik/InputFormik';
import InputNumInt from '../Inputs/InputNumInt/InputNumInt';
import InputTabela from '../Inputs/InputTabela/InputTabela';
import InputTexto from '../Inputs/InputTexto/InputTexto';
import './Tabela.css';
import InputToggle from '../Inputs/InputToggle/InputToggle';
import InputSelect from '../Inputs/InputSelect/InputSelect';
import { v4 as uuidv4 } from 'uuid';

function CampoTabelaInterna({ data, item, column, columns, nomeLista }) {
  if (column.editavel) {
    let id = `${nomeLista}[${data.indexOf(item)}]${column.name}`;
    let name = `${nomeLista}[${data.indexOf(item)}]${column.name}`;

    switch (column.type) {
      case 'number':
        return (
          <Td key={uuidv4()}>
            <InputTabela
              titulo={column.name}
              id={id}
              name={name}
              component={InputNumInt}
              gridColumn={gridColumn.gc4}
            />
          </Td>
        );
      case 'float':
        return (
          <Td key={uuidv4()}>
            <InputTabela
              titulo={column.name}
              id={id}
              name={name}
              component={InputFloat}
              gridColumn={gridColumn.gc4}
            />
          </Td>
        );
      case 'money':
        return (
          <Td key={uuidv4()}>
            <InputTabela
              titulo={column.name}
              id={id}
              name={name}
              component={InputDinheiro}
              gridColumn={gridColumn.gc4}
            />
          </Td>
        );
      case 'date':
        return (
          <Td key={uuidv4()}>
            <InputTabela
              titulo={column.name}
              id={id}
              name={name}
              component={InputData}
              gridColumn={gridColumn.gc4}
            />
          </Td>
        );
      default:
        return (
          <Td key={uuidv4()}>
            <InputTabela
              titulo={column.name}
              id={id}
              name={name}
              component={InputTexto}
              gridColumn={gridColumn.gc4}
            />
          </Td>
        );
    }
  } else {
    switch (column.type) {
      case 'money':
        return (
          <Td key={uuidv4()}>{formataDinheiro(get(item, column.name))}</Td>
        );
      case 'date':
        return <Td key={uuidv4()}>{formataData(get(item, column.name))}</Td>;
      case 'function':
        return (
          <Td key={uuidv4()}>{column.function(get(item, column.name))}</Td>
        );
      case 'bool':
        return (
          <Td key={uuidv4()}>
            {get(item, column.name) ? <GoCheck /> : <GoX />}
          </Td>
        );

      default:
        return <Td key={uuidv4()}>{`${get(item, column.name)}`}</Td>;
    }
  }
}

function CampoTabelaInternaEditavel({
  data,
  item,
  column,
  columns,
  nomeLista,
}) {
  //console.log(column);
  if (column.editavel) {
    let id = `${nomeLista}[${data.indexOf(item)}]${column.name}`;
    let name = `${nomeLista}[${data.indexOf(item)}]${column.name}`;
    
    switch (column.type) {
      case 'number':
        return (
          <InputFormik
            key={uuidv4()}
            titulo={column.placeholder}
            id={name}
            name={name}
            component={InputNumInt}
            gridColumn={gridColumn.gc4}
          />
        );
      case 'float':
        return (
          <InputFormik
            key={uuidv4()}
            titulo={column.placeholder}
            id={name}
            name={name}
            component={InputFloat}
            gridColumn={gridColumn.gc4}
          />
        );
      case 'money':
        return (
          <InputFormik
            key={uuidv4()}
            titulo={column.placeholder}
            id={name}
            name={name}
            component={InputDinheiro}
            gridColumn={gridColumn.gc4}
          />
        );
      case 'date':
        return (
          <InputFormik
            key={uuidv4()}
            titulo={column.placeholder}
            id={id}
            name={name}
            component={InputData}
            gridColumn={gridColumn.gc4}
          />
        );
      case 'toggle':
        return (
          <InputFormik
            key={uuidv4()}
            titulo={column.placeholder}
            id={id}
            name={name}
            component={InputToggle}
            gridColumn={gridColumn.gc4}
          />
        );
      case 'select':
        return (
          <InputFormik
            key={uuidv4()}
            titulo={column.placeholder}
            id={id}
            name={name}
            component={InputSelect}
            options={column.options}
            gridColumn={gridColumn.gc4}
          />
        );
      default:
        return (
          <InputFormik
            key={uuidv4()}
            titulo={column.placeholder}
            id={id}
            name={name}
            component={InputTexto}
            gridColumn={gridColumn.gc4}
          />
        );
    }
  } else {
    switch (column.type) {
      case 'money':
        return (
          <Td key={uuidv4()}>{formataDinheiro(get(item, column.name))}</Td>
        );
      case 'date':
        return <Td key={uuidv4()}>{formataData(get(item, column.name))}</Td>;
      case 'function':
        return (
          <Td key={uuidv4()}>{column.function(get(item, column.name))}</Td>
        );
      case 'bool':
        return (
          <Td key={uuidv4()}>
            {get(item, column.name) ? <GoCheck /> : <GoX />}
          </Td>
        );
      default:
        return <Td key={uuidv4()}>{`${get(item, column.name)}`}</Td>;
    }
  }
}

function TabelaInterna({
  columns,
  hiddenColumns,
  nomeLista,
  showDelete = true,
  handleDelete = null,
}) {
  let formik = useFormikContext();
  let data = formik.values[nomeLista];

  if (data) {
    data.forEach(item => {
      if (item.collapsed === undefined) item.collapsed = true;
    });
  }

  function toggleCollapse(item) {
    let data = formik.values[nomeLista];
    data[item].collapsed = !data[item].collapsed;
    formik.setFieldValue(nomeLista, data);
  }

  return (
    <Box mb={7} gridColumn={gridColumn.gc12} className="card">
      <Box className="cadastrados" gridColumn={gridColumn.gc12}>
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                {hiddenColumns && <Th></Th>}
                {columns.map(column => (
                  <Th key={uuidv4()}>{column.placeholder}</Th>
                ))}
                {showDelete && handleDelete && <Th></Th>}
              </Tr>
            </Thead>
            <Tbody>
              {data &&
                data.map((item, index) => (
                  <React.Fragment key={uuidv4()}>
                    <Tr key={uuidv4()}>
                      {hiddenColumns && (
                        <Td key={uuidv4()}>
                          <ChevronDownIcon
                            onClick={() => toggleCollapse(index)}
                          />
                        </Td>
                      )}

                      {columns.map(column => (
                        <CampoTabelaInterna
                          key={uuidv4()}
                          data={data}
                          item={item}
                          column={column}
                          columns={columns}
                          nomeLista={nomeLista}
                        />
                      ))}

                      {showDelete && handleDelete && (
                        <Td key={uuidv4()}>
                          <IconeDeletar
                            onClick={() => handleDelete(item, index)}
                          />
                        </Td>
                      )}
                    </Tr>
                    {hiddenColumns && !item.collapsed && (
                      <Tr key={uuidv4()}>
                        <Td key={uuidv4()} colSpan={columns.length + 1}>
                          <Flex
                            flexGrow={1}
                            id="content"
                            justifyContent="center"
                          >
                            <Grid
                              gridTemplateColumns="repeat(12, 1fr)"
                              gap={'10px'}
                              mx={6}
                              width="100%"
                              height="fit-content"
                            >
                              {hiddenColumns.map(column => {
                                if (column.visible) {
                                  return (
                                    <CampoTabelaInternaEditavel
                                      key={uuidv4()}
                                      data={data}
                                      item={item}
                                      column={column}
                                      columns={columns}
                                      nomeLista={nomeLista}
                                    />
                                  );
                                } else {
                                  return <></>;
                                }
                              })}
                            </Grid>
                          </Flex>
                        </Td>
                      </Tr>
                    )}
                  </React.Fragment>
                ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}

export default TabelaInterna;
