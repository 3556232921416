import React, { useState } from 'react';
import './InputCheckbox.css';

function InputCheckbox({ item, initialValue = false, handleChange }) {
  const [selecionado, setSelecionado] = useState(initialValue);

  return (
    <label className="container-label">
      <input
        className="inputcheckbox"
        type="checkbox"
        checked={selecionado}
        onChange={() => {
          setSelecionado(!selecionado);
          handleChange(item,!selecionado);
        }}
      />
    </label>
  );
}

export default InputCheckbox;
