import { useDisclosure } from '@chakra-ui/react';
import { useFormik, useFormikContext } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import '../../App.css';
import Alerta from '../../components/Alerta/Alerta';
import Botao from '../../components/Botao/Botao';
import InputNumInt from '../../components/Inputs/InputNumInt/InputNumInt';
import InputTextArea from '../../components/Inputs/InputTextArea/InputTextArea';
import InputTexto from '../../components/Inputs/InputTexto/InputTexto';
import InputToggle from '../../components/Inputs/InputToggle/InputToggle';
import Pagina from '../../components/Pagina/Pagina';
import SecaoCadastro from '../../components/SecaoCadastro/SecaoCadastro';
import api from '../../services/api';
import { gridColumn } from '../../utils/gridColumn';
import { addIDtoURL } from '../../utils/utils';
import './Servicos.css';
import InputFormik from '../../components/Inputs/InputFormik/InputFormik';
import BarraBotoes from '../../components/BarraBotoes/BarraBotoes';
import InputFloat from '../../components/Inputs/InputFloat/InputFloat';
import FormikPadrao from '../../components/FormikPadrao/FormikPadrao';
import Card from '../../components/Card/Card';

function EditarServicos() {
  const navigate = useNavigate();
  const [servicos, setServicos] = useState({
    Codigo: '',
    Nome: '',
    Ativo: '',
    PrecoCusto: 0,
    MVA: 0,
    PrecoVenda: 0,
    Descricao: '',
  });
  const [loading, setLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false });
  const [alertStatus, setAlertStatus] = useState('success');
  const [alertTitle, setAlertTitle] = useState('');

  let { id } = useParams();

  useEffect(() => {
    async function GetServicos() {
      let payload = {
        database: localStorage.getItem('database'),
        data: id,
      };
      let res = await api.post('/EditProdutos/Get', payload);
      if (res.status === 200) {
        let data = res.data;
        setServicos(data.Produto);
        setLoading(false);
      } else {
        alert('Erro ao buscar dados');
      }
    }
    GetServicos();
  }, [id]);

  let save = async value => {
    setLoading(true);
    let payload = {
      data: value,
      database: localStorage.getItem('database'),
    };
    let res = await api.post('/EditProdutos/Save', payload);
    let data = res.data;
    setLoading(false);
    if (data.Success) {
      setAlertStatus('success');
      setAlertTitle(data.MSG);
      onOpen();
      addIDtoURL(id, data.ItemID);
    } else {
      setAlertStatus('error');
      setAlertTitle(data.MSG);
      onOpen();
    }
  };

  return (
    <>
      <Alerta
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        alertStatus={alertStatus}
        title={alertTitle}
      />
      <Pagina loading={loading}>
        <FormikPadrao
          enableReinitialize={true}
          initialValues={servicos}
          validationSchema={yup.object({
          })}
          onSubmit={async values => {
            values.IsServico = true;
            await save(JSON.stringify(values, null, 2));
          }}
        >
          <Interno navigate={navigate} id={id} />
        </FormikPadrao>
      </Pagina>
    </>
  );
}

function Interno({ navigate, id }) {
  const { values, setFieldValue } = useFormikContext();
  let titulo = `${values.Id !== '' ? 'Editar' : 'Novo'
    } Serviço`;

  const getPrice = useCallback(async (opcao, values) => {
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(values, null, 2),
      arg: JSON.stringify(opcao, null, 2)
    };
    let res = await api.post('/EditProdutos/GetPrice', payload);
    if (res.status === 200) {
      let data = res.data;
      setFieldValue("PrecoVenda", data.Produto.PrecoVenda);
      setFieldValue("CustoComAcrescimos", data.Produto.CustoComAcrescimos);
      setFieldValue("MVA", data.Produto.MVA);
    } else {
      alert('Erro ao buscar dados');
    }
  }, [setFieldValue])


  useEffect(() => {
    getPrice('c', values);
  }, [values.PrecoCusto, getPrice])

  useEffect(() => {
    getPrice('m', values);
  }, [values.MVA, getPrice])

  useEffect(() => {
    getPrice('v', values);
  }, [values.PrecoVenda, getPrice])

  return (
    <>
      <BarraBotoes
        botoes={[
          {
            variant: 'vermelho',
            onClick: () => navigate('/Servicos/Listar'),
            children: 'Cancelar',
          },
          {
            variant: 'turquesa',
            type: 'submit',
            children: 'Salvar',
          },
        ]}
        caminho={[
          { nome: 'Serviços', path: '/Servicos/Listar' },
          { nome: titulo },
        ]}
      />
      <Card titulo={titulo}>
        <InputFormik
          titulo="Código"
          id="Codigo"
          name="Codigo"
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />
        <InputFormik
          titulo="Nome"
          id="Nome"
          name="Nome"
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />
        <InputFormik
          titulo="Ativo"
          id="Ativo"
          name="Ativo"
          component={InputToggle}
          gridColumn={gridColumn.gc4}
        />
        <InputFormik
          titulo="Preço de Custo"
          id="PrecoCusto"
          name="PrecoCusto"
          component={InputFloat}
          gridColumn={gridColumn.gc4}
        />
        <InputFormik
          titulo="Lucro (%)"
          id="MVA"
          name="MVA"
          component={InputFloat}
          gridColumn={gridColumn.gc4}
        />
        <InputFormik
          titulo="Preço de Venda"
          id="PrecoVenda"
          name="PrecoVenda"
          component={InputFloat}
          gridColumn={gridColumn.gc4}
        />
        <InputFormik
          titulo="Descrição"
          id="Descricao"
          name="Descricao"
          component={InputTextArea}
          gridColumn={gridColumn.gc4}
        />
      </Card>
    </>
  );
}

export default EditarServicos;
