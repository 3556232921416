import { Box, useDisclosure } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import '../../App.css';
import Alerta from '../../components/Alerta/Alerta';
import InputSelect from '../../components/Inputs/InputSelect/InputSelect';
import InputToggle from '../../components/Inputs/InputToggle/InputToggle';
import Pagina from '../../components/Pagina/Pagina';
import api from '../../services/api';
import { gridColumn } from '../../utils/gridColumn';
import './PDVBalanca.css';
import InputFormik from '../../components/Inputs/InputFormik/InputFormik';
import BarraBotoes from '../../components/BarraBotoes/BarraBotoes';
import FormikPadrao from '../../components/FormikPadrao/FormikPadrao';
import Card from './../../components/Card/Card';

function EditarPDVBalanca() {
  const [PDV, setPDV] = useState({
    HabilitarBalancaPDV: false,
    PadraoBalancaPDV: '',
  });
  const [loading, setLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false });
  const [alertStatus, setAlertStatus] = useState('success');
  const [alertTitle, setAlertTitle] = useState('');

  useEffect(() => {
    async function GetPDV() {
      let payload = {
        database: localStorage.getItem('database'),
        data: '',
      };
      let res = await api.post('/EditConfiguracoesBalanca/Get', payload);
      if (res.status === 200) {
        let data = res.data;
        setPDV(data.Configuracao);
        setLoading(false);
      } else {
        alert('Erro ao buscar dados');
      }
    }
    GetPDV();
  }, []);

  let save = async value => {
    setLoading(true);
    let payload = {
      data: value,
      database: localStorage.getItem('database'),
    };
    let res = await api.post('/EditConfiguracoesBalanca/Save', payload);
    let data = res.data;
    setLoading(false);
    if (data.Success) {
      setAlertStatus('success');
      setAlertTitle(data.MSG);
      onOpen();
    } else {
      setAlertStatus('error');
      setAlertTitle(data.MSG);
      onOpen();
    }
  };

  return (
    <>
      <Alerta
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        alertStatus={alertStatus}
        title={alertTitle}
      />
      <Pagina loading={loading}>
        <FormikPadrao
          enableReinitialize={true}
          initialValues={PDV}
          onSubmit={async values => {
            await save(JSON.stringify(values, null, 2));
          }}
        >
          <Interno />
        </FormikPadrao>
      </Pagina>
    </>
  );
}

function Interno() {
  const formik = useFormikContext();

  const optionsBalanca = [
    { value: '', name: 'Selecione' },
    { value: '2CCCC0TTTTTTDV', name: '2 C C C C 0 T T T T T T DV' },
    { value: '2CCCC00PPPPPDV', name: '2 C C C C 0 0 P P P P P DV' },
    { value: '2CCCCCTTTTTTDV', name: '2 C C C C C T T T T T T DV' },
    { value: '2CCCCC0PPPPPDV', name: '2 C C C C C 0 P P P P P DV' },
    { value: '2CCCCCCPPPPPDV', name: '2 C C C C C C P P P P P DV' },
  ];
  return (
    <>
      <BarraBotoes
        botoes={[
          {
            variant: 'turquesa',
            type: 'submit',
            children: 'Salvar',
          },
        ]}
      />
      <Card titulo="EDITAR CONFIGURAÇÃO">
        <InputFormik
          titulo="Habilitar Balança no PDV"
          id="HabilitarBalancaPDV"
          name="HabilitarBalancaPDV"
          component={InputToggle}
          gridColumn={gridColumn.gc4}
        />
        <InputFormik
          titulo="Padrão"
          id="PadraoBalancaPDV"
          name="PadraoBalancaPDV"
          component={InputSelect}
          gridColumn={gridColumn.gc4}
          options={optionsBalanca}
          disabled={!formik.values.HabilitarBalancaPDV}
        />
        <Box p={2} ml={5} gridColumn={gridColumn.gc6}>
          <h3>
            <b>Descrição dos padrões de balança</b>
          </h3>
          <ul>
            <li>2 - Código in store reservado</li>
            <li>C - Código do produto</li>
            <li>0 - Dígito Separador</li>
            <li>T - Valor Total a Pagar (2 casas decimais)</li>
            <li>P - Peso ou Quantidade (3 casas decimais)</li>
            <li>DV - Digito Verificador EAN-13</li>
          </ul>
        </Box>
      </Card>
    </>
  );
}

export default EditarPDVBalanca;
