import { Box } from '@chakra-ui/react';
import { ErrorMessage, Field } from 'formik';
import React from 'react';

function InputTabela({ gridColumn, component, ...rest }) {
  return (
    <Box p={2} gridColumn={gridColumn}>
      <Field as={component} {...rest} />
      <ErrorMessage name={rest.name} />
    </Box>
  );
}

export default InputTabela;
