import { Box, Grid } from '@chakra-ui/react';
import React from 'react';
import { formataDinheiro } from '../../utils/formatador';
import { gridColumn } from '../../utils/gridColumn';

function TotalizadorRelatorio({ items }) {
  return (
    <Box mb={5} gridColumn={gridColumn.gc12}>
      <Grid
        gridTemplateColumns="repeat(12, 1fr)"
        mx={1}
        width="100%"
        height="100%"
        maxBlockSize={'300px'}
        className="itensRelatorio"
      >
        {items.map(item => {
          if (item.type === 'money') {
            return (
              <Box gridColumn={gridColumn.gc6} key={item.Titulo}>
                <p>{item.Titulo}</p>
                <b>{formataDinheiro(item.Valor)}</b>
              </Box>
            );
          } else {
            return (
              <Box gridColumn={gridColumn.gc6} key={item.Titulo}>
                <p>{item.Titulo}</p>
                <b>{item.Valor}</b>
              </Box>
            );
          }
        })}
      </Grid>
    </Box>
  );
}

export default TotalizadorRelatorio;
