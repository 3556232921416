import {
  Badge,
  Box,
  Grid,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import { GoCheck, GoX } from 'react-icons/go';
import '../../App.css';
import IconeDeletar from '../../components/Icones/IconeDeletar/IconeDeletar';
import IconeDownload from '../../components/Icones/IconeDownload/IconeDownload';
import IconeEditar from '../../components/Icones/IconeEditar/IconeEditar';
import InputCheckbox from '../../components/Inputs/InputCheckbox/InputCheckbox';
import SecaoCadastro from '../../components/SecaoCadastro/SecaoCadastro';
import { filtrar } from '../../utils/filtros';
import { formataData, formataDinheiro } from '../../utils/formatador';
import { gridColumn } from '../../utils/gridColumn';
import { sortAZ, sortFunction, sortInt } from '../../utils/utils';
import './Tabela.css';
import ReactPaginate from 'react-paginate';
import { AiFillLeftCircle, AiFillRightCircle } from 'react-icons/ai';
import { IconContext } from 'react-icons';
import { v4 as uuidv4 } from 'uuid';

function Tabela({
  setFunction,
  data,
  columns,
  searchBar,
  titulo,
  useCheck = false,
  handleEdit = null,
  showDelete = true,
  handleDelete = null,
  handleDownload = null,
  mensagemNaoEncontrado = 'Nenhum dado encontrado!',
  pageSize = 10,
}) {
  const [desc, setDesc] = useState(false);
  const [page, setPage] = useState(0);
  const [filterData, setFilterData] = useState();

  useEffect(() => {
    if (data && data.length > 0){
      setFilterData(
        data
          .filter(item => filtrar(item, searchBar))
          .filter((item, index) => {
            return (index >= page * pageSize) & (index < (page + 1) * pageSize);
          })
      );
    }
    else{
      setFilterData([]);
    }
    
  }, [page, data, pageSize, searchBar]);

  columns = columns.filter(column => !column.hide);

  function handleDoubleClick(param, type = 'text', func = null) {
    setDesc(!desc);
    if (type === 'text') setFunction(sortAZ(data, desc, param));
    if (type === 'number') setFunction(sortInt(data, desc, param));
    if (type === 'money') setFunction(sortInt(data, desc, param));
    if (type === 'date') setFunction(sortAZ(data, desc, param));
    if (type === 'bool') setFunction(sortAZ(data, desc, param));
    if (type === 'function' && func)
      setFunction(sortFunction(data, desc, param, func));
  }

  function handleCheck(item, value) {
    let itens = data;
    for (let i = 0; i < itens.length; i++) {
      if (itens[i].Id === item.Id) {
        itens[i].Selecionado = value;
        break;
      }
    }
    setFunction([...itens]);
  }

  return (
    <Box mb={7} gridColumn={gridColumn.gc12} className="card">
      <SecaoCadastro titulo={titulo} />
      <Box className="cadastrados" gridColumn={gridColumn.gc12}>
        {filterData && filterData.length > 0 && (
          <>
            <TableContainer maxWidth={'100%'} whiteSpace={'revert'}>
              <Table>
                <Thead>
                  <Tr>
                    {useCheck && <Th></Th>}
                    {handleEdit && <Th></Th>}
                    {columns.map(column => (
                      <Th key={uuidv4()}>
                        <button
                          onDoubleClick={() =>
                            handleDoubleClick(
                              column.name,
                              column.type,
                              column.function
                            )
                          }
                          type="button"
                        >
                          {column.placeholder.toUpperCase()}
                        </button>
                      </Th>
                    ))}
                    {showDelete && handleDelete && <Th></Th>}
                    {handleDownload && <Th></Th>}
                  </Tr>
                </Thead>
                <Tbody key={uuidv4()}>
                  {filterData &&
                    filterData.length > 0 &&
                    filterData.map(item => (
                      <Tr
                        key={uuidv4()}
                        className={`${
                          item.Selecionado ? 'checked' : 'notChecked'
                        }`}
                      >
                        {useCheck && (
                          <Td key={uuidv4()}>
                            <InputCheckbox
                              item={item}
                              initialValue={item.Selecionado}
                              handleChange={handleCheck}
                            />
                          </Td>
                        )}
                        {handleEdit && (
                          <Td key={uuidv4()}>
                            <button
                              onClick={() => handleEdit(item)}
                              style={{
                                width: '15px',
                              }}
                            >
                              <IconeEditar />
                            </button>
                          </Td>
                        )}
                        {columns.map(column => {
                          switch (column.type) {
                            case 'money':
                              return (
                                <Td key={uuidv4()}>
                                  {formataDinheiro(get(item, column.name))}
                                </Td>
                              );
                            case 'date':
                              return (
                                <Td key={uuidv4()}>
                                  {formataData(get(item, column.name))}
                                </Td>
                              );
                            case 'function':
                              return (
                                <Td key={uuidv4()}>
                                  {column.function(get(item, column.name))}
                                </Td>
                              );
                            case 'bool':
                              return (
                                <Td key={uuidv4()}>
                                  {get(item, column.name) ? (
                                    <GoCheck />
                                  ) : (
                                    <GoX />
                                  )}
                                </Td>
                              );
                            case 'badges':
                              return (
                                <Td key={uuidv4()}>
                                  <Stack direction="row">
                                    {get(item, column.name).map(item => (
                                      <Badge colorScheme="purple">
                                        {item.Nome}
                                      </Badge>
                                    ))}
                                  </Stack>
                                </Td>
                              );
                            default:
                              return (
                                <Td key={uuidv4()}>{get(item, column.name)}</Td>
                              );
                          }
                        })}

                        {showDelete && handleDelete && (
                          <Td key={uuidv4()}>
                            <IconeDeletar
                              onClick={() => handleDelete(item)}
                              style={{
                                width: '15px',
                              }}
                            />
                          </Td>
                        )}
                        {handleDownload && (
                          <Td key={uuidv4()}>
                            <button
                              onClick={() => handleDownload(item)}
                              style={{
                                width: '15px',
                              }}
                              type="button"
                            >
                              <IconeDownload />
                            </button>
                          </Td>
                        )}
                      </Tr>
                    ))}
                </Tbody>
              </Table>
            </TableContainer>
            <Box className="pagination-container">
              <ReactPaginate
                containerClassName={'pagination'}
                activeClassName={'active'}
                pageClassName={'page-item'}
                onPageChange={event => setPage(event.selected)}
                breakLabel="..."
                pageCount={Math.ceil(data.length / pageSize)}
                previousLabel={
                  <IconContext.Provider
                    value={{ color: '#B8C1CC', size: '36px' }}
                  >
                    <AiFillLeftCircle />
                  </IconContext.Provider>
                }
                nextLabel={
                  <IconContext.Provider
                    value={{ color: '#B8C1CC', size: '36px' }}
                  >
                    <AiFillRightCircle />
                  </IconContext.Provider>
                }
              />
            </Box>
          </>
        )}
        {!data ||
          (data.length === 0 && (
            <Box mb={5} gridColumn={gridColumn.gc12}>
              <Grid
                gridTemplateColumns="repeat(12, 1fr)"
                mx={1}
                width="100%"
                height="100%"
                maxBlockSize={'300px'}
                className="itensRelatorio"
              >
                <Box gridColumn={gridColumn.gc6} key={mensagemNaoEncontrado}>
                  {mensagemNaoEncontrado}
                </Box>
              </Grid>
            </Box>
          ))}
      </Box>
    </Box>
  );
}

export default Tabela;
