const valor_inicial = {
  ProprietarioId: '',
  NomeProprietario: '',
  NomeVeiculo: '',
  Empresa: '',
  Condutor: '',
  Fabricante: '',
  AnoFabricacao: 0,
  Placa: '',
  Combustivel: 'Gasolina',
  Cor: '',
  Antt: 0,
  Observacoes: '',
  Id: '',
};

export { valor_inicial };
