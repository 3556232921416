import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import '../../App.css';
import BarraBotoes from '../../components/BarraBotoes/BarraBotoes';
import FormPadrao from '../../components/FormPadrao/FormPadrao';
import Pagina from '../../components/Pagina/Pagina';
import Tabela from '../../components/Tabela/Tabela';
import api from '../../services/api';
import './NotasCompra.css';

function ListarNotasCompra() {
  const navigate = useNavigate();
  const [notasCompra, setNotasCompra] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchBar, setSearchBar] = useState('');

  useEffect(() => {
    async function GetNotasCompra() {
      let payload = {
        database: localStorage.getItem('database'),
        data: '{}',
      };
      let res = await api.post('/ListNotasCompra/Get', payload);
      if (res.status === 200) {
        let data = res.data;
        data.NotasCompra.forEach(b => {
          b.Selecionado = false;
        });
        setNotasCompra(data.NotasCompra);
        setLoading(false);
      } else {
        alert('Erro ao buscar dados');
      }
    }
    GetNotasCompra();
  }, []);

  function handleEdit(item) {
    navigate(`/NotasCompra/Editar/${item.Id}`);
  }

  function handleClick() {
    navigate('/NotasCompra/Editar');
  }


  return (
    <>
      <Pagina loading={loading}>
        <FormPadrao>
          <BarraBotoes
            botoes={[
              {
                children: 'Novo',
                onClick: handleClick,
                variant: 'turquesa',
              },
            ]}
            search={{
              placeholder: 'Procurar por notas de compra',
              onChange: e => setSearchBar(e.target.value),
            }}
          />
          <Tabela
            titulo={'LISTAGEM DE NOTAS DE COMPRA'}
            setFunction={setNotasCompra}
            data={notasCompra}
            columns={[
              {
                placeholder: 'CÓDIGO',
                name: 'Codigo',
                type: 'text',
              },
              {
                placeholder: 'EMPRESA',
                name: 'Empresa',
                type: 'text',
              },
              {
                placeholder: 'DEPÓSITO',
                name: 'Deposito',
                type: 'text',
              },
              {
                placeholder: 'CLIENTE/FORNECEDOR',
                name: 'Fornecedor',
                type: 'text',
              },
              {
                placeholder: 'NÚMERO',
                name: 'NumeroNota',
                type: 'text',
              },
              {
                placeholder: 'EMISSÃO',
                name: 'DataEmissaoNota',
                type: 'date',
              },
              {
                placeholder: 'Lançada?',
                name: 'Finalizado',
                type: 'bool',
              },
            ]}
            searchBar={searchBar}
            handleEdit={handleEdit}
            showDelete={false}
            useCheck={false}
          />
        </FormPadrao>
      </Pagina>
    </>
  );
}

export default ListarNotasCompra;
