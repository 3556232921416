import React from 'react';
import { FaUndo } from 'react-icons/fa';
import './IconeResetar.css';

function IconeResetar({ ...props }) {
  return (
    <button {...props}>
      <FaUndo className="IconeResetar" />
    </button>
  );
}

export default IconeResetar;
