import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import '../../App.css';
import InputData from '../../components/Inputs/InputData/InputData';
import Pagina from '../../components/Pagina/Pagina';
import api from '../../services/api';
import { getFiltroPadraoRelatorioFechamentoPDV } from '../../utils/filtros';
import { gridColumn } from '../../utils/gridColumn';
import { ajustaDatas } from '../../utils/utils';
import { baixarArquivoBase64 } from '../../utils/download';
import Filtros from '../../components/Filtros/Filtros';
import InputFiltro from '../../components/Inputs/InputFiltro/InputFiltro';
import InputTexto from '../../components/Inputs/InputTexto/InputTexto';
import BarraBotoes from '../../components/BarraBotoes/BarraBotoes';
import FormikPadrao from '../../components/FormikPadrao/FormikPadrao';
import TotalizadorRelatorio from '../../components/TotalizadorRelatorio/TotalizadorRelatorio';
import Tabela from '../../components/Tabela/Tabela';

function RelPedidosPDV() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [search] = useSearchParams();
  const [searchBar, setSearchBar] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [relPedidosPDV, setRelPedidosPDV] = useState([]);
  const [caixa, setCaixa] = useState({
    Email: '',
    SaldoAtual: 0,
  });
  const [operacoes, setOperacoes] = useState([]);

  async function GetRelPedidosPDV(filtros) {
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(filtros, null, 2),
    };
    let res = await api.post('/PDVReport/GetList', payload);

    if (res.status === 200) {
      let data = res.data;
      console.log(data);
      setRelPedidosPDV(data.relatorio.Pedidos);
      setCaixa(data.relatorio.Caixa);
      setOperacoes(data.relatorio.Operacoes);
      setLoading(false);
    }
  }

  useEffect(() => {
    GetRelPedidosPDV(getFiltroPadraoRelatorioFechamentoPDV());
  }, []);

  useEffect(() => {
    if (search.get('filter')) {
      setShowFilters(search.get('filter') === 'true');
      GetRelPedidosPDV(getFiltroPadraoRelatorioFechamentoPDV());
    }
  }, [search]);

  function handleEdit(item) {
    navigate(`/Pedidos/Editar/${item.Id}`);
  }

  const getPDF = async () => {
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(relPedidosPDV, null, 2),
    };
    baixarArquivoBase64('/PDVReport/GetPDF', payload);
  };

  return (
    <Pagina loading={loading}>
      <FormikPadrao
        initialValues={getFiltroPadraoRelatorioFechamentoPDV()}
        onSubmit={async values => {
          await GetRelPedidosPDV(values);
        }}
      >
        <BarraBotoes
          maxWindowSize={1030}
          botoes={[
            {
              children: 'Exportar PDF',
              onClick: getPDF,
              variant: 'rosa',
            },
          ]}
          search={{
            placeholder: 'Procurar por pedidos',
            onChange: e => setSearchBar(e.target.value),
          }}
          showFilters={showFilters}
          setShowFilters={setShowFilters}
        />
        <FilterMenu showFilters={showFilters} setShowFilters={setShowFilters} />
        <TotalizadorRelatorio
          items={[
            {
              Titulo: 'CAIXA',
              Valor: caixa.Email,
              type: 'money',
            },
            {
              Titulo: 'SALDO ATUAL',
              Valor: caixa.SaldoAtual,
              type: 'money',
            },
          ]}
        />
        <Tabela
          titulo={'PEDIDOS'}
          setFunction={setRelPedidosPDV}
          data={RelPedidosPDV}
          columns={[
            {
              placeholder: 'CÓDIGO',
              name: 'Codigo',
              type: 'text',
            },
            {
              placeholder: 'CLIENTE',
              name: 'Cliente',
              type: 'text',
            },
            {
              placeholder: 'FORMA DE PAGAMENTO',
              name: 'Data',
              type: 'date',
            },
            {
              placeholder: 'VALOR',
              name: 'CustoProdutos',
              type: 'money',
            },
          ]}
          searchBar={searchBar}
          handleEdit={handleEdit}
        />
        <Tabela
          titulo={'OPERAÇÕES'}
          setFunction={setOperacoes}
          data={operacoes}
          columns={[
            {
              placeholder: 'TIPO',
              name: 'TipoOperacao',
              type: 'text',
            },
            {
              placeholder: 'DATA',
              name: 'Data',
              type: 'date',
            },
            {
              placeholder: 'VALOR',
              name: 'Valor',
              type: 'money',
            },
            {
              placeholder: 'OBSERVAÇÕES',
              name: 'Observacao',
              type: 'text',
            },
          ]}
          handleEdit={handleEdit}
        />
      </FormikPadrao>
    </Pagina>
  );
}

function FilterMenu({ showFilters, setShowFilters }) {
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    ajustaDatas(values.PeriodoLancamento, setFieldValue);
  }, [values.PeriodoLancamento, setFieldValue]);

  return (
    <Filtros showFilters={showFilters} setShowFilters={setShowFilters}>
      <InputFiltro
        titulo="EMAIL"
        id="Email"
        name="Email"
        component={InputTexto}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="Data de Abertura"
        id="DataAbertura"
        name="DataAbertura"
        component={InputData}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="Data Fechamento"
        id="DataFechamento"
        name="DataFechamento"
        component={InputData}
        gridColumn={gridColumn.gc4}
      />
    </Filtros>
  );
}

export default RelPedidosPDV;
