import { useDisclosure } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import '../../App.css';
import Alerta from '../../components/Alerta/Alerta';
import AlertaConfirmacao from '../../components/AlertaConfirmacao/AlertaConfirmacao';
import BarraBotoes from '../../components/BarraBotoes/BarraBotoes';
import FormPadrao from '../../components/FormPadrao/FormPadrao';
import Pagina from '../../components/Pagina/Pagina';
import Tabela from '../../components/Tabela/Tabela';
import api from '../../services/api';
import './Veiculos.css';

function ListarVeiculos() {
  const navigate = useNavigate();
  const [veiculos, setVeiculos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchBar, setSearchBar] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false });
  const [alertStatus, setAlertStatus] = useState('success');
  const {
    isOpen: isOpenExcluir,
    onOpen: onOpenExcluir,
    onClose: onCloseExcluir,
  } = useDisclosure({
    defaultIsOpen: false,
  });
  const [alertTitle, setAlertTitle] = useState('');
  const [message, setMessage] = useState('');
  const [selectedItem, setSelectedItem] = useState('');

  useEffect(() => {
    async function GetVeiculos() {
      let payload = {
        database: localStorage.getItem('database'),
        data: '{}',
      };
      let res = await api.post('/ListVeiculos/Get', payload);
      if (res.status === 200) {
        let data = res.data;
        data.Veiculos.forEach(b => {
          b.Selecionado = false;
        });
        setVeiculos(data.Veiculos);
        setLoading(false);
      } else {
        alert('Erro ao buscar dados');
      }
    }
    GetVeiculos();
  }, []);

  function handleEdit(item) {
    navigate(`/Veiculos/Editar/${item.Id}`);
  }

  function handleClick() {
    navigate('/Veiculos/Editar');
  }

  function handleDelete(item) {
    setSelectedItem(item.Id);
    setAlertTitle('Excluir');
    setMessage('Deseja excluir o veículo?');
    onOpenExcluir();
  }

  let deleteItem = async () => {
    let payload = {
      data: selectedItem,
      database: localStorage.getItem('database'),
    };
    let res = await api.post('/ListVeiculos/Delete', payload);
    let data = res.data;
    onCloseExcluir();
    if (data.Success) {
      setAlertStatus('success');
      setAlertTitle(data.MSG);
      onOpen();
    } else {
      setVeiculos(veiculos.filter(item => item.Id !== selectedItem));
      setAlertStatus('error');
      setAlertTitle(data.MSG);
      onOpen();
    }
  };

  return (
    <>
      <AlertaConfirmacao
        isOpen={isOpenExcluir}
        onClose={onCloseExcluir}
        description={message}
        title={alertTitle}
        onConfirm={() => deleteItem()}
      />
      <Alerta
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        alertStatus={alertStatus}
        title={alertTitle}
      />
      <Pagina loading={loading}>
        <FormPadrao>
          <BarraBotoes
            botoes={[
              {
                children: 'Novo',
                onClick: handleClick,
                variant: 'turquesa',
              },
            ]}
            search={{
              placeholder: 'Procurar por veículos',
              onChange: e => setSearchBar(e.target.value),
            }}
          />
          <Tabela
            titulo={'LISTAGEM DE VEÍCULOS'}
            setFunction={setVeiculos}
            data={veiculos}
            columns={[
              {
                placeholder: 'NOME DO VEÍCULO',
                name: 'NomeVeiculo',
                type: 'text',
              },
              {
                placeholder: 'PROPRIETÁRIO',
                name: 'NomeProprietario',
                type: 'text',
              },
              {
                placeholder: 'EMPRESA',
                name: 'Empresa',
                type: 'text',
              },
              {
                placeholder: 'CONDUTOR',
                name: 'Condutor',
                type: 'text',
              },
              {
                placeholder: 'MARCA',
                name: 'Fabricante',
                type: 'text',
              },
              {
                placeholder: 'ANO DE FABRICAÇÃO',
                name: 'AnoFabricacao',
                type: 'text',
              },
              {
                placeholder: 'PLACA',
                name: 'Placa',
                type: 'text',
              },
              {
                placeholder: 'COMBUSTÍVEL',
                name: 'Combustivel',
                type: 'text',
              },
              {
                placeholder: 'COR',
                name: 'Cor',
                type: 'text',
              },
              {
                placeholder: 'REGISTRO ANTT',
                name: 'Antt',
                type: 'text',
              },
            ]}
            searchBar={searchBar}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            useCheck={false}
          />
        </FormPadrao>
      </Pagina>
    </>
  );
}

export default ListarVeiculos;
