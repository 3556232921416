import Decimal from 'decimal.js';
import { isNotNullOrEmpty } from './utils';

const getSomaLista = (lista, campo) => {
  if (lista.length === 0) return new Decimal(0);
  if (!isNotNullOrEmpty(campo)) {
    return lista.reduce((accumulator, currentValue) => {
      return accumulator.plus(new Decimal(currentValue));
    }, new Decimal(0));
  } else {
    return lista.reduce((accumulator, currentValue) => {
      return accumulator.plus(new Decimal(currentValue[campo]));
    }, new Decimal(0));
  }
};

const getSomaListaFloat = (lista, campo) => {
  return parseFloat(getSomaLista(lista, campo).toFixed(2));
};

export { getSomaLista, getSomaListaFloat };
