import React, { useEffect, useState } from 'react';
import { BsList } from 'react-icons/bs';
import LogoERP from '../../../images/logo.png';
import MenuLateral from '../MenuLateral';
import './HeaderMenuLateral.css';

function HeaderMenuLateral({ menuState, handleMenuChange }) {
  const [showMenu, setShowMenu] = useState(menuState);

  useEffect(() => {
    setShowMenu(menuState);
  }, [menuState]);

  return (
    <div
      className={`${
        showMenu ? 'navegacaomenulateral ' : 'hidemenu '
      } MenuLateralContainer`}
    >
      <div className="headermenulateral">
        {showMenu && (
          <img className="headerlogo" src={LogoERP} alt="Logo ERP" />
        )}
        <button
          type="button"
          className="iconebutton"
          onClick={handleMenuChange}
        >
          <BsList className="iconemenu" />
        </button>
      </div>
      {showMenu && <MenuLateral />}
    </div>
  );
}

export default HeaderMenuLateral;
