import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import '../../App.css';
import Alerta from '../../components/Alerta/Alerta';
import BarraBotoes from '../../components/BarraBotoes/BarraBotoes';
import Card from '../../components/Card/Card';
import FormikPadrao from '../../components/FormikPadrao/FormikPadrao';
import IconeResetar from '../../components/Icones/IconeResetar/IconeResetar';
import IconeSeta from '../../components/Icones/IconeSeta/IconeSeta';
import InputAutoComplete from '../../components/Inputs/InputAutoComplete/InputAutoComplete';
import InputFloat from '../../components/Inputs/InputFloat/InputFloat';
import InputFormik from '../../components/Inputs/InputFormik/InputFormik';
import InputSelect from '../../components/Inputs/InputSelect/InputSelect';
import InputTexto from '../../components/Inputs/InputTexto/InputTexto';
import Pagina from '../../components/Pagina/Pagina';
import api from '../../services/api';
import { formataDinheiro } from '../../utils/formatador';
import { gridColumn } from '../../utils/gridColumn';
import { addIDtoURL } from '../../utils/utils';
import './TabelasPreco.css';
import { v4 as uuidv4 } from 'uuid';

function EditarTabelasPreco() {
  const navigate = useNavigate();
  const [tabela, setTabela] = useState({
    Nome: '',
    Genero: '00 – Mercadoria para Revenda',
    Categoria: '',
    CategoriaId: '',
    Empresa: '',
    EmpresaId: '',
    NoCustos: false,
    Custos: [],
    Id: '',
  });
  const [produtos, setProdutos] = useState([]);
  const [loading, setLoading] = useState(true);

  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false });
  const [alertStatus, setAlertStatus] = useState('success');
  const [alertTitle, setAlertTitle] = useState('');

  let { id } = useParams();

  /*async function GetProdutos(forceUpdate, tabelaPrecos = null) {
    if (!tabelaPrecos) {
      tabelaPrecos = tabela;
    }
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(tabelaPrecos, null, 2),
      arg: JSON.stringify(forceUpdate, null, 2),
    };
    let res = await api.post('/EditTabelas/GetProdutos', payload);
    if (res.status === 200) {
      let data = res.data.Produtos;
      console.log(data);
      setProdutos(data);
      setLoading(false);
    } else {
      alert('Erro ao buscar dados');
    }
  }*/

  useEffect(() => {
    async function GetProdutos(forceUpdate, tabelaPrecos = null) {
      if (!tabelaPrecos) {
        tabelaPrecos = tabela;
      }
      let payload = {
        database: localStorage.getItem('database'),
        data: JSON.stringify(tabelaPrecos, null, 2),
        arg: JSON.stringify(forceUpdate, null, 2),
      };
      let res = await api.post('/EditTabelas/GetProdutos', payload);
      if (res.status === 200) {
        let data = res.data.Produtos;
        console.log(data);
        setProdutos(data);
        setLoading(false);
      } else {
        alert('Erro ao buscar dados');
      }
    }

    async function GetTabela() {
      let payload = {
        database: localStorage.getItem('database'),
        data: id,
      };
      let res = await api.post('/EditTabelas/Get', payload);
      if (res.status === 200) {
        let data = res.data.TabelaPreco;
        delete data.Custos;
        if (data.Id) {
          GetProdutos(true, data);
        } else {
          GetProdutos(false, data);
        }
        setTabela(data);
        setLoading(false);
      } else {
        alert('Erro ao buscar dados');
      }
    }
    GetTabela();
  }, [id]);

  let save = async (tabela, produtos) => {
    setLoading(true);
    let payload = {
      data: tabela,
      arg: produtos,
      database: localStorage.getItem('database'),
    };
    let res = await api.post('/EditTabelas/Save', payload);
    let data = res.data;
    setLoading(false);
    if (data.Success) {
      setAlertStatus('success');
      setAlertTitle(data.MSG);
      onOpen();
      addIDtoURL(id, data.ItemID);
    } else {
      setAlertStatus('error');
      setAlertTitle(data.MSG);
      onOpen();
    }
  };
  return (
    <>
      <Alerta
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        alertStatus={alertStatus}
        title={alertTitle}
      />
      <Pagina loading={loading}>
        <FormikPadrao
          enableReinitialize={true}
          initialValues={{ Tabela: tabela, Produtos: produtos }}
          validationSchema={yup.object({
            // TipoUsuario: yup.string().required('O campo é obrigatório.').nullable(),
            // Username: yup.string().required('O campo é obrigatório.').nullable(),
            // Email: yup.string().email('Digite um email válido').required('O campo é obrigatório.').nullable(),
            // Administrador: yup.boolean().required('O campo é obrigatório.').nullable(),
            // Password: yup.string().required('O campo é obrigatório.').nullable(),
            // RepetirPassword: yup.string().required('O campo é obrigatório.').oneOf([yup.ref('password')], 'As senhas devem ser iguais').nullable(),
            // Permissões: yup.object().required('O campo é obrigatório.').nullable(),
          })}
          onSubmit={async values => {
            //FIX
            delete values.Tabela.Custos;
            //FIX
            await save(
              JSON.stringify(values.Tabela, null, 2),
              JSON.stringify(values.Produtos, null, 2)
            );
          }}
        >
          <Interno navigate={navigate} />
        </FormikPadrao>
      </Pagina>
    </>
  );
}

function Interno({ navigate }) {
  const { values, touched, setFieldValue } = useFormikContext();

  useEffect(()=>
  {
    console.log(touched)
  },[touched])

  let titulo = `${
    values.Tabela.Id !== '' ? 'Editar' : 'Nova'
  } Tabela de Preços`;

  const generosProduto = [
    {
      value: '00 – Mercadoria para Revenda',
      label: '00 – Mercadoria para Revenda',
    },
    { value: '01 – Matéria-Prima', label: '01 – Matéria-Prima' },
    { value: '01', label: '01 - Entrada Tributável com Alíquota Zero' },
    { value: '02 – Embalagem', label: '02 – Embalagem' },
    { value: '03 – Produto em Processo', label: '03 – Produto em Processo' },
    { value: '04 – Produto Acabado', label: '04 – Produto Acabado' },
    { value: '05 – Subproduto', label: '05 – Subproduto' },
    {
      value: '06 – Produto Intermediário',
      label: '06 – Produto Intermediário',
    },
    {
      value: '07 – Material de Uso e Consumo',
      label: '07 – Material de Uso e Consumo',
    },
    { value: '08 – Ativo Imobilizado', label: '08 – Ativo Imobilizado' },
    { value: '10 – Outros insumos', label: '10 – Outros insumos' },
    { value: '99 – Outras', label: '99 – Outras' },
  ];

  /*
  useEffect(() => {
    if (!values.Permissoes.Configuracoes) {
      setFieldValue('Permissoes.Configuracoes', false);
      setFieldValue('IsAdmin', false);
    }
  }, [setFieldValue, values.Permissoes.Configuracoes]);*/

  return (
    <>
      <BarraBotoes
        botoes={[
          {
            variant: 'vermelho',
            onClick: () => navigate('/TabelasPreco/Listar'),
            children: 'Cancelar',
          },
          {
            variant: 'turquesa',
            type: 'submit',
            children: 'Salvar',
          },
        ]}
        caminho={[
          { nome: 'Tabelas de Preço', path: '/TabelasPreco/Listar' },
          { nome: titulo },
        ]}
      />
      <Card titulo={titulo}>
        <InputFormik
          titulo="Nome"
          id="Nome"
          name="Tabela.Nome"
          component={InputTexto}
          gridColumn={gridColumn.gc3}
        />
        <InputFormik
          titulo="Empresa"
          id="Empresa"
          name="Tabela.Empresa"
          component={InputAutoComplete}
          gridColumn={gridColumn.gc3}
          path="Empresa"
          autocomplete={true}
          database={localStorage.getItem('database')}
        />
        <InputFormik
          titulo="Gênero"
          id="Genero"
          name="Tabela.Genero"
          component={InputSelect}
          options={generosProduto}
          gridColumn={gridColumn.gc3}
        />
        <InputFormik
          titulo="Categoria"
          id="Categoria"
          name="Tabela.Categoria"
          component={InputAutoComplete}
          gridColumn={gridColumn.gc3}
          path="Categoria"
          autocomplete={true}
          database={localStorage.getItem('database')}
        />
      </Card>
      <Card titulo="PRODUTOS">
        <Box p={12} gridColumn={gridColumn.gc12}>
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>Código</Th>
                  <Th>Produto</Th>
                  <Th>Preço Custo</Th>
                  <Th>MVA (%)</Th>
                  <Th>Preço Venda</Th>
                  <Th></Th>
                  <Th>Usar Padrão? </Th>
                </Tr>
              </Thead>
              <Tbody>
                {values.Produtos.map(produto => (
                  <Tr key={uuidv4()}>
                    <Td key={uuidv4()}>{produto.CodigoProduto}</Td>
                    <Td key={uuidv4()}>{produto.Produto}</Td>
                    <Td key={uuidv4()}>{formataDinheiro(produto.PrecoCusto)}</Td>
                    <Td key={uuidv4()}>
                      <InputFormik
                        id={`Produtos[${values.Produtos.indexOf(produto)}]MVA`}
                        name={`Produtos[${values.Produtos.indexOf(
                          produto
                        )}]MVA`}
                        component={InputFloat}
                      
                      />
                      <span>Valor Proposto : {produto.MVA_OLD} %</span>
                    </Td>
                    <Td key={uuidv4()}>
                      <InputFormik
                        id={`Produtos[${values.Produtos.indexOf(
                          produto
                        )}]PrecoVenda`}
                        name={`Produtos[${values.Produtos.indexOf(
                          produto
                        )}]PrecoVenda`}
                        component={InputFloat}
                      />
                      <span>
                        Valor Proposto :
                        {formataDinheiro(produto.PrecoVenda_OLDmoney)}
                      </span>
                    </Td>
                    <Td key={uuidv4()}>
                      {produto.ManualChange === 1 ? (
                        <IconeSeta up={false} cor="vermelho" />
                      ) : produto.ManualChange === 2 ? (
                        <IconeSeta cor="verde" />
                      ) : (
                        <IconeSeta cor="branco" />
                      )}
                    </Td>
                    <Td key={uuidv4()}>
                      <IconeResetar />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </Card>
    </>
  );
}

export default EditarTabelasPreco;
