import React from 'react';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import './IconeSeta.css';

function IconeSeta({ up = true, cor = "verde" }) {
  let classe = cor === "verde" ? "iconeSetaVerde" : cor === "vermelho" ? "iconeSetaVermelha" : "iconeSetaBranca";
  if (up) {
    return (
      <FaChevronUp className={classe} />
    );
  } else {
    return (
      <FaChevronDown classe={classe} />
    );
  }
}

export default IconeSeta;
