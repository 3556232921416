import { Input } from '@chakra-ui/react';
import $ from 'jquery';
import 'jquery-ui-bundle/jquery-ui';
import 'jquery-ui-bundle/jquery-ui.css';
import React, { useEffect } from 'react';
import './InputAutoComplete.css';

function InputAutoComplete({
  placeholder,
  path,
  database,
  value,
  onSourceSelect,
  minLength = 0,
  ...rest
}) {
  useEffect(() => {
    try {
      $('#' + rest.id).autocomplete({
        delay: 0,
        autoFocus: true,
        autoFill: true,
        scrollHeight: 220,
        minLength: minLength,
        source: function (request, response) {
          $.ajax({
            url: `https://api.erpsuite.com.br/api/AutoComplete/${path}?database=${database}&query=${
              value ? value : ''
            }`,
            dataType: 'json',
            success: function (data) {
              response(data.suggestions);
            },
          });
        },
        select: function (event, ui) {
          onSourceSelect(rest.id, ui.item.label);
        },
        open: function () {
          $(this).removeClass('ui-corner-all').addClass('ui-corner-top');
        },
        close: function () {
          $(this).removeClass('ui-corner-top').addClass('ui-corner-all');
        },
      });
    } catch (error) {
      console.log(error);
    }
  }, [path, onSourceSelect, value, database, rest.id, minLength]);

  return (
    <Input
      className="inputautocomplete"
      type="text"
      placeholder={placeholder}
      value={value}
      onClick={ () => $('#' + rest.id).autocomplete('search', '') }
      {...rest}
    />
  );
}

export default InputAutoComplete;
