import { Flex, Grid, GridItem, List, ListItem } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import {
    AiFillMail,
    AiFillPhone,
    AiFillVideoCamera,
    AiOutlineWhatsApp
} from 'react-icons/ai';
import DashboardCard from '../../components/DashboardCard/DashboardCard';
import GraficoFluxoDiario from '../../components/Gráficos/GraficoFluxoDiario';
import Pagina from '../../components/Pagina/Pagina';
import api from '../../services/api';
import { formataDinheiro } from '../../utils/formatador';
import { gridColumn } from '../../utils/gridColumn';
import './Dashboard.css';

import { extendedTheme } from '../../utils/theme';

function Dashboard() {
  const [dashboard, setDashboard] = useState({
    TotalReceberHoje: 0.0,
    TotalReceberPercentualUltimoPeriodo: 0.0,
    TotalPagarHoje: 0.0,
    TotalPagarPercentualUltimoPeriodo: 0.0,
    TotalVendas: 0.0,
    TotalVendasPercentualUltimoPeriodo: 0.0,
    TotalRecebimentosAtraso: 0.0,
    TotalRecebimentosAtrasoPercentualUltimoPeriodo: 0.0,
    TotalPagamentosAtraso: 0.0,
    TotalPagamentosAtrasoPercentualUltimoPeriodo: 0.0,
    UltimaAtualizacao: Date(),
    FluxoCaixa: {},
    Bancos: [],
  });
  const [loading, setLoading] = useState(true);
  const [saldoTotal, setSaldoTotal] = useState(0);

  useEffect(() => {
    const GetDashboard = async () => {
      let payload = {
        database: localStorage.getItem('database'),
        data: '{}',
      };
      let res = await api.post('/Dashboard/Get', payload);
      let data = res.data;
      setDashboard(data.data);
      setLoading(false);
    };

    GetDashboard();
  }, []);

  useEffect(() => {
    if (dashboard && Object.keys(dashboard).length > 0) {
      let bancosList = dashboard.Bancos;
      let saldo = 0;

      bancosList.map(banco => (saldo += banco.Saldo));

      setSaldoTotal(saldo);
      
    }
  }, [dashboard]);

  const bancos = dashboard.Bancos;

  return (
    <Pagina loading={loading}>
      <Flex flexGrow={1} id="content" justifyContent="center" height="100%">
        <Grid
          gridTemplateColumns="repeat(12, 1fr)"
          gap={4}
          mx={6}
          width="100%"
          height="fit-content"
          paddingTop="16px"
        >
          <GridItem gridColumn={gridColumn.gc4}>
            <DashboardCard titulo="A RECEBER" subtitulo="Contas a receber hoje" borderColor={extendedTheme.colors.successColor}>
              <p className="gradientText">
                {formataDinheiro(dashboard.TotalReceberHoje)}
              </p>
            </DashboardCard>
          </GridItem>
          <GridItem gridColumn={gridColumn.gc4}>
            <DashboardCard titulo="A PAGAR" subtitulo="Contas a pagar hoje" borderColor={extendedTheme.colors.errorColor}>
            <p className="gradientText">
                {formataDinheiro(dashboard.TotalPagarHoje)}
              </p>
            </DashboardCard>            
          </GridItem>
          <GridItem gridColumn={gridColumn.gc4}>
            <DashboardCard titulo="PRECISA DE AJUDA?" subtitulo="Fale com um especialista" borderColor={extendedTheme.colors.warningColor}>
            <div className="helpIcons">
                <AiFillVideoCamera />
                <AiFillPhone />
                <AiFillMail />
                <AiOutlineWhatsApp />
              </div>
            </DashboardCard>
          </GridItem>
          <GridItem gridColumn={gridColumn.gc8}>
            <DashboardCard titulo="FLUXO DE CAIXA" subtitulo="Contas a receber hoje">
              {dashboard.FluxoCaixa.Dias && (
                <GraficoFluxoDiario data={dashboard.FluxoCaixa.Dias} />
              )}
            </DashboardCard>
          </GridItem>
          <GridItem gridColumn={gridColumn.gc4}>
            <DashboardCard titulo="SALDO TOTAL DAS CONTAS" subtitulo="Saldo das contas correntes">
              <div className="gradientText saldoTotal">{formataDinheiro(saldoTotal)}</div>
              <List>
                {bancos.map(banco => (
                  <ListItem className="bancosItem" display="flex" flexDirection="column" key={banco.Nome}>
                    <p>{banco.Nome}</p>
                    <b>{formataDinheiro(banco.Saldo)}</b>                  
                  </ListItem>
                ))}
              </List>
            </DashboardCard>
          </GridItem>
          {/* <GridItem p={4} gridColumn={gridColumn.gc8}>
              <div className="dashBoddys">
                <div className="sessaoVendas">
                  <h3 className="sessaoTitulo">GRÁFICO DE VENDAS</h3>
                  <p className="sessaoParagrafo">Gráfico de vendas de hoje</p>
                </div>
                <p className="gradientText">{dashboard.TotalVendas}</p>
                <GraficoVendas />
              </div>
            </GridItem>
            <GridItem p={4} gridColumn={gridColumn.gc4}>
              <div className="dashBoddys">
                <div className="sessaoRanking">
                  <h3 className="sessaoTitulo">RANKING DE VENDEDORES</h3>
                  <p className="sessaoParagrafo">
                    Ranking de vendedores por valores de venda
                  </p>
                </div>
              </div>
            </GridItem> */}
        </Grid>
      </Flex>
    </Pagina>
  );
}
export default Dashboard;
