import React from 'react';

import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import './ChevronUpDown.css';

function ChevronUpDown({ down = true }) {
  return <div className='chevronUpDown'>
    {down ? <BiChevronDown /> : <BiChevronUp className="chevron" />}
  </div>;
}

export default ChevronUpDown;
