import {
    Box,
    Flex,
    Grid,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useDisclosure
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import '../../App.css';
import Alerta from '../../components/Alerta/Alerta';
import Botao from '../../components/Botao/Botao';
import InputImagens from '../../components/Inputs/InputImagens/InputImagens';
import InputNumInt from '../../components/Inputs/InputNumInt/InputNumInt';
import InputSelect from '../../components/Inputs/InputSelect/InputSelect';
import InputTexto from '../../components/Inputs/InputTexto/InputTexto';
import InputToggle from '../../components/Inputs/InputToggle/InputToggle';
import Pagina from '../../components/Pagina/Pagina';
import SecaoCadastro from '../../components/SecaoCadastro/SecaoCadastro';
import api from '../../services/api';
import { gridColumn } from '../../utils/gridColumn';
import { addIDtoURL } from '../../utils/utils';
import './Produtos.css';

function EditarProduto() {
  const navigate = useNavigate();
  const optionsCat = ['00 - Mercadoria para Revenda'];
  const optionsCondicoesProduto = ['Novo'];
  const optionsLucro = ['10%'];
  const optionsOrigemProduto = ['Selecione'];
  const optionsTipoProduto = ['Selecione'];
  const optionsNCM = ['Selecione'];
  const optionsCest = ['Selecione'];
  const optionsUnidMedida = ['Selecione'];
  const [numeroSerie, setNumeroSerie] = useState([]);
  const [produtos, setProdutos] = useState({});
  const [loading, setLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false });
  const [alertStatus, setAlertStatus] = useState('success');
  const [alertTitle, setAlertTitle] = useState('');

  let { id } = useParams();

  useEffect(() => {
    async function GetProdutos() {
      let payload = {
        database: localStorage.getItem('database'),
        data: id,
      };
      let res = await api.post('/EditProdutos/Get', payload);
      if (res.status === 200) {
        let data = res.data;
        setProdutos(data.Produto);
        setLoading(false);
      } else {
        alert('Erro ao buscar dados');
      }
    }
    GetProdutos();
  }, [id]);

  let save = async value => {
    setLoading(true);
    let payload = {
      data: value,
      database: localStorage.getItem('database'),
    };
    let res = await api.post('/EditProdutos/Save', payload);
    let data = res.data;
    setLoading(false);
    if (data.Success) {
      setAlertStatus('success');
      setAlertTitle(data.MSG);
      onOpen();
      addIDtoURL(id, data.ItemID);
    } else {
      setAlertStatus('error');
      setAlertTitle(data.MSG);
      onOpen();
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues:
      Object.keys(produtos).length > 0
        ? produtos
        : {
            Codigo: '',
            Nome: '',
            Ativo: '',
            Genero: '',
            PrecoVenda: 0,
            MVA: '',
            Deposito: '',
            Condicao: '',
            Tipo: '',
            Marca: '',
            Prateleira: '',
            PesoKG: '',
            Largura: '',
            Altura: '',
            Profundidade: '',
            Volumes: '',
            ItensCaixa: '',
            Unidade: '',
            SaldoEstoque: '',
            EstoqueMinimo: '',
            CodigoBalanca: '',
            NumeroSerie: '',
          },
    validationSchema: yup.object({
      // Codigo: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
      // Nome: yup.string().required('O campo é obrigatório.').nullable(),
      // Ativo: yup.boolean().required('O campo é obrigatório.').nullable(),
      // Genero: yup.string().required('O campo é obrigatório.').nullable(),
      // PrecoVenda: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
      // MVA: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
      // Deposito: yup.string().required('O campo é obrigatório.').nullable(),
      // Condicao: yup.string().required('O campo é obrigatório.').nullable(),
      // Tipo: yup.string().required('O campo é obrigatório.').nullable(),
      // Marca: yup.string().required('O campo é obrigatório.').nullable(),
      // Prateleira: yup.string().required('O campo é obrigatório.').nullable(),
      // PesoKG: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
      // Largura: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
      // Altura: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
      // Profundidade: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
      // Volumes: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
      // ItensCaixa: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
      // Unidade: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
      // SaldoEstoque: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
      // EstoqueMinimo: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
      // CodigoBalanca: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
      // NumeroSerie: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
    }),
    onSubmit: async values => {
      await save(JSON.stringify(values, null, 2));
      
    },
  });

  function handleClick() {
    setNumeroSerie(...numeroSerie);
  }

  return (
    <>
      <Alerta
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        alertStatus={alertStatus}
        title={alertTitle}
      />
      <Pagina loading={loading}>
        <form className="body" onSubmit={formik.handleSubmit}>
          <Flex
            flexGrow={1}
            id="content"
            justifyContent="center"
            minHeight="100%"
          >
            <Grid
              gridTemplateColumns="repeat(12, 1fr)"
              gap={'10px'}
              mx={6}
              width="100%"
              height="fit-content"
            >
              <Box p={4} gridColumn={gridColumn.gc12} className="searchbuttons">
                {/* <InputSearch placeholder="Procurar por produtos" style={{ flex: 1 }} />  */}
                <Botao variant="rosa">Imprimir em Lote</Botao>
                <Botao
                  variant="vermelho"
                  onClick={() => navigate('/Produtos/Listar')}
                >
                  Cancelar
                </Botao>
                <Botao variant="turquesa" type="submit">
                  Salvar
                </Botao>
              </Box>
              <Box mb={7} pb={10} gridColumn={gridColumn.gc12} className="card">
                <Flex flexGrow={1} id="content" justifyContent="center">
                  <Grid
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={'10px'}
                    width="100%"
                    height="fit-content"
                  >
                    <SecaoCadastro titulo="CADASTRAR PRODUTOS" />
                    <Box p={2} gridColumn={gridColumn.gc3}>
                      <p>Código/SKU</p>
                      <InputNumInt
                        id="Codigo"
                        name="Codigo"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.Codigo}
                      />
                      {formik.touched.Codigo && formik.errors.Codigo ? (
                        <div>{formik.errors.Codigo}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc6}>
                      <p>Nome</p>
                      <InputTexto
                        id="Nome"
                        name="Nome"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.Nome}
                      />
                      {formik.touched.Nome && formik.errors.Nome ? (
                        <div>{formik.errors.Nome}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc2}>
                      <p>Ativo?</p>
                      <InputToggle
                        id="Ativo"
                        name="Ativo"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.Ativo}
                      />
                      {formik.touched.Ativo && formik.errors.Ativo ? (
                        <div>{formik.errors.Ativo}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc4}>
                      <p>Categoria/Gênero</p>
                      <InputSelect
                        id="Genero"
                        name="Genero"
                        options={optionsCat}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.Genero}
                      />
                      {formik.touched.Genero && formik.errors.Genero ? (
                        <div>{formik.errors.Genero}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc2}>
                      <p>Custo Médio</p>
                      <InputNumInt
                        id="PrecoCusto"
                        name="PrecoCusto"
                        options={optionsCat}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.PrecoCusto}
                      />
                      {formik.touched.PrecoCusto && formik.errors.PrecoCusto ? (
                        <div>{formik.errors.PrecoCusto}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc3}>
                      <p>Margem de Lucro (MVA)</p>
                      <InputSelect
                        id="MVA"
                        name="MVA"
                        options={optionsLucro}
                        placeholder="%"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.MVA}
                      />
                      {formik.touched.MVA && formik.errors.MVA ? (
                        <div>{formik.errors.MVA}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc2}>
                      <p>Valor de Venda</p>
                      <InputNumInt
                        id="PrecoVenda"
                        name="PrecoVenda"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.PrecoVenda}
                      />
                      {formik.touched.PrecoVenda && formik.errors.PrecoVenda ? (
                        <div>{formik.errors.PrecoVenda}</div>
                      ) : null}
                    </Box>
                  </Grid>
                </Flex>
              </Box>
              <Box mb={7} pb={10} gridColumn={gridColumn.gc12} className="card">
                <Flex flexGrow={1} id="content" justifyContent="center">
                  <Grid
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={'10px'}
                    width="100%"
                    height="fit-content"
                  >
                    <SecaoCadastro titulo="ESTOQUE" />
                    <Box p={2} gridColumn={gridColumn.gc12}>
                      <TableContainer>
                        <Table>
                          <Thead>
                            <Tr>
                              <Th>DEPÓSITO</Th>
                              <Th>TELEFONE</Th>
                              <Th>ESTADO</Th>
                              <Th>ESTOQUE</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            <Tr className="depósitos">
                              <Td></Td>
                              <Td></Td>
                              <Td></Td>
                              <Td></Td>
                            </Tr>
                          </Tbody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </Grid>
                </Flex>
              </Box>
              <Box mb={7} pb={10} gridColumn={gridColumn.gc12} className="card">
                <Flex flexGrow={1} id="content" justifyContent="center">
                  <Grid
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={'10px'}
                    width="100%"
                    height="fit-content"
                  >
                    <SecaoCadastro titulo="INFORMAÇÕES ADICIONAIS" />
                    <Box p={2} gridColumn={gridColumn.gc3}>
                      <p>Condiçoes do Produto</p>
                      <InputSelect
                        id="Condicao"
                        name="Condicao"
                        options={optionsCondicoesProduto}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.Condicao}
                      />
                      {formik.touched.Condicao && formik.errors.Condicao ? (
                        <div>{formik.errors.Condicao}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc3}>
                      <p>Marca</p>
                      <InputTexto
                        id="Marca"
                        name="Marca"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.Marca}
                      />
                      {formik.touched.Marca && formik.errors.Marca ? (
                        <div>{formik.errors.Marca}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc3}>
                      <p>Prateleira</p>
                      <InputTexto
                        id="Prateleira"
                        name="Prateleira"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.Prateleira}
                      />
                      {formik.touched.Prateleira && formik.errors.Prateleira ? (
                        <div>{formik.errors.Prateleira}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc3}>
                      <p>Código Balança</p>
                      <InputNumInt
                        id="CodigoBalanca"
                        name="CodigoBalanca"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.CodigoBalanca}
                      />
                      {formik.touched.CodigoBalanca &&
                      formik.errors.CodigoBalanca ? (
                        <div>{formik.errors.CodigoBalanca}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc3}>
                      <p>Peso</p>
                      <InputNumInt
                        id="PesoKG"
                        name="PesoKG"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.PesoKG}
                      />
                      {formik.touched.PesoKG && formik.errors.PesoKG ? (
                        <div>{formik.errors.PesoKG}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc3}>
                      <p>Largura</p>
                      <InputNumInt
                        id="Largura"
                        name="Largura"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.Largura}
                      />
                      {formik.touched.Largura && formik.errors.Largura ? (
                        <div>{formik.errors.Largura}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc3}>
                      <p>Altura</p>
                      <InputNumInt
                        id="Altura"
                        name="Altura"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.Altura}
                      />
                      {formik.touched.Altura && formik.errors.Altura ? (
                        <div>{formik.errors.Altura}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc3}>
                      <p>Profundidade</p>
                      <InputNumInt
                        id="Profundidade"
                        name="Profundidade"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.Profundidade}
                      />
                      {formik.touched.Profundidade &&
                      formik.errors.Profundidade ? (
                        <div>{formik.errors.Profundidade}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc3}>
                      <p>Volumes</p>
                      <InputNumInt
                        id="Volumes"
                        name="Volumes"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.Volumes}
                      />
                      {formik.touched.Volumes && formik.errors.Volumes ? (
                        <div>{formik.errors.Volumes}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc3}>
                      <p>Estoque Mínimo</p>
                      <InputTexto
                        id="EstoqueMinimo"
                        name="EstoqueMinimo"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.EstoqueMinimo}
                      />
                      {formik.touched.EstoqueMinimo &&
                      formik.errors.EstoqueMinimo ? (
                        <div>{formik.errors.EstoqueMinimo}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc3}>
                      <p>Alerta de Estoque Crítico</p>
                      <InputTexto />
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc3}>
                      <p>Saldo</p>
                      <InputNumInt
                        id="SaldoEstoque"
                        name="SaldoEstoque"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.SaldoEstoque}
                      />
                      {formik.touched.SaldoEstoque &&
                      formik.errors.SaldoEstoque ? (
                        <div>{formik.errors.SaldoEstoque}</div>
                      ) : null}
                    </Box>
                  </Grid>
                </Flex>
              </Box>
              <Box mb={7} pb={10} gridColumn={gridColumn.gc12} className="card">
                <Flex flexGrow={1} id="content" justifyContent="center">
                  <Grid
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={'10px'}
                    width="100%"
                    height="fit-content"
                  >
                    <SecaoCadastro titulo="FOTOS" />
                    <Box p={2} gridColumn={gridColumn.gc4}>
                      <p>Origem do Produto</p>
                      <InputImagens />
                    </Box>
                  </Grid>
                </Flex>
              </Box>
              <Box mb={7} pb={10} gridColumn={gridColumn.gc12} className="card">
                <Flex flexGrow={1} id="content" justifyContent="center">
                  <Grid
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={'10px'}
                    width="100%"
                    height="fit-content"
                  >
                    <SecaoCadastro titulo="DADOS PARA EMISSÃO DE NF-E" />
                    <Box p={2} gridColumn={gridColumn.gc4}>
                      <p>Origem do Produto</p>
                      <InputSelect options={optionsOrigemProduto} />
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc4}>
                      <p>Tipo do Produto</p>
                      <InputSelect
                        id="Tipo"
                        name="Tipo"
                        options={optionsTipoProduto}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.Tipo}
                      />
                      {formik.touched.Tipo && formik.errors.Tipo ? (
                        <div>{formik.errors.Tipo}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc4}>
                      <p>NCM</p>
                      <InputSelect options={optionsNCM} />
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc6}>
                      <p>CEST(Cód. Especificador da Substituição Tributária)</p>
                      <InputSelect options={optionsCest} />
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc4}>
                      <p>Unidade de Medida</p>
                      <InputSelect
                        id="Unidade"
                        name="Unidade"
                        options={optionsUnidMedida}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.Unidade}
                      />
                      {formik.touched.Unidade && formik.errors.Unidade ? (
                        <div>{formik.errors.Unidade}</div>
                      ) : null}
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc12}>
                      <h2
                        style={{
                          color: 'rgb(104, 102, 102)',
                        }}
                      >
                        <b>NÚMEROS DE SÉRIE</b>
                      </h2>
                    </Box>
                    <Box p={2} gridColumn={gridColumn.gc6}>
                      <p>Número de Série</p>
                      <InputNumInt
                        id="NumeroSerie"
                        name="NumeroSerie"
                        options={optionsUnidMedida}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.NumeroSerie}
                      />
                      {formik.touched.NumeroSerie &&
                      formik.errors.NumeroSerie ? (
                        <div>{formik.errors.NumeroSerie}</div>
                      ) : null}
                    </Box>
                    <Box mt={9} gridColumn={gridColumn.gc6}>
                      <button type="button" onClick={handleClick}>
                        <AiOutlinePlusCircle className="botaoPlus" />
                      </button>
                    </Box>
                  </Grid>
                </Flex>
              </Box>
            </Grid>
          </Flex>
        </form>
      </Pagina>
    </>
  );
}

export default EditarProduto;
