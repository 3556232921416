import { extendTheme } from '@chakra-ui/react';

const Botao = {
  baseStyle: {
    borderRadius: '7px',
    fontSize: '.875rem',
    fontWeight: 'bolder',
    minWidth: 'fit-content',
    paddingLeft: '20px',
    paddingRight: '20px',
    minHeight: '2.2rem',
  },
  variants: {
    amarelo: {
      border: 'solid 1px rgb(255, 209, 5)',
      color: 'rgb(226, 185, 5)',
      outlineColor: 'rgb(226, 185, 5)',
      backgroundColor: 'rgb(255, 254, 247)', // rgb(255, 209, 5) at 3% opacity over rgb(255, 255, 255)
      _hover: {
        backgroundColor: 'rgb(255, 249, 225)', // rgb(255, 209, 5) at 12% opacity over rgb(255, 255, 255)
      },
      _active: {
        backgroundColor: 'rgb(255, 246, 205)', // rgb(255, 209, 5) at 20% opacity over rgb(255, 255, 255)
      },

    },
    vermelho: {
      border: 'solid 1px rgb(255, 29, 35)',
      color: 'rgb(255, 29, 35)',
      outlineColor: 'rgb(255, 29, 35)',
      backgroundColor: 'rgb(255, 244, 244)', // rgb(255, 29, 35) at 5% opacity over rgb(255, 255, 255)
      _hover: {
        backgroundColor: 'rgb(255, 228, 228)', // rgb(255, 29, 35) at 12% opacity over rgb(255, 255, 255)
      },
      _active: {
        backgroundColor: 'rgb(255, 210, 211)', // rgb(255, 29, 35) at 20% opacity over rgb(255, 255, 255)
      },
    },
    turquesa: {
      border: 'solid 1px rgb(2, 204, 211)',
      color: 'rgb(2, 176, 183)',
      outlineColor: 'rgb(2, 176, 183)',
      backgroundColor: 'rgb(242, 252, 253)', // rgb(2, 204, 211) at 5% opacity over rgb(255, 255, 255)
      _hover: {
        backgroundColor: 'rgb(224, 249, 250)', // rgb(2, 204, 211) at 12% opacity over rgb(255, 255, 255)
      },
      _active: {
        backgroundColor: 'rgb(204, 245, 246)', // rgb(2, 204, 211) at 20% opacity over rgb(255, 255, 255)
      },
    },
    azulEscuro: {
      border: 'solid 1px rgb(21, 53, 238)',
      color: 'rgb(21, 53, 238)',
      outlineColor: 'rgb(21, 53, 238)',
      backgroundColor: 'rgb(243, 245, 254)', // rgb(21, 53, 238) at 5% opacity over rgb(255, 255, 255)
      _hover: {
        backgroundColor: 'rgb(227, 230, 253)', // rgb(21, 53, 238) at 12% opacity over rgb(255, 255, 255)
      },
      _active: {
        backgroundColor: 'rgb(208, 215, 252)', // rgb(21, 53, 238) at 20% opacity over rgb(255, 255, 255)
      },
    },
    rosa: {
      border: 'solid 1px rgb(229, 38, 247)',
      color: 'rgb(229, 38, 247)',
      outlineColor: 'rgb(229, 38, 247)',
      backgroundColor: 'rgb(254, 244, 255)', // rgb(229, 38, 247) at 5% opacity over rgb(255, 255, 255)
      _hover: {
        backgroundColor: 'rgb(252, 229, 254)', // rgb(229, 38, 247) at 12% opacity over rgb(255, 255, 255)
      },
      _active: {
        backgroundColor: 'rgb(250, 212, 253)', // rgb(229, 38, 247) at 20% opacity over rgb(255, 255, 255)
      },
    },
    azul: {
      border: 'solid 1px rgb(0, 183, 255)',
      color: 'rgb(0, 183, 255)',
      outlineColor: 'rgb(0, 183, 255)',
      backgroundColor: 'rgb(242, 251, 255)', // rgb(0, 183, 255) at 5% opacity over rgb(255, 255, 255)
      _hover: {
        backgroundColor: 'rgb(224, 246, 255)', // rgb(0, 183, 255) at 12% opacity over rgb(255, 255, 255)
      },
      _active: {
        backgroundColor: 'rgb(204, 241, 255)', // rgb(0, 183, 255) at 20% opacity over rgb(255, 255, 255)
      },
    },
    laranja: {
      border: 'solid 1px rgb(252, 132, 62)',
      color: 'rgb(252, 132, 62)',
      outlineColor: 'rgb(252, 132, 62)',
      backgroundColor: 'rgb(255, 249, 245)', // rgb(252, 132, 62) at 5% opacity over rgb(255, 255, 255)
      _hover: {
        backgroundColor: 'rgb(255, 240, 232)', // rgb(252, 132, 62) at 12% opacity over rgb(255, 255, 255)
      },
      _active: {
        backgroundColor: 'rgb(254, 230, 216)', // rgb(252, 132, 62) at 20% opacity over rgb(255, 255, 255)
      },
    },
    roxo: {
      border: 'solid 1px rgb(148, 40, 250)',
      color: 'rgb(148, 40, 250)',
      outlineColor: 'rgb(148, 40, 250)',
      backgroundColor: 'rgb(250, 244, 255)', // rgb(148, 40, 250) at 5% opacity over rgb(255, 255, 255)
      _hover: {
        backgroundColor: 'rgb(242, 229, 254)', // rgb(148, 40, 250) at 12% opacity over rgb(255, 255, 255)
      },
      _active: {
        backgroundColor: 'rgb(234, 212, 254)', // rgb(148, 40, 250) at 20% opacity over rgb(255, 255, 255)
      },
    },
    verde: {
      border: 'solid 1px rgb(2, 138, 108)',
      color: 'rgb(2, 138, 108)',
      outlineColor: 'rgb(2, 138, 108)',
      backgroundColor: 'rgb(242, 249, 248)', // rgb(2, 138, 108) at 5% opacity over rgb(255, 255, 255)
      _hover: {
        backgroundColor: 'rgb(224, 241, 237)', // rgb(2, 138, 108) at 12% opacity over rgb(255, 255, 255)
      },
      _active: {
        backgroundColor: 'rgb(204, 232, 226)', // rgb(2, 138, 108) at 20% opacity over rgb(255, 255, 255)
      },
    },
    verdeSucess: {
      border: 'solid 1px rgb(22, 209, 5)',
      color: 'rgb(22, 209, 5)',
      outlineColor: 'rgb(22, 209, 5)',
      backgroundColor: 'rgb(243, 253, 242)', // rgb(22, 209, 5) at 5% opacity over rgb(255, 255, 255)
      _hover: {
        backgroundColor: 'rgb(227, 249, 225)', // rgb(22, 209, 5) at 12% opacity over rgb(255, 255, 255)
      },
      _active: {
        backgroundColor: 'rgb(208, 246, 205)', // rgb(22, 209, 5) at 20% opacity over rgb(255, 255, 255)
      },
    },
    branco: {
      backgroundColor: 'rgb(254, 254, 255)',
      border: 'solid 1px grey',
      _hover: {
        backgroundColor: 'rgb(237, 242, 247)',
      },
      _active: {
        backgroundColor: 'rgb(226, 232, 240)',
      },
    },
    removeItem: {
      backgroundColor: 'rgb(255, 255, 255)',
      color: 'rgb(255, 29, 35)',
      outlineColor: 'rgb(255, 29, 35)',
      _hover: {
        backgroundColor: 'rgb(255, 220, 220)',
      },
      _active: {
        backgroundColor: 'rgb(255, 193, 193)',
      },
    },
    filtro: {
      padding: '0 8px',
      outlineColor: 'rgb(1, 166, 216)',
      color: 'rgb(1, 166, 216)',
      _hover: {
        color: 'rgb(1, 155, 202)',
      },
      _active: {
        color: 'rgb(0, 144, 188)',
      },
    },
  },
};

const colors = {
  searchColor: 'rgb(1, 166, 216)',//'rgb(2, 204, 211)',
  primaryColor: 'rgb(0, 183, 255)',
  secundaryColor: 'rgb(54, 238, 109)',
  successColor: "rgb(22, 209, 5)",
  errorColor: 'rgb(255, 29, 35)',
  warningColor: 'rgb(255, 209, 5)',

  primaryColor15: 'rgba(0, 183, 255, 0.15)',
  primaryColor25: 'rgba(0, 183, 255, 0.25)',
  primaryColor50: 'rgba(0, 183, 255, 0.5)',
  primaryColor75: 'rgba(0, 183, 255, 0.75)',
  primaryColorDark: 'rgb(0,141,197)',

  pagina: {
    background: 'rgb(242, 244, 247)',
  },
  elementos: {
    background: 'white',
    color: 'rgb(104, 102, 102)',
  },
  filtros: {
    background: 'rgb(255, 255, 255)',
    border: 'solid 1px rgb(1, 166, 216)',
    color: 'rgb(0, 144, 188)',
    outlineColor: 'rgb(1, 166, 216)'
  },
  itens: {
    background: 'rgb(207, 205, 205)',
    border: 'solid 1 px rgb(201, 199, 199)',
    color: 'rgb(110, 107, 107)',
  },
  checked: {
    background: 'rgb(227, 249, 225)',
    color: 'rgb(2, 138, 108)',
  },
  input: {
    border: 'solid 1px rgb(146, 142, 142)',
  },
  itensBoletos: {
    p1: 'rgb(247, 2, 2)',
    p2: 'rgb(110, 107, 107)',
    p3: 'rgb(3, 182, 143)',
    p4: 'rgb(6, 206, 112)',
  },
  menu: {
    link: 'rgb(158, 152, 152)',
  },
};

const extendedTheme = extendTheme({
  components: {
    Botao,
  },
  colors,
  initialColorMode: 'light',
  useSystemColorMode: false,
});

export { extendedTheme };
