import { Box } from '@chakra-ui/react';
import React from 'react';
import Chart from "react-apexcharts";
import { formataData } from '../../utils/formatador';

import { extendedTheme } from '../../utils/theme';

function GraficoFluxoDiario({data}) {
  const series =   [{
    name: 'Receitas',
    type: 'column',    
    data: data.map((item) => item.Receitas)
  }, {
    name: 'Despesas',
    type: 'column',
    data: data.map((item) => item.Despesas)
  }, {
    name: 'Saldo',
    type: 'line',
    data: data.map((item) => item.Saldo)
  }];
  const xaxis = data.map((item) => formataData(item.Dia))
  return (
    <Box minH={300} height="100%">
      <Chart
        options={{
          chart: {
            type: 'line',
            stacked: false
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            width: [1, 1, 3]
          },
          xaxis: {
            categories: xaxis,
          },
          yaxis: [
            {
              axisTicks: {
                show: true,
              },
              axisBorder: {
                show: true,
              },
              title: {
                text: "Reais (R$)",                
              },
              tooltip: {
                enabled: true
              }
            }
          ],
          tooltip: {
            fixed: {
              enabled: true,
              position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
              offsetY: 30,
              offsetX: 60
            },
          },
          legend: {
            horizontalAlign: 'left',
            offsetX: 40
          },
          colors: [
            extendedTheme.colors.successColor,
            extendedTheme.colors.errorColor,
            extendedTheme.colors.primaryColor,
          ]
        }
        }
      series={series}
      type="line"
      height="100%"
    />
    </Box>
  );
}

export default GraficoFluxoDiario;
