import { ChakraProvider } from '@chakra-ui/react';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Agenda from './pages/Agenda/Agenda';
import EditarBancos from './pages/Bancos/EditarBancos';
import ListarBancos from './pages/Bancos/ListarBancos';
import EditarBoletos from './pages/Boletos/EditarBoletos';
import ListarBoletos from './pages/Boletos/ListarBoletos';
import ListarCartasCorrecao from './pages/CartasCorrecao/ListarCartasCorrecao';
import EditarCategorias from './pages/Categorias/EditarCategorias';
import ListarCategorias from './pages/Categorias/ListarCategorias';
import EditarCategoriasVendas from './pages/CategoriasVendas/EditarCategoriasVendas';
import ListarCategoriasVendas from './pages/CategoriasVendas/ListarCategoriasVendas';
import EditarCentrosCustos from './pages/CentrosCusto/EditarCentrosCustos';
import ListarCentrosCustos from './pages/CentrosCusto/ListarCentrosCustos';
import EditarCheques from './pages/Cheques/EditarCheques';
import ListarCheques from './pages/Cheques/ListarCheques';
import EditarClientes from './pages/Clientes/EditarClientes';
import ListarClientes from './pages/Clientes/ListarClientes';
import EditarCondicoesPagamento from './pages/CondicoesPagamento/EditarCondicoesPagamento';
import ListarCondicoesPagamento from './pages/CondicoesPagamento/ListarCondicoesPagamento';
import ConfigPDV from './pages/ConfigPDV/ConfigPDV';
import ConsultaNotasCNPJ from './pages/ConsultaNotasCNPJ/ConsultaNotasCNPJ';
import EditarContratos from './pages/Contratos/EditarContratos';
import ListarContratos from './pages/Contratos/ListarContratos';
import Dashboard from './pages/Dashboard/Dashboard';
import EditarEmpresas from './pages/Empresas/EditarEmpresas';
import ListarEmpresas from './pages/Empresas/ListarEmpresas';
import EditarEquipamentos from './pages/Equipamentos/EditarEquipamentos';
import ListarEquipamentos from './pages/Equipamentos/ListarEquipamentos';
import EditarEstoques from './pages/Estoques/EditarEstoques';
import ListarEstoques from './pages/Estoques/ListarEstoques';
import FrenteDeCaixa from './pages/FrenteDeCaixa/FrenteDeCaixa';
import EditarGradeProdutos from './pages/Grade/EditarGradeProdutos';
import ListarGradeProdutos from './pages/Grade/ListarGradeProdutos';
import EditarLancamentosFinanceiros from './pages/LancamentosFinanceiros/EditarLancamentosFinanceiros';
import ListarLancamentosFinanceiros from './pages/LancamentosFinanceiros/ListarLancamentosFinanceiros';
import Login from './pages/Login/Login';
import EditarMarcas from './pages/Marcas/EditarMarcas';
import ListarMarcas from './pages/Marcas/ListarMarcas';
import EditarMovimentacoesEstoque from './pages/MovimentacoesEstoque/EditarMovimentacoesEstoque';
import ListarMovimentacoesEstoque from './pages/MovimentacoesEstoque/ListarMovimentacoesEstoque';
import EditarNFCe from './pages/NFCe/EditarNFCe';
import ListarNFCe from './pages/NFCe/ListarNFCe';
import EditarNFe from './pages/NFe/EditarNFe';
import ListarNFe from './pages/NFe/ListarNFe';
import NotFound from './pages/NotFound/NotFound';
import EditarNotasCompra from './pages/NotasCompra/EditarNotasCompra';
import ListarNotasCompra from './pages/NotasCompra/ListarNotasCompra';
import EditarOperacoesFiscais from './pages/OperacoesFiscais/EditarOperacoesFiscais';
import ListarOperacoesFiscais from './pages/OperacoesFiscais/ListarOperacoesFiscais';
import EditarOrdensCompra from './pages/OrdensCompra/EditarOrdensCompra';
import ListarOrdensCompra from './pages/OrdensCompra/ListarOrdensCompra';
import EditarOP from './pages/OrdensProducao/EditarOP';
import ListarOP from './pages/OrdensProducao/ListarOP';
import EditarOrdensServico from './pages/OrdensServico/EditarOrdensServico';
import ListarOrdensServico from './pages/OrdensServico/ListarOrdensServico';
import PDVBalanca from './pages/PDVBalanca/PDVBalanca';
import CadastrarPedidos from './pages/Pedidos/EditarPedidos';
import ListarPedidos from './pages/Pedidos/ListarPedidos';
import EditarPlanoContas from './pages/PlanoContas/EditarPlanoContas';
import ListarPlanoContas from './pages/PlanoContas/ListarPlanoContas';
import EditarProdutos from './pages/Produtos/EditarProdutos';
import ListarProdutos from './pages/Produtos/ListarProdutos';
import EditarServicos from './pages/Servicos/EditarServicos';
import ListarServicos from './pages/Servicos/ListarServicos';
import EditarSituacaoTributaria from './pages/SituacaoTributaria/EditarSituacaoTributaria';
import ListarSituacaoTributaria from './pages/SituacaoTributaria/ListarSituacaoTributaria';
import EditarTabelaPreco from './pages/TabelasPreco/EditarTabelasPreco';
import ListarTabelaPreco from './pages/TabelasPreco/ListarTabelasPreco';
import EditarTransferenciasBancarias from './pages/TransferenciasBancarias/EditarTransferenciasBancarias';
import ListarTransferenciasBancarias from './pages/TransferenciasBancarias/ListarTransferenciasBancarias';
import EditarUsuarios from './pages/Usuarios/EditarUsuarios';
import ListarUsuarios from './pages/Usuarios/ListarUsuarios';
import EditarVeiculos from './pages/Veiculos/EditarVeiculos';
import ListarVeiculos from './pages/Veiculos/ListarVeiculos';
import RelCentroCustos from './reports/RelCentroCustos/RelCentroCustos';
import RelCheques from './reports/RelCheques/RelCheques';
import RelClientes from './reports/RelClientes/RelClientes';
import RelComissoes from './reports/RelComissoes/RelComissoes';
import RelEntregaPedidos from './reports/RelEntregaPedido/RelEntregaPedidos';
import RelEstoque from './reports/RelEstoque/RelEstoque';
import RelFaturamento from './reports/RelFaturamento/RelFaturamento';
import RelFaturamentoVendas from './reports/RelFaturamentoVendas/RelFaturamentoVendas';
import RelFechamentoPDV from './reports/RelFechamentoPDV/RelFechamentoPDV';
import RelFluxoBancario from './reports/RelFluxoBancario/RelFluxoBancario';
import RelFluxoCaixa from './reports/RelFluxoCaixa/RelFluxoCaixa';
import RelInadimplentes from './reports/RelInadimplentes/RelInadimplentes';
import RelInventario from './reports/RelInventario/RelInventario';
import RelLancamentos from './reports/RelLancamentos/RelLancamentos';
import RelLancamentosNaoPagos from './reports/RelLancamentosNaoPagos/RelLancamentosNaoPagos';
import RelLancamentosVencidos from './reports/RelLancamentosVencidos/RelLancamentosVencidos';
import RelLucroPedidos from './reports/RelLucroPedidos/RelLucroPedidos';
import RelNotasCompra from './reports/RelNotasCompra/RelNotasCompra';
import RelOrdensProducao from './reports/RelOrdensProducao/RelOrdensProducao';
import RelOrdensServico from './reports/RelOrdensServico/RelOrdensServico';
import RelPedidosPDV from './reports/RelPedidosPDV/RelPedidosPDV';
import RelPrevisaoCaixa from './reports/RelPrevisaoCaixa/RelPrevisaoCaixa';
import RelSinteseFinanceira from './reports/RelSinteseFinanceira/RelSinteseFinanceira';
import RelValidadePedidos from './reports/RelValidadePedidos/RelValidadePedidos';
import RelValorEstoque from './reports/RelValorEstoque/RelValorEstoque';
import ProtectedRoute from './services/protected_route';
import { extendedTheme } from './utils/theme';
import ListarArquivosRH from './pages/ArquivosRH/ListarArquivosRH';
import EditarArquivosRH from './pages/ArquivosRH/EditarArquivosRH';
import RelOrcamentos from './reports/RelOrcamentos/RelOrcamentos';

function App() {
  return (
    <div className="paginaPrincipal">
      <ChakraProvider theme={extendedTheme}>
        <BrowserRouter forceRefresh={false}>
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route
              path="/agenda"
              element={
                <ProtectedRoute>
                  <Agenda />
                </ProtectedRoute>
              }
            />
            <Route
              path="/ArquivosRH/Editar/:id"
              element={
                <ProtectedRoute>
                  <EditarArquivosRH />
                </ProtectedRoute>
              }
            />
            <Route
              path="/ArquivosRH/Listar"
              element={
                <ProtectedRoute>
                  <ListarArquivosRH />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Bancos/Editar"
              element={
                <ProtectedRoute>
                  <EditarBancos />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Bancos/Editar/:id"
              element={
                <ProtectedRoute>
                  <EditarBancos />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Bancos/Listar"
              element={
                <ProtectedRoute>
                  <ListarBancos />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Boletos/Editar"
              element={
                <ProtectedRoute>
                  <EditarBoletos />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Boletos/Editar/:id"
              element={
                <ProtectedRoute>
                  <EditarBoletos />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Boletos/Listar"
              element={
                <ProtectedRoute>
                  <ListarBoletos />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Categorias/Editar"
              element={
                <ProtectedRoute>
                  <EditarCategorias />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Categorias/Editar/:id"
              element={
                <ProtectedRoute>
                  <EditarCategorias />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Categorias/Listar"
              element={
                <ProtectedRoute>
                  <ListarCategorias />
                </ProtectedRoute>
              }
            />
            <Route
              path="/CategoriasVendas/Editar"
              element={
                <ProtectedRoute>
                  <EditarCategoriasVendas />
                </ProtectedRoute>
              }
            />
            <Route
              path="/CategoriasVendas/Editar/:id"
              element={
                <ProtectedRoute>
                  <EditarCategoriasVendas />
                </ProtectedRoute>
              }
            />
            <Route
              path="/CategoriasVendas/Listar"
              element={
                <ProtectedRoute>
                  <ListarCategoriasVendas />
                </ProtectedRoute>
              }
            />
            <Route
              path="/CartasCorrecao/Listar"
              element={
                <ProtectedRoute>
                  <ListarCartasCorrecao />
                </ProtectedRoute>
              }
            />
            <Route
              path="/CentrosCustos/Editar"
              element={
                <ProtectedRoute>
                  <EditarCentrosCustos />
                </ProtectedRoute>
              }
            />
            <Route
              path="/CentrosCustos/Editar/:id"
              element={
                <ProtectedRoute>
                  <EditarCentrosCustos />
                </ProtectedRoute>
              }
            />
            <Route
              path="/CentrosCustos/Listar"
              element={
                <ProtectedRoute>
                  <ListarCentrosCustos />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Cheques/Editar"
              element={
                <ProtectedRoute>
                  <EditarCheques />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Cheques/Editar/:id"
              element={
                <ProtectedRoute>
                  <EditarCheques />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Cheques/Listar"
              element={
                <ProtectedRoute>
                  <ListarCheques />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Clientes/Editar"
              element={
                <ProtectedRoute>
                  <EditarClientes categoria={'Clientes'} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Clientes/Editar/:id"
              element={
                <ProtectedRoute>
                  <EditarClientes categoria={'Clientes'} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Clientes/Listar"
              element={
                <ProtectedRoute>
                  <ListarClientes categoria={'Clientes'} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/CondicoesPagamento/Editar"
              element={
                <ProtectedRoute>
                  <EditarCondicoesPagamento />
                </ProtectedRoute>
              }
            />
            <Route
              path="/CondicoesPagamento/Editar/:id"
              element={
                <ProtectedRoute>
                  <EditarCondicoesPagamento />
                </ProtectedRoute>
              }
            />
            <Route
              path="/CondicoesPagamento/Listar"
              element={
                <ProtectedRoute>
                  <ListarCondicoesPagamento />
                </ProtectedRoute>
              }
            />
            <Route
              path="/ConfiguracoesBlanca/Editar"
              element={
                <ProtectedRoute>
                  <PDVBalanca />
                </ProtectedRoute>
              }
            />
            <Route
              path="/consulta-notas-cnpj"
              element={
                <ProtectedRoute>
                  <ConsultaNotasCNPJ />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Contratos/Editar"
              element={
                <ProtectedRoute>
                  <EditarContratos />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Contratos/Editar/:id"
              element={
                <ProtectedRoute>
                  <EditarContratos />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Contratos/Listar"
              element={
                <ProtectedRoute>
                  <ListarContratos />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Empresas/Editar"
              element={
                <ProtectedRoute>
                  <EditarEmpresas />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Empresas/Editar/:id"
              element={
                <ProtectedRoute>
                  <EditarEmpresas />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Empresas/Listar"
              element={
                <ProtectedRoute>
                  <ListarEmpresas />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Equipamentos/Editar"
              element={
                <ProtectedRoute>
                  <EditarEquipamentos />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Equipamentos/Editar/:id"
              element={
                <ProtectedRoute>
                  <EditarEquipamentos />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Equipamentos/Listar"
              element={
                <ProtectedRoute>
                  <ListarEquipamentos />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Estoques/Editar"
              element={
                <ProtectedRoute>
                  <EditarEstoques />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Estoques/Editar/:id"
              element={
                <ProtectedRoute>
                  <EditarEstoques />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Estoques/Listar"
              element={
                <ProtectedRoute>
                  <ListarEstoques />
                </ProtectedRoute>
              }
            />
            <Route
              path="/frente-de-caixa"
              element={
                <ProtectedRoute>
                  <FrenteDeCaixa />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Funcionarios/Editar"
              element={
                <ProtectedRoute>
                  <EditarClientes categoria={'Funcionarios'} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Funcionarios/Editar/:id"
              element={
                <ProtectedRoute>
                  <EditarClientes categoria={'Funcionarios'} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Funcionarios/Listar"
              element={
                <ProtectedRoute>
                  <ListarClientes categoria={'Funcionarios'} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/GradeProdutos/Editar"
              element={
                <ProtectedRoute>
                  <EditarGradeProdutos />
                </ProtectedRoute>
              }
            />
            <Route
              path="/GradeProdutos/Editar/:id"
              element={
                <ProtectedRoute>
                  <EditarGradeProdutos />
                </ProtectedRoute>
              }
            />
            <Route
              path="/GradeProdutos/Listar"
              element={
                <ProtectedRoute>
                  <ListarGradeProdutos />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Lancamentos/Editar"
              element={
                <ProtectedRoute>
                  <EditarLancamentosFinanceiros />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Lancamentos/Editar/:id"
              element={
                <ProtectedRoute>
                  <EditarLancamentosFinanceiros />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Lancamentos/Listar"
              element={
                <ProtectedRoute>
                  <ListarLancamentosFinanceiros />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Marcas/Editar"
              element={
                <ProtectedRoute>
                  <EditarMarcas />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Marcas/Editar/:id"
              element={
                <ProtectedRoute>
                  <EditarMarcas />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Marcas/Listar"
              element={
                <ProtectedRoute>
                  <ListarMarcas />
                </ProtectedRoute>
              }
            />
            <Route
              path="/MovimentacoesEstoque/Editar"
              element={
                <ProtectedRoute>
                  <EditarMovimentacoesEstoque />
                </ProtectedRoute>
              }
            />
            <Route
              path="/MovimentacoesEstoque/Listar"
              element={
                <ProtectedRoute>
                  <ListarMovimentacoesEstoque />
                </ProtectedRoute>
              }
            />
            <Route
              path="/NFe/Editar"
              element={
                <ProtectedRoute>
                  <EditarNFe />
                </ProtectedRoute>
              }
            />
            <Route
              path="/NFe/Editar/:id"
              element={
                <ProtectedRoute>
                  <EditarNFe />
                </ProtectedRoute>
              }
            />
            <Route
              path="/NFe/Listar"
              element={
                <ProtectedRoute>
                  <ListarNFe />
                </ProtectedRoute>
              }
            />
            <Route
              path="/NFCe/Editar"
              element={
                <ProtectedRoute>
                  <EditarNFCe />
                </ProtectedRoute>
              }
            />
            <Route
              path="/NFCe/Editar/:id"
              element={
                <ProtectedRoute>
                  <EditarNFCe />
                </ProtectedRoute>
              }
            />
            <Route
              path="/NFCe/Listar"
              element={
                <ProtectedRoute>
                  <ListarNFCe />
                </ProtectedRoute>
              }
            />
            <Route
              path="/OperacoesFiscais/Editar"
              element={
                <ProtectedRoute>
                  <EditarOperacoesFiscais />
                </ProtectedRoute>
              }
            />
            <Route
              path="/OperacoesFiscais/Editar/:id"
              element={
                <ProtectedRoute>
                  <EditarOperacoesFiscais />
                </ProtectedRoute>
              }
            />
            <Route
              path="/OperacoesFiscais/Listar"
              element={
                <ProtectedRoute>
                  <ListarOperacoesFiscais />
                </ProtectedRoute>
              }
            />
            <Route
              path="/OrdensProducao/Editar"
              element={
                <ProtectedRoute>
                  <EditarOP />
                </ProtectedRoute>
              }
            />
            <Route
              path="/OrdensProducao/Editar/:id"
              element={
                <ProtectedRoute>
                  <EditarOP />
                </ProtectedRoute>
              }
            />
            <Route
              path="/OrdensProducao/Listar"
              element={
                <ProtectedRoute>
                  <ListarOP />
                </ProtectedRoute>
              }
            />
            <Route
              path="/OrdensCompra/Editar"
              element={
                <ProtectedRoute>
                  <EditarOrdensCompra />
                </ProtectedRoute>
              }
            />
            <Route
              path="/OrdensCompra/Editar/:id"
              element={
                <ProtectedRoute>
                  <EditarOrdensCompra />
                </ProtectedRoute>
              }
            />
            <Route
              path="/OrdensCompra/Listar"
              element={
                <ProtectedRoute>
                  <ListarOrdensCompra />
                </ProtectedRoute>
              }
            />
            <Route
              path="/OrdensServico/Editar"
              element={
                <ProtectedRoute>
                  <EditarOrdensServico />
                </ProtectedRoute>
              }
            />
            <Route
              path="/OrdensServico/Editar/:id"
              element={
                <ProtectedRoute>
                  <EditarOrdensServico />
                </ProtectedRoute>
              }
            />
            <Route
              path="/OrdensServico/Listar"
              element={
                <ProtectedRoute>
                  <ListarOrdensServico />
                </ProtectedRoute>
              }
            />
            <Route
              path="/PDV/Config"
              element={
                <ProtectedRoute>
                  <ConfigPDV />
                </ProtectedRoute>
              }
            />
            <Route
              path="/ConfiguracoesBalanca/Editar"
              element={
                <ProtectedRoute>
                  <PDVBalanca />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Pedidos/Editar"
              element={
                <ProtectedRoute>
                  <CadastrarPedidos />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Pedidos/Editar/:id"
              element={
                <ProtectedRoute>
                  <CadastrarPedidos />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Pedidos/Listar"
              element={
                <ProtectedRoute>
                  <ListarPedidos />
                </ProtectedRoute>
              }
            />
            <Route
              path="/PlanoContas/Editar"
              element={
                <ProtectedRoute>
                  <EditarPlanoContas />
                </ProtectedRoute>
              }
            />
            <Route
              path="/PlanoContas/Editar/:id"
              element={
                <ProtectedRoute>
                  <EditarPlanoContas />
                </ProtectedRoute>
              }
            />
            <Route
              path="/PlanoContas/Listar"
              element={
                <ProtectedRoute>
                  <ListarPlanoContas />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Produtos/Editar"
              element={
                <ProtectedRoute>
                  <EditarProdutos />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Produtos/Editar/:id"
              element={
                <ProtectedRoute>
                  <EditarProdutos />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Produtos/Listar"
              element={
                <ProtectedRoute>
                  <ListarProdutos />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Servicos/Editar"
              element={
                <ProtectedRoute>
                  <EditarServicos />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Servicos/Editar/:id"
              element={
                <ProtectedRoute>
                  <EditarServicos />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Servicos/Listar"
              element={
                <ProtectedRoute>
                  <ListarServicos />
                </ProtectedRoute>
              }
            />
            <Route
              path="/SituacaoTributaria/Editar"
              element={
                <ProtectedRoute>
                  <EditarSituacaoTributaria />
                </ProtectedRoute>
              }
            />
            <Route
              path="/SituacaoTributaria/Editar/:id"
              element={
                <ProtectedRoute>
                  <EditarSituacaoTributaria />
                </ProtectedRoute>
              }
            />
            <Route
              path="/SituacaoTributaria/Listar"
              element={
                <ProtectedRoute>
                  <ListarSituacaoTributaria />
                </ProtectedRoute>
              }
            />
            <Route
              path="/TabelasPreco/Editar"
              element={
                <ProtectedRoute>
                  <EditarTabelaPreco />
                </ProtectedRoute>
              }
            />
            <Route
              path="/TabelasPreco/Editar/:id"
              element={
                <ProtectedRoute>
                  <EditarTabelaPreco />
                </ProtectedRoute>
              }
            />
            <Route
              path="/TabelasPreco/Listar"
              element={
                <ProtectedRoute>
                  <ListarTabelaPreco />
                </ProtectedRoute>
              }
            />
            <Route
              path="/TransferenciasBancarias/Editar"
              element={
                <ProtectedRoute>
                  <EditarTransferenciasBancarias />
                </ProtectedRoute>
              }
            />
            <Route
              path="/TransferenciasBancarias/Editar/:id"
              element={
                <ProtectedRoute>
                  <EditarTransferenciasBancarias />
                </ProtectedRoute>
              }
            />
            <Route
              path="/TransferenciasBancarias/Listar"
              element={
                <ProtectedRoute>
                  <ListarTransferenciasBancarias />
                </ProtectedRoute>
              }
            />

            <Route
              path="/Transportadoras/Editar"
              element={
                <ProtectedRoute>
                  <EditarClientes categoria={'Transportadoras'} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Transportadoras/Editar/:id"
              element={
                <ProtectedRoute>
                  <EditarClientes categoria={'Transportadoras'} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Transportadoras/Listar"
              element={
                <ProtectedRoute>
                  <ListarClientes categoria={'Transportadoras'} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Usuarios/Editar"
              element={
                <ProtectedRoute>
                  <EditarUsuarios />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Usuarios/Editar/:id"
              element={
                <ProtectedRoute>
                  <EditarUsuarios />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Usuarios/Listar"
              element={
                <ProtectedRoute>
                  <ListarUsuarios />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Veiculos/Editar"
              element={
                <ProtectedRoute>
                  <EditarVeiculos />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Veiculos/Editar/:id"
              element={
                <ProtectedRoute>
                  <EditarVeiculos />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Veiculos/Listar"
              element={
                <ProtectedRoute>
                  <ListarVeiculos />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Vendedores/Editar"
              element={
                <ProtectedRoute>
                  <EditarClientes categoria={'Vendedores'} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Vendedores/Editar/:id"
              element={
                <ProtectedRoute>
                  <EditarClientes categoria={'Vendedores'} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Vendedores/Listar"
              element={
                <ProtectedRoute>
                  <ListarClientes categoria={'Vendedores'} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Fornecedores/Editar"
              element={
                <ProtectedRoute>
                  <EditarClientes categoria={'Fornecedores'} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Fornecedores/Editar/:id"
              element={
                <ProtectedRoute>
                  <EditarClientes categoria={'Fornecedores'} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Fornecedores/Listar"
              element={
                <ProtectedRoute>
                  <ListarClientes categoria={'Fornecedores'} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/NotasCompra/Editar"
              element={
                <ProtectedRoute>
                  <EditarNotasCompra />
                </ProtectedRoute>
              }
            />
            <Route
              path="/NotasCompra/Editar/:id"
              element={
                <ProtectedRoute>
                  <EditarNotasCompra />
                </ProtectedRoute>
              }
            />
            <Route
              path="/NotasCompra/Listar"
              element={
                <ProtectedRoute>
                  <ListarNotasCompra />
                </ProtectedRoute>
              }
            />
            <Route
              path="/CentroCustos/Reportar"
              element={
                <ProtectedRoute>
                  <RelCentroCustos />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Cheques/Reportar"
              element={
                <ProtectedRoute>
                  <RelCheques />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Clientes/Reportar"
              element={
                <ProtectedRoute>
                  <RelClientes />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Comissoes/Reportar"
              element={
                <ProtectedRoute>
                  <RelComissoes />
                </ProtectedRoute>
              }
            />
            <Route
              path="/EntregaPedidos/Reportar"
              element={
                <ProtectedRoute>
                  <RelEntregaPedidos />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Estoque/Reportar"
              element={
                <ProtectedRoute>
                  <RelEstoque />
                </ProtectedRoute>
              }
            />
            <Route
              path="/FaturamentoVendas/Reportar"
              element={
                <ProtectedRoute>
                  <RelFaturamentoVendas />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Faturamento/Reportar"
              element={
                <ProtectedRoute>
                  <RelFaturamento />
                </ProtectedRoute>
              }
            />
            <Route
              path="/FechamentoPDV/Reportar"
              element={
                <ProtectedRoute>
                  <RelFechamentoPDV />
                </ProtectedRoute>
              }
            />
            <Route
              path="/FluxoBancario/Reportar"
              element={
                <ProtectedRoute>
                  <RelFluxoBancario />
                </ProtectedRoute>
              }
            />
            <Route
              path="/FluxoCaixa/Reportar"
              element={
                <ProtectedRoute>
                  <RelFluxoCaixa />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Inadimplentes/Reportar"
              element={
                <ProtectedRoute>
                  <RelInadimplentes />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Inventario/Reportar"
              element={
                <ProtectedRoute>
                  <RelInventario />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Lancamentos/Reportar"
              element={
                <ProtectedRoute>
                  <RelLancamentos />
                </ProtectedRoute>
              }
            />
            <Route
              path="/LancamentosNaoPagos/Reportar"
              element={
                <ProtectedRoute>
                  <RelLancamentosNaoPagos />
                </ProtectedRoute>
              }
            />
            <Route
              path="/LancamentosVencidos/Reportar"
              element={
                <ProtectedRoute>
                  <RelLancamentosVencidos />
                </ProtectedRoute>
              }
            />
            <Route
              path="/LucroPedidos/Reportar"
              element={
                <ProtectedRoute>
                  <RelLucroPedidos />
                </ProtectedRoute>
              }
            />
            <Route
              path="/NotasCompra/Reportar"
              element={
                <ProtectedRoute>
                  <RelNotasCompra />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Orcamentos/Reportar"
              element={
                <ProtectedRoute>
                  <RelOrcamentos />
                </ProtectedRoute>
              }
            />
            <Route
              path="/OrdensProducao/Reportar"
              element={
                <ProtectedRoute>
                  <RelOrdensProducao />
                </ProtectedRoute>
              }
            />
            <Route
              path="/OrdensServico/Reportar"
              element={
                <ProtectedRoute>
                  <RelOrdensServico />
                </ProtectedRoute>
              }
            />
            <Route
              path="/PedidosPDV/Reportar"
              element={
                <ProtectedRoute>
                  <RelPedidosPDV />
                </ProtectedRoute>
              }
            />
            <Route
              path="/PrevisaoCaixa/Reportar"
              element={
                <ProtectedRoute>
                  <RelPrevisaoCaixa />
                </ProtectedRoute>
              }
            />
            <Route
              path="/SinteseFinanceira/Reportar"
              element={
                <ProtectedRoute>
                  <RelSinteseFinanceira />
                </ProtectedRoute>
              }
            />
            <Route
              path="/ValidadePedidos/Reportar"
              element={
                <ProtectedRoute>
                  <RelValidadePedidos />
                </ProtectedRoute>
              }
            />
            <Route
              path="/ValorEstoque/Reportar"
              element={
                <ProtectedRoute>
                  <RelValorEstoque />
                </ProtectedRoute>
              }
            />
            <Route
              path="/*"
              element={
                <ProtectedRoute>
                  <NotFound />
                </ProtectedRoute>
              }
            />
          </Routes>
        </BrowserRouter>
      </ChakraProvider>
    </div>
  );
}

export default App;
