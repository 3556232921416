import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import '../../App.css';
import InputData from '../../components/Inputs/InputData/InputData';
import InputNumInt from '../../components/Inputs/InputNumInt/InputNumInt';
import InputSelect from '../../components/Inputs/InputSelect/InputSelect';
import InputFloat from '../../components/Inputs/InputFloat/InputFloat';
import Pagina from '../../components/Pagina/Pagina';
import api from '../../services/api';
import { getFiltroPadraoRelatorioCheques } from '../../utils/filtros';
import { gridColumn } from '../../utils/gridColumn';
import { ajustaDatas, optionsDatas } from '../../utils/utils';
import FormikPadrao from '../../components/FormikPadrao/FormikPadrao';
import BarraBotoes from '../../components/BarraBotoes/BarraBotoes';
import InputFiltro from '../../components/Inputs/InputFiltro/InputFiltro';
import Tabela from '../../components/Tabela/Tabela';
import InputAutoComplete from '../../components/Inputs/InputAutoComplete/InputAutoComplete';
import Filtros from '../../components/Filtros/Filtros';
import { baixarArquivoBase64 } from '../../utils/download';
import TotalizadorRelatorio from '../../components/TotalizadorRelatorio/TotalizadorRelatorio';

function RelCheques() {
  const [relCheques, setRelCheques] = useState(null);
  const [loading, setLoading] = useState(true);
  const [search] = useSearchParams();
  const [searchBar, setSearchBar] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [totalCheque, setTotalCheque] = useState(0);
  const navigate = useNavigate();

  async function GetRelCheques(filtros) {
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(filtros, null, 2),
    };
    let res = await api.post('/ChequesReport/GetList', payload);
    let data = res.data;

    if (res.status === 200) {
      setRelCheques(data.Cheques);
      if (data.Cheques && Object.keys(data.Cheques).length > 0) {
        const chequesList = data.Cheques;
        let soma = 0;
        chequesList.map(cheque => (soma += cheque.Valor));
        setTotalCheque(soma);
      } else {
        setTotalCheque(0);
      }
      setLoading(false);
    } else {
      alert('Erro ao buscar dados');
    }
  }

  useEffect(() => {
    GetRelCheques(getFiltroPadraoRelatorioCheques());
  }, []);

  useEffect(() => {
    if (search.get('filter')) {
      setShowFilters(search.get('filter') === 'true');
      GetRelCheques(getFiltroPadraoRelatorioCheques());
    }
  }, [search]);

  function handleEdit(item) {
    navigate(`/Cheques/Editar/${item.Id}`);
  }

  const getPDF = async () => {
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(relCheques, null, 2),
    };
    baixarArquivoBase64('/ChequesReport/GetPDF', payload);
  };

  const getExcel = async () => {
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(relCheques, null, 2),
    };
    baixarArquivoBase64('/ChequesReport/GetExcel', payload);
  };

  return (
    <Pagina loading={loading}>
      <FormikPadrao
        initialValues={getFiltroPadraoRelatorioCheques()}
        onSubmit={async values => {
          await GetRelCheques(values);
        }}
      >
        <BarraBotoes
          maxWindowSize={1030}
          botoes={[
            {
              children: 'Exportar Planilha',
              onClick: getExcel,
              variant: 'verde',
            },
            {
              children: 'Exportar PDF',
              onClick: getPDF,
              variant: 'rosa',
            },
          ]}
          search={{
            placeholder: 'Procurar por cheques',
            onChange: e => setSearchBar(e.target.value),
          }}
          showFilters={showFilters}
          setShowFilters={setShowFilters}
        />
        <FilterMenu showFilters={showFilters} setShowFilters={setShowFilters} />
        <TotalizadorRelatorio
          items={[
            {
              Titulo: 'VALOR TOTAL DOS CHEQUES',
              Valor: totalCheque,
              type: 'money',
            },
          ]}
        />
        <Tabela
          titulo={'RELATÓRIO DE CHEQUES'}
          setFunction={setRelCheques}
          data={relCheques}
          columns={[
            {
              placeholder: 'Nº CHEQUE',
              name: 'Numero',
              type: 'text',
            },
            {
              placeholder: 'CLIENTE',
              name: 'Favorecido',
              type: 'text',
            },
            {
              placeholder: 'STATUS',
              name: 'Situacao',
              type: 'text',
            },
            {
              placeholder: 'VALOR',
              name: 'Valor',
              type: 'money',
            },
            {
              placeholder: 'EMISSÃO',
              name: 'DataEmissao',
              type: 'date',
            },
            {
              placeholder: 'COMPENSAÇÃO',
              name: 'DataCompensacao',
              type: 'date',
            },
            {
              placeholder: 'BAIXA',
              name: 'DataBaixado',
              type: 'date',
            },
          ]}
          searchBar={searchBar}
          handleEdit={handleEdit}
        />
      </FormikPadrao>
    </Pagina>
  );
}

function FilterMenu({ showFilters, setShowFilters }) {
  const { values, setFieldValue } = useFormikContext();

  const optionsData = [
    { value: 'emissao', name: 'Filtrar Por Data de Emissão' },
    { value: 'compensacao', name: 'Filtrar Por Data de Compensação' },
    { value: 'baixa', name: 'Filtrar Por Data de Baixa' },
  ];

  const optionsStatus = [
    { value: '', name: 'Status do Cheque' },
    { value: 'Em caixa', name: 'Em caixa' },
    { value: 'Depositado', name: 'Depositado' },
    { value: 'Devolvido', name: 'Devolvido' },
    { value: 'Guardado', name: 'Guardado' },
  ];

  useEffect(() => {
    ajustaDatas(values.PeriodoLancamento, setFieldValue);
  }, [values.PeriodoLancamento, setFieldValue]);

  return (
    <Filtros showFilters={showFilters} setShowFilters={setShowFilters}>
      <InputFiltro
        titulo="CLIENTE/FORNECEDOR"
        id="Cliente"
        name="Cliente"
        component={InputAutoComplete}
        autocomplete={true}
        path={'ClienteFornecedor'}
        database={localStorage.getItem('database')}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="EMPRESA"
        id="Empresa"
        name="Empresa"
        component={InputAutoComplete}
        autocomplete={true}
        path={'Empresa'}
        database={localStorage.getItem('database')}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="NÚMERO DO CHEQUE"
        id="NumeroCheque"
        name="NumeroCheque"
        component={InputNumInt}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="STATUS DO CHEQUE"
        id="Situacao"
        name="Situacao"
        component={InputSelect}
        options={optionsStatus}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="VALOR DO CHEQUE"
        id="Valor"
        name="Valor"
        component={InputFloat}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="FILTRAR POR DATA"
        id="TipoData"
        name="TipoData"
        component={InputSelect}
        options={optionsData}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="PERÍODO"
        id="PeriodoLancamento"
        name="PeriodoLancamento"
        component={InputSelect}
        options={optionsDatas}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="Data Inicial"
        id="DataInicial"
        name="DataInicial"
        component={InputData}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="Data Final"
        id="DataFinal"
        name="DataFinal"
        component={InputData}
        gridColumn={gridColumn.gc4}
      />
    </Filtros>
  );
}
export default RelCheques;
