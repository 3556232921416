import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import '../../App.css';
import Alerta from '../../components/Alerta/Alerta';
import BarraBotoes from '../../components/BarraBotoes/BarraBotoes';
import Card from '../../components/Card/Card';
import FormikPadrao from '../../components/FormikPadrao/FormikPadrao';
import InputFormik from '../../components/Inputs/InputFormik/InputFormik';
import InputPassword from '../../components/Inputs/InputPassword/InputPassword';
import InputSelect from '../../components/Inputs/InputSelect/InputSelect';
import InputTexto from '../../components/Inputs/InputTexto/InputTexto';
import InputToggle from '../../components/Inputs/InputToggle/InputToggle';
import Pagina from '../../components/Pagina/Pagina';
import api from '../../services/api';
import { gridColumn } from '../../utils/gridColumn';
import { addIDtoURL } from '../../utils/utils';
import './Usuarios.css';
import { v4 as uuidv4 } from 'uuid';

function EditarUsuarios() {
  const navigate = useNavigate();
  const [usuario, setUsuario] = useState({
    TipoUsuario: '',
    Username: '',
    Email: '',
    Administrador: '',
    Password: '',
    RepetirPassword: '',
    Permissões: '{}',
  });
  const [loading, setLoading] = useState(true);

  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false });
  const [alertStatus, setAlertStatus] = useState('success');
  const [alertTitle, setAlertTitle] = useState('');

  let { id } = useParams();

  const GetUsuario = useCallback(async () => {
    let payload = {
      database: localStorage.getItem('database'),
      data: id,
    };
    let res = await api.post('/EditUsuarios/Get', payload);
    if (res.status === 200) {
      let data = res.data;
      setUsuario(data.Usuario);
      setLoading(false);
    } else {
      alert('Erro ao buscar dados');
    }
  }, [id])

  useEffect(() => {
    GetUsuario();
  }, [GetUsuario]);

  let save = async value => {

    console.log(value)
    setLoading(true);

    let payload = {
      data: value,
      database: localStorage.getItem('database'),
    };
    let res = await api.post('/EditUsuarios/Save', payload);
    let data = res.data;
    setLoading(false);
    if (data.Success) {
      setAlertStatus('success');
      setAlertTitle(data.MSG);
      onOpen();
      addIDtoURL(id, data.ItemID);
      GetUsuario()
    } else {
      setAlertStatus('error');
      setAlertTitle(data.MSG);
      onOpen();
    }
  };
  return (
    <>
      <Alerta
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        alertStatus={alertStatus}
        title={alertTitle}
      />
      <Pagina loading={loading}>
        <FormikPadrao
          initialValues={usuario}
          enableReinitialize={true}
          validationSchema={yup.object({
            // TipoUsuario: yup.string().required('O campo é obrigatório.').nullable(),
            // Username: yup.string().required('O campo é obrigatório.').nullable(),
            // Email: yup.string().email('Digite um email válido').required('O campo é obrigatório.').nullable(),
            // Administrador: yup.boolean().required('O campo é obrigatório.').nullable(),
            // Password: yup.string().required('O campo é obrigatório.').nullable(),
            // RepetirPassword: yup.string().required('O campo é obrigatório.').oneOf([yup.ref('password')], 'As senhas devem ser iguais').nullable(),
            // Permissões: yup.object().required('O campo é obrigatório.').nullable(),
          })}
          onSubmit={async values => {
            await save(JSON.stringify(values, null, 2));
          }}
        >
          <Interno navigate={navigate} />
        </FormikPadrao>
      </Pagina>
    </>
  );
}

function Interno({ navigate }) {
  const { values, touched, setFieldValue } = useFormikContext();

  let titulo = `${values.Id !== '' ? 'Editar' : 'Novo'
    } Usuário`;

  const permissoes = [
    ['PDV', 'Permissoes.PDV', 'Permissoes.EditarPDV'],
    [
      'Lançamentos',
      'Permissoes.VisualizarLancamentos',
      'Permissoes.EditarLancamentos',
    ],
    [
      'Tabelas de Preço',
      'Permissoes.VisualizarTabelaPreco',
      'Permissoes.EditarTabelaPreco',
    ],
    [
      'Movimentações de Estoque',
      'Permissoes.VisualizarMovimentacaoEstoque',
      'Permissoes.EditarMovimentacaoEstoque',
    ],
    ['Pedidos', 'Permissoes.VisualizarPedidos', 'Permissoes.EditarPedidos'],
    ['Pessoas', 'Permissoes.VisualizarPessoas', 'Permissoes.EditarPessoas'],
    ['Produtos', 'Permissoes.VisualizarProdutos', 'Permissoes.EditarProdutos'],
    ['Fiscal', 'Permissoes.VisualizarFiscal', 'Permissoes.EditarFiscal'],
    ['Compras', 'Permissoes.VisualizarCompras', 'Permissoes.EditarCompras'],
    [
      'Loja Online',
      'Permissoes.VisualizarLojaOnline',
      'Permissoes.EditarLojaOnline',
    ],
    ['Serviços', 'Permissoes.VisualizarServicos', 'Permissoes.EditarServicos'],
    [
      'Ordens de Serviço',
      'Permissoes.VisualizarOrdensServico',
      'Permissoes.EditarOrdensServico',
    ],
    ['Usuários', 'Permissoes.VisualizarUsuarios', 'Permissoes.EditarUsuarios'],
    ['Dashboard', 'Permissoes.Dashboard', 'Permissoes.Dashboard'],
    ['Relatórios', 'Permissoes.Relatorios', 'Permissoes.Relatorios'],
    ['Configurações', 'Permissoes.Configuracoes', 'Permissoes.Configuracoes'],
  ];

  useEffect(() => {
    if (touched.IsAdmin) {
      if (values.Permissoes.Administrador) {
        setFieldValue('Permissoes.EditarPDV', true);
        setFieldValue('Permissoes.PDV', true);
        setFieldValue('Permissoes.EditarLancamentos', true);
        setFieldValue('Permissoes.VisualizarLancamentos', true);
        setFieldValue('Permissoes.EditarTabelaPreco', true);
        setFieldValue('Permissoes.VisualizarTabelaPreco', true);
        setFieldValue('Permissoes.EditarMovimentacaoEstoque', true);
        setFieldValue('Permissoes.VisualizarMovimentacaoEstoque', true);
        setFieldValue('Permissoes.EditarPedidos', true);
        setFieldValue('Permissoes.VisualizarPedidos', true);
        setFieldValue('Permissoes.EditarPessoas', true);
        setFieldValue('Permissoes.VisualizarPessoas', true);
        setFieldValue('Permissoes.EditarProdutos', true);
        setFieldValue('Permissoes.VisualizarProdutos', true);
        setFieldValue('Permissoes.EditarFiscal', true);
        setFieldValue('Permissoes.VisualizarFiscal', true);
        setFieldValue('Permissoes.EditarCompras', true);
        setFieldValue('Permissoes.VisualizarCompras', true);
        setFieldValue('Permissoes.EditarLojaOnline', true);
        setFieldValue('Permissoes.VisualizarLojaOnline', true);
        setFieldValue('Permissoes.EditarServicos', true);
        setFieldValue('Permissoes.VisualizarServicos', true);
        setFieldValue('Permissoes.EditarOrdensServico', true);
        setFieldValue('Permissoes.VisualizarOrdensServico', true);
        setFieldValue('Permissoes.EditarUsuarios', true);
        setFieldValue('Permissoes.VisualizarUsuarios', true);
        setFieldValue('Permissoes.Relatorios', true);
        setFieldValue('Permissoes.Configuracoes', true);
      }
    }
  }, [
    setFieldValue,
    values.IsAdmin,
    touched.IsAdmin,
    values.Permissoes.Administrador,
  ]);

  useEffect(() => {
    if (!values.Permissoes.PDV) {
      setFieldValue('Permissoes.EditarPDV', false);
      setFieldValue('IsAdmin', false);
    }
  }, [setFieldValue, values.Permissoes.PDV]);

  useEffect(() => {
    if (values.Permissoes.EditarPDV) {
      setFieldValue('Permissoes.PDV', true);
    } else {
      setFieldValue('IsAdmin', false);
    }
  }, [setFieldValue, values.Permissoes.EditarPDV]);

  useEffect(() => {
    if (!values.Permissoes.VisualizarLancamentos) {
      setFieldValue('Permissoes.EditarLancamentos', false);
      setFieldValue('IsAdmin', false);
    }
  }, [setFieldValue, values.Permissoes.VisualizarLancamentos]);

  useEffect(() => {
    if (values.Permissoes.EditarLancamentos) {
      setFieldValue('Permissoes.VisualizarLancamentos', true);
    } else {
      setFieldValue('IsAdmin', false);
    }
  }, [setFieldValue, values.Permissoes.EditarLancamentos]);

  useEffect(() => {
    if (!values.Permissoes.VisualizarTabelaPreco) {
      setFieldValue('Permissoes.EditarTabelaPreco', false);
      setFieldValue('IsAdmin', false);
    }
  }, [setFieldValue, values.Permissoes.VisualizarTabelaPreco]);

  useEffect(() => {
    if (values.Permissoes.EditarTabelaPreco) {
      setFieldValue('Permissoes.VisualizarTabelaPreco', true);
    } else {
      setFieldValue('IsAdmin', false);
    }
  }, [setFieldValue, values.Permissoes.EditarTabelaPreco]);

  useEffect(() => {
    if (!values.Permissoes.VisualizarMovimentacaoEstoque) {
      setFieldValue('Permissoes.EditarMovimentacaoEstoque', false);
      setFieldValue('IsAdmin', false);
    }
  }, [setFieldValue, values.Permissoes.VisualizarMovimentacaoEstoque]);

  useEffect(() => {
    if (values.Permissoes.EditarMovimentacaoEstoque) {
      setFieldValue('Permissoes.VisualizarMovimentacaoEstoque', true);
    } else {
      setFieldValue('IsAdmin', false);
    }
  }, [setFieldValue, values.Permissoes.EditarMovimentacaoEstoque]);

  useEffect(() => {
    if (!values.Permissoes.VisualizarPedidos) {
      setFieldValue('Permissoes.EditarPedidos', false);
      setFieldValue('IsAdmin', false);
    }
  }, [setFieldValue, values.Permissoes.VisualizarPedidos]);

  useEffect(() => {
    if (values.Permissoes.EditarPedidos) {
      setFieldValue('Permissoes.VisualizarPedidos', true);
    } else {
      setFieldValue('IsAdmin', false);
    }
  }, [setFieldValue, values.Permissoes.EditarPedidos]);

  useEffect(() => {
    if (!values.Permissoes.VisualizarPessoas) {
      setFieldValue('Permissoes.EditarPessoas', false);
      setFieldValue('IsAdmin', false);
    }
  }, [setFieldValue, values.Permissoes.VisualizarPessoas]);

  useEffect(() => {
    if (values.Permissoes.EditarPessoas) {
      setFieldValue('Permissoes.VisualizarPessoas', true);
    } else {
      setFieldValue('IsAdmin', false);
    }
  }, [setFieldValue, values.Permissoes.EditarPessoas]);

  useEffect(() => {
    if (!values.Permissoes.VisualizarProdutos) {
      setFieldValue('Permissoes.EditarProdutos', false);
      setFieldValue('IsAdmin', false);
    }
  }, [setFieldValue, values.Permissoes.VisualizarProdutos]);

  useEffect(() => {
    if (values.Permissoes.EditarProdutos) {
      setFieldValue('Permissoes.VisualizarProdutos', true);
    } else {
      setFieldValue('IsAdmin', false);
    }
  }, [setFieldValue, values.Permissoes.EditarProdutos]);

  useEffect(() => {
    if (!values.Permissoes.VisualizarFiscal) {
      setFieldValue('Permissoes.EditarFiscal', false);
      setFieldValue('IsAdmin', false);
    }
  }, [setFieldValue, values.Permissoes.VisualizarFiscal]);

  useEffect(() => {
    if (values.Permissoes.EditarFiscal) {
      setFieldValue('Permissoes.VisualizarFiscal', true);
    } else {
      setFieldValue('IsAdmin', false);
    }
  }, [setFieldValue, values.Permissoes.EditarFiscal]);

  useEffect(() => {
    if (!values.Permissoes.VisualizarCompras) {
      setFieldValue('Permissoes.EditarCompras', false);
      setFieldValue('IsAdmin', false);
    }
  }, [setFieldValue, values.Permissoes.VisualizarCompras]);

  useEffect(() => {
    if (values.Permissoes.EditarCompras) {
      setFieldValue('Permissoes.VisualizarCompras', true);
    } else {
      setFieldValue('IsAdmin', false);
    }
  }, [setFieldValue, values.Permissoes.EditarCompras]);

  useEffect(() => {
    if (!values.Permissoes.VisualizarLojaOnline) {
      setFieldValue('Permissoes.EditarLojaOnline', false);
      setFieldValue('IsAdmin', false);
    }
  }, [setFieldValue, values.Permissoes.VisualizarLojaOnline]);

  useEffect(() => {
    if (values.Permissoes.EditarLojaOnline) {
      setFieldValue('Permissoes.VisualizarLojaOnline', true);
    } else {
      setFieldValue('IsAdmin', false);
    }
  }, [setFieldValue, values.Permissoes.EditarLojaOnline]);

  useEffect(() => {
    if (!values.Permissoes.VisualizarServicos) {
      setFieldValue('Permissoes.EditarServicos', false);
      setFieldValue('IsAdmin', false);
    }
  }, [setFieldValue, values.Permissoes.VisualizarServicos]);

  useEffect(() => {
    if (values.Permissoes.EditarServicos) {
      setFieldValue('Permissoes.VisualizarServicos', true);
    } else {
      setFieldValue('IsAdmin', false);
    }
  }, [setFieldValue, values.Permissoes.EditarServicos]);

  useEffect(() => {
    if (!values.Permissoes.VisualizarOrdensServico) {
      setFieldValue('Permissoes.EditarOrdensServico', false);
      setFieldValue('IsAdmin', false);
    }
  }, [setFieldValue, values.Permissoes.VisualizarOrdensServico]);

  useEffect(() => {
    if (values.Permissoes.EditarOrdensServico) {
      setFieldValue('Permissoes.VisualizarOrdensServico', true);
    } else {
      setFieldValue('IsAdmin', false);
    }
  }, [setFieldValue, values.Permissoes.EditarOrdensServico]);

  useEffect(() => {
    if (!values.Permissoes.VisualizarUsuarios) {
      setFieldValue('Permissoes.EditarUsuarios', false);
      setFieldValue('IsAdmin', false);
    }
  }, [setFieldValue, values.Permissoes.VisualizarUsuarios]);

  useEffect(() => {
    if (values.Permissoes.EditarUsuarios) {
      setFieldValue('Permissoes.VisualizarUsuarios', true);
    } else {
      setFieldValue('IsAdmin', false);
    }
  }, [setFieldValue, values.Permissoes.EditarUsuarios]);

  useEffect(() => {
    if (!values.Permissoes.Dashboard) {
      setFieldValue('Permissoes.Dashboard', false);
      setFieldValue('IsAdmin', false);
    }
  }, [setFieldValue, values.Permissoes.Dashboard]);

  useEffect(() => {
    if (!values.Permissoes.Relatorios) {
      setFieldValue('Permissoes.Relatorios', false);
      setFieldValue('IsAdmin', false);
    }
  }, [setFieldValue, values.Permissoes.Relatorios]);

  useEffect(() => {
    if (!values.Permissoes.Configuracoes) {
      setFieldValue('Permissoes.Configuracoes', false);
      setFieldValue('IsAdmin', false);
    }
  }, [setFieldValue, values.Permissoes.Configuracoes]);

  const optionsUsuarios = ['Vendedor', 'Gerenciador'];
  return (
    <>
      <BarraBotoes
        botoes={[
          {
            variant: 'vermelho',
            onClick: () => navigate('/Usuarios/Listar'),
            children: 'Cancelar',
          },
          {
            variant: 'turquesa',
            type: 'submit',
            children: 'Salvar',
          },
        ]}
        caminho={[
          { nome: 'Usuários', path: '/Usuarios/Listar' },
          { nome: titulo },
        ]}
      />
      <Card titulo={titulo}>
        <InputFormik
          titulo="Tipo de usuário"
          id="TipoUsuario"
          name="TipoUsuario"
          component={InputSelect}
          options={optionsUsuarios}
          gridColumn={gridColumn.gc3}
        />
        <InputFormik
          titulo="Nome"
          id="Username"
          name="Username"
          component={InputTexto}
          gridColumn={gridColumn.gc3}
        />
        <InputFormik
          titulo="Email"
          id="Email"
          name="Email"
          component={InputTexto}
          gridColumn={gridColumn.gc3}
        />
        <InputFormik
          titulo="Administrador"
          id="Administrador"
          name="IsAdmin"
          component={InputToggle}
          gridColumn={gridColumn.gc3}
        />
        {values.Id == null && (
          <>
            <InputFormik
              titulo="Senha"
              id="Password"
              name="Password"
              component={InputPassword}
              gridColumn={gridColumn.gc3}
            />
            <InputFormik
              titulo="Repetir Senha"
              id="RepetirPassword"
              name="RepetirPassword"
              component={InputPassword}
              gridColumn={gridColumn.gc3}
            />
          </>
        )}
      </Card>
      <Card titulo="PERMISSÕES">
        <Box p={12} gridColumn={gridColumn.gc12}>
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>NOME</Th>
                  <Th>PODE VISUALIZAR?</Th>
                  <Th>PODE EDITAR?</Th>
                </Tr>
              </Thead>
              <Tbody>
                {permissoes.map(permissao => (
                  <Tr key={uuidv4()}>
                    <Td key={uuidv4()}>{permissao[0]}</Td>

                    <Td key={uuidv4()}>
                      <InputFormik
                        id={permissao[1]}
                        name={permissao[1]}
                        component={InputToggle}
                      />
                    </Td>
                    <Td key={uuidv4()}>
                      <InputFormik
                        id={permissao[2]}
                        name={permissao[2]}
                        component={InputToggle}
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </Card>
    </>
  );
}

export default EditarUsuarios;
