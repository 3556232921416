import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import '../../App.css';
import BarraBotoes from '../../components/BarraBotoes/BarraBotoes';
import FormPadrao from '../../components/FormPadrao/FormPadrao';
import Pagina from '../../components/Pagina/Pagina';
import Tabela from '../../components/Tabela/Tabela';
import api from '../../services/api';
import './Clientes.css';
import { getButtonNovoText } from './utils';
import Alerta from '../../components/Alerta/Alerta';
import { useDisclosure } from '@chakra-ui/react';
import Importacao from '../../components/Importacao/Importacao';

function ListarClientes({ categoria }) {
  const navigate = useNavigate();
  const [clientes, setClientes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchBar, setSearchBar] = useState('');
  const [importacao, setImportacao] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false });
  const [alertStatus, setAlertStatus] = useState('success');
  const [alertTitle, setAlertTitle] = useState('');

  useEffect(() => {
    async function GetClientes(filtros) {
      let payload = {
        database: localStorage.getItem('database'),
        data: JSON.stringify(filtros, null, 2),
      };
      let res = await api.post('/ListClientes/Get', payload);
      if (res.status === 200) {
        let data = res.data;
        data.Clientes.forEach(b => {
          b.Selecionado = false;
        });
        setClientes(data.Clientes);
        setLoading(false);
      } else {
        alert('Erro ao buscar dados');
      }
    }

    GetClientes({
      Categoria: categoria,
    });
  }, [categoria]);

  function handleEdit(item) {
    navigate(`/${categoria}/Editar/${item.Id}`);
  }

  function handleClick() {
    navigate(`/${categoria}/Editar`);
  }

  const uploadEmLote = async event => {
    const files = event.target.files;
    Array.from(files).forEach(async file => {
      const formData = new FormData();
      formData.append('file', file);

      try {
        let res = await api.post(
          `EditClientes/ImportExcel?database=${localStorage.getItem(
            'database'
          )}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        let data = res.data;
        if (data.Success) {
          setAlertStatus('success');
          setAlertTitle('Arquivo carregado com sucesso');
          onOpen();
        } else if (!data.Success) {
          setAlertStatus('error');
          setAlertTitle(data.MSG);
          onOpen();
        }
      } catch (error) {}
    });

    return;
  };

  return (
    <>
      <Alerta
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        alertStatus={alertStatus}
        title={alertTitle}
      />
      {importacao ? (
        <Importacao
          tipo={categoria}
          funcaoVoltar={() => setImportacao(false)}
          funcaoNovo={() => handleClick()}
          funcaoUpload={uploadEmLote}
        />
      ) : (
        <Pagina loading={loading}>
          <FormPadrao>
            <BarraBotoes
              botoes={[
                {
                  children: 'Importar em Lote',
                  onClick: () => setImportacao(true),
                  variant: 'azul',
                },
                {
                  children: getButtonNovoText(categoria),
                  onClick: handleClick,
                  variant: 'turquesa',
                },
              ]}
              search={{
                placeholder: `Procurar por ${categoria}`,
                onChange: e => setSearchBar(e.target.value),
              }}
            />
            <Tabela
              titulo={`LISTAGEM DE ${categoria.toUpperCase()} `}
              setFunction={setClientes}
              data={clientes}
              columns={[
                {
                  placeholder: `NOME ${
                    categoria !== 'Funcionarios' && categoria !== 'Vendedores'
                      ? 'FANTASIA'
                      : ''
                  }`,
                  name: 'NomeFantasia',
                  type: 'text',
                },
                {
                  placeholder: 'Razão Social',
                  name: 'RazaoSocial',
                  type: 'text',
                  hide:
                    categoria === 'Funcionarios' || categoria === 'Vendedores',
                },
                {
                  placeholder: 'CPF/CNPJ',
                  name: 'CPF_CNPJ',
                  type: 'text',
                },
                {
                  placeholder: 'Pessoa Física?',
                  name: 'EhFisica',
                  type: 'bool',
                },
                {
                  placeholder: 'Telefone',
                  name: 'Telefone',
                  type: 'text',
                },
                {
                  placeholder: 'Email',
                  name: 'Email',
                  type: 'text',
                },
              ]}
              searchBar={searchBar}
              handleEdit={handleEdit}
              useCheck={false}
            />
          </FormPadrao>
        </Pagina>
      )}
    </>
  );
}

export default ListarClientes;
