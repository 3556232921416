import React from 'react';
import './InputArquivos.css';
import { Input } from '@chakra-ui/react';
import Botao from '../../Botao/Botao';

function InputArquivos({ handleUpload, botao = false }) {
  if (botao) {
    const fileInput = React.createRef();

    const triggerInputFile = () => {
      if (
        fileInput.current != undefined &&
        fileInput.current.click != undefined
      )
        fileInput.current.click();
    };
    return (
      <>
        <Botao
          variant="amarelo"
          type={'button'}
          onClick={() => triggerInputFile()}
        >
          Importar arquivo
        </Botao>
        <Input
          ref={fileInput}
          type="file"
          multiple={true}
          onChange={handleUpload}
          style={{ display: 'none' }}
        />
      </>
    );
  } else {
    return (
      <div className="input-arquivo-remover-borda">
        <Input type="file" multiple={true} onChange={handleUpload} />
      </div>
    );
  }
}

export default InputArquivos;
