import {
    Box,
    Flex,
    Grid,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import '../../App.css';
import Botao from '../../components/Botao/Botao';
import IconeDeletar from '../../components/Icones/IconeDeletar/IconeDeletar';
import IconeEditar from '../../components/Icones/IconeEditar/IconeEditar';
import InputCheckbox from '../../components/Inputs/InputCheckbox/InputCheckbox';
import InputSearch from '../../components/Inputs/InputSearch/InputSearch';
import Pagina from '../../components/Pagina/Pagina';
import SecaoCadastro from '../../components/SecaoCadastro/SecaoCadastro';
import api from '../../services/api';
import { filtrar } from '../../utils/filtros';
import { formataData, formataDinheiro } from '../../utils/formatador';
import { gridColumn } from '../../utils/gridColumn';
import { sortAZ } from '../../utils/utils';
import './NFCe.css';

function ListarNFCe() {
  const navigate = useNavigate();
  const [NFCe, setNFCe] = useState([]);
  const [loading, setLoading] = useState(true);
  const [desc, setDesc] = useState(false);
  const [searchBar, setSearchBar] = useState('');

  useEffect(() => {
    async function GetNFCe() {
      let payload = {
        database: localStorage.getItem('database'),
        data: '{}',
      };
      let res = await api.post('/ListNFCe/Get', payload);
      if (res.status === 200) {
        let data = res.data;
        data.NFCes.forEach(b => {
          b.Selecionado = false;
        });
        setNFCe(data.NFCes);
        setLoading(false);
      } else {
        alert('Erro ao buscar dados');
      }
    }
    GetNFCe();
  }, []);

  function handleDelete(item) {
    const newList = NFCe.filter(nota => nota.Id !== item.Id);
    
    setNFCe(newList);
  }

  function handleEdit(item) {
    navigate(`/NFCe/Editar/${item.Id}`);
  }

  function handleClick() {
    navigate('/NFCe/Editar');
  }

  function handleDeleteMany(item) {
    const itens = NFCe;
    itens.map(nota => nota.Selecionado);
  }

  function handleCheck(item, value) {
    let itens = NFCe;
    for (let i = 0; i < itens.length; i++) {
      if (itens[i].Id === item.Id) {
        itens[i].Selecionado = value;
        break;
      }
    }
    setNFCe([...itens]);
  }

  function handleDoubleClick(param) {
    setDesc(!desc);
    setNFCe(sortAZ(NFCe, desc, param));
  }

  return (
    <>
      <Pagina loading={loading}>
        <form className="body">
          <Flex
            flexGrow={1}
            id="content"
            justifyContent="center"
            minHeight="100%"
          >
            <Grid
              gridTemplateColumns="repeat(12, 1fr)"
              gap={'10px'}
              mx={6}
              width="100%"
              height="fit-content"
            >
              <Box p={4} gridColumn={gridColumn.gc12} className="searchbuttons">
                <InputSearch
                  placeholder="Procurar por NFCe"
                  style={{ flex: 1 }}
                  onChange={e => setSearchBar(e.target.value)}
                />
                <Botao variant="azul">Baixar Lote</Botao>
                <Botao variant="verde">Baixar Zip</Botao>
                <Botao variant="vermelho" onClick={handleDeleteMany}>
                  Excluir
                </Botao>
                <Botao variant="turquesa" onClick={handleClick}>
                  Novo
                </Botao>
              </Box>
              <Box mb={7} className="card" gridColumn={gridColumn.gc12}>
                <SecaoCadastro titulo="LISTAGEM DE NFCe" />
                <Box className="cadastrados" gridColumn={gridColumn.gc12}>
                  <TableContainer>
                    <Table>
                      <Thead>
                        <Tr>
                          <Th></Th>
                          <Th>
                            <button
                              onDoubleClick={() => handleDoubleClick('Emissor')}
                              type="button"
                            >
                              EMISSOR
                            </button>
                          </Th>
                          <Th>
                            <button
                              onDoubleClick={() => handleDoubleClick('Cliente')}
                              type="button"
                            >
                              DESTINATÁRIO
                            </button>
                          </Th>
                          <Th>
                            <button
                              onDoubleClick={() =>
                                handleDoubleClick('NumeroNFe')
                              }
                              type="button"
                            >
                              NÚMERO
                            </button>
                          </Th>
                          <Th>
                            <button
                              onDoubleClick={() =>
                                handleDoubleClick('SerieNFe')
                              }
                              type="button"
                            >
                              SÉRIE
                            </button>
                          </Th>
                          <Th>
                            <button
                              onDoubleClick={() =>
                                handleDoubleClick('ValorNota')
                              }
                              type="button"
                            >
                              VALOR
                            </button>
                          </Th>
                          <Th>
                            <button
                              onDoubleClick={() => handleDoubleClick('Origem')}
                              type="button"
                            >
                              ORIGEM
                            </button>
                          </Th>
                          <Th>
                            <button
                              onDoubleClick={() =>
                                handleDoubleClick('StatusSefaz')
                              }
                              type="button"
                            >
                              STATUS
                            </button>
                          </Th>
                          <Th>
                            <button
                              onDoubleClick={() => handleDoubleClick('DataNFe')}
                              type="button"
                            >
                              EMISSÃO
                            </button>
                          </Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {NFCe &&
                          NFCe.filter(item => filtrar(item, searchBar)).map(
                            nota => (
                              <Tr
                                key={nota.Id}
                                className={`${
                                  nota.Selecionado ? 'checked' : 'notChecked'
                                }`}
                              >
                                <Td>
                                  <InputCheckbox
                                    item={nota}
                                    initialValue={nota.Selecionado}
                                    handleChange={handleCheck}
                                  />
                                  <button
                                    onClick={() => handleEdit(nota)}
                                    style={{
                                      width: '15px',
                                    }}
                                  >
                                    <IconeEditar />
                                  </button>
                                </Td>
                                <Td>{nota.Emissor}</Td>
                                <Td>{nota.Cliente}</Td>
                                <Td>{nota.NumeroNFe}</Td>
                                <Td>{nota.SerieNFe}</Td>
                                <Td>{formataDinheiro(nota.ValorNota)}</Td>
                                <Td>{nota.Origem}</Td>
                                <Td>{nota.StatusSefaz}</Td>
                                <Td>{formataData(nota.DataNFe)}</Td>
                                <Td>
                                  <button
                                    onClick={() => handleDelete(nota)}
                                    style={{
                                      width: '15px',
                                    }}
                                  >
                                    <IconeDeletar />
                                  </button>
                                </Td>
                              </Tr>
                            )
                          )}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Box>
              </Box>
            </Grid>
          </Flex>
        </form>
      </Pagina>
    </>
  );
}

export default ListarNFCe;
