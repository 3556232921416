import React from 'react';
import { RiDeleteBin6Fill } from 'react-icons/ri';
import './IconeDeletar.css';

function IconeDeletar({ ...props }) {
  return (
    <button type="button" {...props}>
      <RiDeleteBin6Fill className="iconeDeletar" />{' '}
    </button>
  );
}
export default IconeDeletar;
