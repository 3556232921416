import React from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import './InputSearch.css';

function InputSearch({ placeholder, ...rest }) {
  
  // function filter (event) {
  //   setFilterByName(event.target.value);
  //   return filterByName;
  // }

  return (
    <div className="inputSearchContainer">
        <AiOutlineSearch className="iconSearch"/>
        <input
          className="inputSearch"
          type="search"
          placeholder={placeholder}
          {...rest}
        />
    </div>
  );
}

export default InputSearch;
