import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import '../../App.css';
import BarraBotoes from '../../components/BarraBotoes/BarraBotoes';
import FormPadrao from '../../components/FormPadrao/FormPadrao';
import Pagina from '../../components/Pagina/Pagina';
import Tabela from '../../components/Tabela/Tabela';
import api from '../../services/api';
import './CondicoesPagamento.css';

function ListarCondicoesPagamento() {
  const navigate = useNavigate();

  const [condicoesPagamento, setCondicoesPagamento] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchBar, setSearchBar] = useState('');

  useEffect(() => {
    async function GetCondicoesPagamento() {
      let payload = {
        database: localStorage.getItem('database'),
        data: '{}',
      };
      let res = await api.post('/ListCondicoesPagamento/Get', payload);
      if (res.status === 200) {
        let data = res.data;
        data.CondicoesPagamento.forEach(b => {
          b.Selecionado = false;
        });
        setCondicoesPagamento(data.CondicoesPagamento);
        setLoading(false);
      } else {
        alert('Erro ao buscar dados');
      }
    }
    GetCondicoesPagamento();
  }, []);

  function handleEdit(item) {
    navigate(`/CondicoesPagamento/Editar/${item.Id}`);
  }

  function handleClick() {
    navigate('/CondicoesPagamento/Editar');
  }

  return (
    <>
      <Pagina loading={loading}>
        <FormPadrao>
          <BarraBotoes
            botoes={[
              {
                children: 'Novo',
                onClick: handleClick,
                variant: 'turquesa',
              },
            ]}
            search={{
              placeholder: 'Procurar por condições de pagamento',
              onChange: e => setSearchBar(e.target.value),
            }}
          />
          <Tabela
            titulo={'LISTAGEM DE CONDIÇÕES DE PAGAMENTO'}
            setFunction={setCondicoesPagamento}
            data={condicoesPagamento}
            columns={[
              {
                placeholder: 'Descrição',
                name: 'Descricao',
                type: 'text',
              },
              {
                placeholder: 'TABELA DE PREÇOS',
                name: 'TabelaPreco',
                type: 'text',
              },
              {
                placeholder: 'INATIVA?',
                name: 'Inativa',
                type: 'bool',
              },
            ]}
            searchBar={searchBar}
            handleEdit={handleEdit}
            showDelete={false}
            useCheck={false}
          />
        </FormPadrao>
      </Pagina>
    </>
  );
}

export default ListarCondicoesPagamento;
