import { Select } from '@chakra-ui/react';
import React from 'react';
import './InputSelect.css';

function InputSelect({ options, placeholder, ...rest }) {
  return (
    <Select className="inputselect" placeholder={placeholder} {...rest}>
      {options.map(op => {
        return op instanceof Object ? (
          <option key={op.value + op.name} value={op.value}>
            {op.label ? op.label : op.name}
          </option>
        ) : (
          <option key={op + op.name} value={op}>
            {op}
          </option>
        );
      })}
    </Select>
  );
}

export default InputSelect;
