import { Box } from '@chakra-ui/react';
import React, { useState } from 'react';
import '../../App.css';
import { gridColumn } from '../../utils/gridColumn';
import IconeAdicionar from '../Icones/IconeAdicionar/IconeAdicionar';
import InputAutoComplete from '../Inputs/InputAutoComplete/InputAutoComplete';

function PesquisaProdutos({ funcao }) {
  const [search, setSearch] = useState('');

  let onSourceSelect = (a, b) => {
    setSearch(b);
  };

  let handleClick = () => {
    funcao(search);
    setSearch('')
  };

  return (
    <>
      <Box p={2} gridColumn={gridColumn.gc10}>
        <InputAutoComplete
          id="Produto"
          name="Produto"
          path={'Produto'}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onSourceSelect={onSourceSelect}
          database={localStorage.getItem('database')}
        />
      </Box>
      <Box p={2} gridColumn={gridColumn.gc2}>
        <button type="button" onClick={() => handleClick()}>
          <IconeAdicionar />
        </button>
      </Box>
    </>
  );
}

export default PesquisaProdutos;
