import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import '../../App.css';
import BarraBotoes from '../../components/BarraBotoes/BarraBotoes';
import FormPadrao from '../../components/FormPadrao/FormPadrao';
import Pagina from '../../components/Pagina/Pagina';
import api from '../../services/api';
import './Estoques.css';

import Tabela from '../../components/Tabela/Tabela';

function ListarEstoques() {
  const [estoques, setEstoques] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchBar, setSearchBar] = useState('');

  useEffect(() => {
    async function GetEstoques(filtros) {
      let payload = {
        database: localStorage.getItem('database'),
        data: JSON.stringify(filtros, null, 2),
      };
      let res = await api.post('/ListDepositos/Get', payload);
      if (res.status === 200) {
        let data = res.data;
        data.Depositos.forEach(b => {
          b.Selecionado = false;
        });
        setLoading(false);
        setEstoques(data.Depositos);
      } else {
        alert('Erro ao buscar dados');
      }
    }
    GetEstoques();
  }, []);

  const navigate = useNavigate();

  function handleClick() {
    navigate('/Estoques/Editar');
  }

  function handleEdit(item) {
    navigate(`/Estoques/Editar/${item.Id}`);
  }

  return (
    <>
      <Pagina loading={loading}>
        <FormPadrao>
          <BarraBotoes
            botoes={[
              {
                children: 'Novo',
                onClick: handleClick,
                variant: 'turquesa',
              },
            ]}
            search={{
              placeholder: 'Procurar por estoques',
              onChange: e => setSearchBar(e.target.value),
            }}
          />
          <Tabela
            titulo={'LISTAGEM DE ESTOQUES'}
            setFunction={setEstoques}
            data={estoques}
            columns={[
              {
                placeholder: 'Nome',
                name: 'Nome',
                type: 'text',
              },
              {
                placeholder: 'EMPRESA',
                name: 'Empresa',
                type: 'text',
              },
            ]}
            searchBar={searchBar}
            handleEdit={handleEdit}
            showDelete={false}
            useCheck={false}
          />
        </FormPadrao>
      </Pagina>
    </>
  );
}

export default ListarEstoques;
