import React from 'react';

import {
    Alert, AlertDescription,
    AlertDialog, AlertDialogContent, AlertDialogOverlay, AlertIcon,
    AlertTitle,
    Box,
    CloseButton
} from '@chakra-ui/react';

function Alerta({
  alertStatus = 'success',
  isOpen,
  onClose,
  onOpen,
  title,
  description,
}) {
  return (
    isOpen && (
      <AlertDialog isOpen={isOpen} onClose={onClose}>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <Alert status={alertStatus} flexDirection="column">            
            <Box display='flex' flexDirection="row" width="100%" justifyContent="space-between" alignItems="center">
              <AlertIcon />
              <AlertTitle>{title.replace('<br>', '\n')}</AlertTitle>
              <CloseButton onClick={onClose} alignSelf="flex-start" />
            </Box>            
            {description && <AlertDescription>{description}</AlertDescription>}
          </Alert>
        </AlertDialogContent>
      </AlertDialog>
    )
  );
}

export default Alerta;
