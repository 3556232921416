import { Box, useStyleConfig } from '@chakra-ui/react';
import React from 'react';

function Botao(props) {
  const { variant, ...rest } = props;
  const styles = useStyleConfig('Botao', { variant });

  return (
    <Box as="button" type="button" {...rest} sx={styles}>
      {props.children}
    </Box>
  );
}

export default Botao;
