import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import '../../App.css';
import BarraBotoes from '../../components/BarraBotoes/BarraBotoes';
import FormPadrao from '../../components/FormPadrao/FormPadrao';
import Pagina from '../../components/Pagina/Pagina';
import Tabela from '../../components/Tabela/Tabela';
import api from '../../services/api';
import './SituacaoTributaria.css';

function ListarSituacaoTributaria() {
  const navigate = useNavigate();
  const [situacoes, setSituacoesTributarias] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchBar, setSearchBar] = useState('');

  useEffect(() => {
    async function GetSituacoesTributarias() {
      let payload = {
        database: localStorage.getItem('database'),
        data: '{}',
      };
      let res = await api.post('/ListSituacaoTributaria/Get', payload);
      if (res.status === 200) {
        let data = res.data;
        data.SituacaoTributaria.forEach(b => {
          b.Selecionado = false;
        });
        setSituacoesTributarias(data.SituacaoTributaria);
        setLoading(false);
      } else {
        alert('Erro ao buscar dados');
      }
    }
    GetSituacoesTributarias();
  }, []);

  function handleClick() {
    navigate('/SituacaoTributaria/Editar');
  }

  function handleEdit(item) {
    navigate(`/SituacaoTributaria/Editar/${item.Id}`);
  }

  return (
    <>
      <Pagina loading={loading}>
        <FormPadrao>
          <BarraBotoes
            botoes={[
              {
                children: 'Nova',
                onClick: handleClick,
                variant: 'turquesa',
              },
            ]}
            search={{
              placeholder: 'Procurar por situações tributárias',
              onChange: e => setSearchBar(e.target.value),
            }}
          />
          <Tabela
            titulo={'LISTAGEM DE SITUAÇÕES TRIBUTÁRIAS'}
            setFunction={setSituacoesTributarias}
            data={situacoes}
            columns={[
              {
                placeholder: 'NOME',
                name: 'Nome',
                type: 'text',
              }
            ]}
            searchBar={searchBar}
            handleEdit={handleEdit}
            useCheck={false}
          />
        </FormPadrao>
      </Pagina>
    </>
  );
}

export default ListarSituacaoTributaria;
