import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import '../../App.css';
import BarraBotoes from '../../components/BarraBotoes/BarraBotoes';
import FormPadrao from '../../components/FormPadrao/FormPadrao';
import Pagina from '../../components/Pagina/Pagina';
import Tabela from '../../components/Tabela/Tabela';
import api from '../../services/api';
import './ArquivosRH.css';

function ListarArquivosRH() {
  const [arquivosRH, setArquivosRH] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchBar, setSearchBar] = useState('');

  useEffect(() => {
    async function GetArquivosRH() {
      let payload = {
        database: localStorage.getItem('database'),
        data: '{}',
      };
      let res = await api.post('/ListEmpresas/Get', payload);
      let data = res.data;

      if (res.status === 200) {
        setArquivosRH(data.Empresas);
        setLoading(false);
      } else {
        alert('Erro ao buscar dados');
      }
    }
    GetArquivosRH();
  }, []);

  const navigate = useNavigate();

  function handleEdit(item) {
    navigate(`/ArquivosRH/Editar/${item.Id}`);
  }

  return (
    <>
      <Pagina loading={loading}>
        <FormPadrao>
          <BarraBotoes
            botoes={[]}
            search={{
              placeholder: 'Procurar por empresas',
              onChange: e => setSearchBar(e.target.value),
            }}
          />
          <Tabela
            titulo={'Arquivos das Empresas'}
            setFunction={setArquivosRH}
            data={arquivosRH}
            columns={[
              {
                placeholder: 'Razão Social',
                name: 'RazaoSocial',
                type: 'text',
              },
              {
                placeholder: 'Nome Fantasia',
                name: 'NomeFantasia',
                type: 'text',
              },
              {
                placeholder: 'CNPJ',
                name: 'CNPJ',
                type: 'text',
              },
            ]}
            searchBar={searchBar}
            handleEdit={handleEdit}
          />
        </FormPadrao>
      </Pagina>
    </>
  );
}

export default ListarArquivosRH;
