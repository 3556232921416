import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import '../../App.css';
import InputAutoComplete from '../../components/Inputs/InputAutoComplete/InputAutoComplete';
import InputData from '../../components/Inputs/InputData/InputData';
import InputSelect from '../../components/Inputs/InputSelect/InputSelect';
import Pagina from '../../components/Pagina/Pagina';
import api from '../../services/api';
import { getFiltroPadraoRelatorioFluxoBancario } from '../../utils/filtros';
import { gridColumn } from '../../utils/gridColumn';
import { ajustaDatas, optionsDatas } from '../../utils/utils';
import FormikPadrao from '../../components/FormikPadrao/FormikPadrao';
import BarraBotoes from '../../components/BarraBotoes/BarraBotoes';
import Tabela from '../../components/Tabela/Tabela';
import InputFiltro from '../../components/Inputs/InputFiltro/InputFiltro';
import Filtros from '../../components/Filtros/Filtros';
import { baixarArquivoBase64 } from '../../utils/download';

function RelFluxoBancario() {
  const [loading, setLoading] = useState(true);
  const [search] = useSearchParams();
  const [searchBar, setSearchBar] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [relFluxoBancario, setRelFluxoBancario] = useState([]);

  async function GetRelFluxoBancario(filtro) {
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(filtro, null, 2),
    };
    let res = await api.post('/FluxoBancarioReport/GetList', payload);
    let data = res.data;
    if (res.status === 200) {
      setRelFluxoBancario(data.LanBancarios);
      setLoading(false);
    } else {
      alert('Erro ao buscar dados');
    }
  }

  useEffect(() => {
    GetRelFluxoBancario(getFiltroPadraoRelatorioFluxoBancario());
  }, []);

  useEffect(() => {
    if (search.get('filter')) {
      setShowFilters(search.get('filter') === 'true');
      GetRelFluxoBancario(getFiltroPadraoRelatorioFluxoBancario());
    }
  }, [search]);

  const getPDF = async () => {
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(relFluxoBancario, null, 2),
    };
    baixarArquivoBase64('/FluxoBancarioReport/GetPDF', payload);
  };

  return (
    <Pagina loading={loading}>
      <FormikPadrao
        initialValues={getFiltroPadraoRelatorioFluxoBancario()}
        onSubmit={async values => {
          await GetRelFluxoBancario(values);
        }}
      >
        <BarraBotoes
          maxWindowSize={1030}
          botoes={[
            {
              children: 'Exportar PDF',
              onClick: getPDF,
              variant: 'rosa',
            },
          ]}
          search={{
            placeholder: 'Procurar por lançamentos',
            onChange: e => setSearchBar(e.target.value),
          }}
          showFilters={showFilters}
          setShowFilters={setShowFilters}
        />
        <FilterMenu showFilters={showFilters} setShowFilters={setShowFilters} />
        <Tabela
          titulo={'RELATÓRIO DE FLUXO BANCÁRIO'}
          setFunction={setRelFluxoBancario}
          data={relFluxoBancario}
          columns={[
            {
              placeholder: 'CONTA',
              name: 'Banco',
              type: 'text',
            },
            {
              placeholder: 'DATA',
              name: 'Data',
              type: 'date',
            },
            {
              placeholder: 'RECEITAS',
              name: 'Receitas',
              type: 'money',
            },
            {
              placeholder: 'DESPESAS',
              name: 'Despesas',
              type: 'money',
            },
            {
              placeholder: 'SALDO DO DIA',
              name: 'Total',
              type: 'money',
            },
          ]}
          searchBar={searchBar}
        />
      </FormikPadrao>
    </Pagina>
  );
}

function FilterMenu({ showFilters, setShowFilters }) {
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    ajustaDatas(values.PeriodoLancamento, setFieldValue);
  }, [values.PeriodoLancamento, setFieldValue]);

  return (
    <Filtros showFilters={showFilters} setShowFilters={setShowFilters}>
      <InputFiltro
        titulo="EMPRESA"
        id="Empresa"
        name="Empresa"
        component={InputAutoComplete}
        autocomplete={true}
        path={'Empresa'}
        database={localStorage.getItem('database')}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="BANCO"
        id="ContaBancaria"
        name="ContaBancaria"
        component={InputAutoComplete}
        autocomplete={true}
        path={'Bancos'}
        database={localStorage.getItem('database')}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="PERÍODO"
        id="PeriodoLancamento"
        name="PeriodoLancamento"
        component={InputSelect}
        options={optionsDatas}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="Data Inicial"
        id="DataInicial"
        name="DataInicial"
        component={InputData}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="Data Final"
        id="DataFinal"
        name="DataFinal"
        component={InputData}
        gridColumn={gridColumn.gc4}
      />
    </Filtros>
  );
}

export default RelFluxoBancario;
