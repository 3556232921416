import React, { useEffect, useState } from 'react';
import '../../App.css';
import InputData from '../../components/Inputs/InputData/InputData';
import Pagina from '../../components/Pagina/Pagina';
import api from '../../services/api';
import { getFiltroPadraoRelatorioFechamentoPDV } from '../../utils/filtros';
import { gridColumn } from '../../utils/gridColumn';
import { baixarArquivoBase64 } from '../../utils/download';
import Filtros from '../../components/Filtros/Filtros';
import InputFiltro from '../../components/Inputs/InputFiltro/InputFiltro';
import Tabela from '../../components/Tabela/Tabela';
import TotalizadorRelatorio from '../../components/TotalizadorRelatorio/TotalizadorRelatorio';
import BarraBotoes from '../../components/BarraBotoes/BarraBotoes';
import FormikPadrao from '../../components/FormikPadrao/FormikPadrao';
import { Box, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import SecaoCadastro from '../../components/SecaoCadastro/SecaoCadastro';
import { formataDinheiro } from '../../utils/formatador';

function RelFechamentoPDV() {
  const [showFilters, setShowFilters] = useState(false);
  const [searchBar, setSearchBar] = useState('');
  const [relFechamentoPDV, setRelFechamentoPDV] = useState({
    TotalVendas: 0,
    TotalNFCe: 0,
    TotalDescontos: 0,
    TotalClientes: 0,
    Credito: 0,
    Debito: 0,
    Dinheiro: 0,
    OutrasFormas: 0,
    TotalBruto: 0,
    TotalTroco: 0,
    TotalTroca: 0,
    TotalLiquido: 0,
  });
  const [loading, setLoading] = useState(true);
  const [vendasFuncionarios, setVendasFuncionarios] = useState([]);
  const [caixa, setCaixa] = useState({
    Email: '',
    SaldoAtual: 0,
  });
  const { email } = JSON.parse(localStorage.getItem('user'));
  async function GetRelFechamentoPDV(filtros) {
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(filtros, null, 2),
    };
    let res = await api.post('/PDVReport/GetReportFechamentoCaixa', payload);
    if (res.status === 200) {
      let data = res.data;
      setRelFechamentoPDV(data.relatorio);
      setVendasFuncionarios(data.relatorio.Funcionarios);
      setCaixa(data.relatorio.Caixa);

      setLoading(false);
    } else {
      alert('Erro ao buscar dados');
    }
  }

  useEffect(() => {
    GetRelFechamentoPDV(getFiltroPadraoRelatorioFechamentoPDV(email));
  }, [email]);

  const getPDF = async () => {
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(relFechamentoPDV, null, 2),
    };
    baixarArquivoBase64('/PDVReport/GetPDFFechamentoPDV', payload);
  };

  return (
    <Pagina loading={loading}>
      <FormikPadrao
        initialValues={getFiltroPadraoRelatorioFechamentoPDV(email)}
        onSubmit={async values => {
          await GetRelFechamentoPDV(values);
        }}
      >
        <BarraBotoes
          maxWindowSize={1030}
          botoes={[
            {
              children: 'Exportar PDF',
              onClick: getPDF,
              variant: 'rosa',
            },
          ]}
          search={{
            placeholder: 'Procurar por vendedores',
            onChange: e => setSearchBar(e.target.value),
          }}
          showFilters={showFilters}
          setShowFilters={setShowFilters}
        />
        <FilterMenu showFilters={showFilters} setShowFilters={setShowFilters} />
        <TotalizadorRelatorio
          items={[
            {
              Titulo: 'CAIXA',
              Valor: caixa.Email,
              type: 'money',
            },
            {
              Titulo: 'SALDO ATUAL',
              Valor: caixa.SaldoAtual,
              type: 'money',
            },
          ]}
        />
        <Box mb={7} gridColumn={gridColumn.gc12} className="card">
          <SecaoCadastro titulo={'DADOS GERAIS'} />
          <Table>
            <Thead>
              <Tr>
                <Th>Total Vendas</Th>
                <Th>Total NFCe </Th>
                <Th>Total Descontos</Th>
                <Th>Total Clientes</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>{formataDinheiro(relFechamentoPDV.TotalVendas)}</Td>
                <Td>{formataDinheiro(relFechamentoPDV.TotalNFCe)}</Td>
                <Td>{formataDinheiro(relFechamentoPDV.TotalDescontos)}</Td>
                <Td>{formataDinheiro(relFechamentoPDV.TotalClientes)}</Td>
              </Tr>
            </Tbody>
          </Table>
        </Box>
        <Box mb={7} gridColumn={gridColumn.gc12} className="card">
          <SecaoCadastro titulo={'FORMAS DE PAGAMENTO'} />
          <Table>
            <Thead>
              <Tr>
                <Th>Crédito</Th>
                <Th>Débito </Th>
                <Th>Dinheiro</Th>
                <Th>Outras</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>{formataDinheiro(relFechamentoPDV.Credito)}</Td>
                <Td>{formataDinheiro(relFechamentoPDV.Debito)}</Td>
                <Td>{formataDinheiro(relFechamentoPDV.Dinheiro)}</Td>
                <Td>{formataDinheiro(relFechamentoPDV.OutrasFormas)}</Td>
              </Tr>
            </Tbody>
          </Table>
        </Box>
        <Box mb={7} gridColumn={gridColumn.gc12} className="card">
          <SecaoCadastro titulo={'TOTAIS'} />
          <Table>
            <Thead>
              <Tr>
                <Th>Total Bruto</Th>
                <Th>Total Troco </Th>
                <Th>Total Troca</Th>
                <Th>Total Líquido</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>{formataDinheiro(relFechamentoPDV.TotalBruto)}</Td>
                <Td>{formataDinheiro(relFechamentoPDV.TotalTroco)}</Td>
                <Td>{formataDinheiro(relFechamentoPDV.TotalTroca)}</Td>
                <Td>{formataDinheiro(relFechamentoPDV.TotalLiquido)}</Td>
              </Tr>
            </Tbody>
          </Table>
        </Box>
        <Tabela
          titulo={'POR VENDEDOR'}
          setFunction={setVendasFuncionarios}
          data={vendasFuncionarios}
          columns={[
            {
              placeholder: 'VENDEDOR',
              name: 'Vendedor',
              type: 'text',
            },
            {
              placeholder: 'VALOR',
              name: 'TotalVendas',
              type: 'money',
            },
          ]}
          searchBar={searchBar}
        />
      </FormikPadrao>
    </Pagina>
  );
}

function FilterMenu({ showFilters, setShowFilters }) {
  return (
    <Filtros showFilters={showFilters} setShowFilters={setShowFilters}>
      <InputFiltro
        titulo="Data"
        id="DataAbertura"
        name="DataAbertura"
        component={InputData}
        gridColumn={gridColumn.gc4}
      />
    </Filtros>
  );
}

export default RelFechamentoPDV;
