import { Flex, Grid } from '@chakra-ui/react';
import React from 'react';

import '../../App.css';
import './FormPadrao.css';

function FormPadrao({ onSubmit = () => {}, children }) {
  return (
    <form className="body" onSubmit={onSubmit}>
      <Flex flexGrow={1} id="content" justifyContent="center" minHeight="100%">
        <Grid
          gridTemplateColumns="repeat(12, 1fr)"
          gap={'10px'}
          mx={6}
          width="100%"
          height="fit-content"
        >
          {children}
        </Grid>
      </Flex>
    </form>
  );
}

export default FormPadrao;
