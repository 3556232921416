import { Box, Text, useDisclosure } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import '../../App.css';
import Alerta from '../../components/Alerta/Alerta';
import Botao from '../../components/Botao/Botao';
import Card from '../../components/Card/Card';
import InputAutoComplete from '../../components/Inputs/InputAutoComplete/InputAutoComplete';
import InputFormik from '../../components/Inputs/InputFormik/InputFormik';
import InputNumInt from '../../components/Inputs/InputNumInt/InputNumInt';
import InputSelect from '../../components/Inputs/InputSelect/InputSelect';
import InputTextArea from '../../components/Inputs/InputTextArea/InputTextArea';
import InputTexto from '../../components/Inputs/InputTexto/InputTexto';
import InputToggle from '../../components/Inputs/InputToggle/InputToggle';
import Pagina from '../../components/Pagina/Pagina';
import api from '../../services/api';
import { gridColumn } from '../../utils/gridColumn';
import {
  addIDtoURL,
  isNotNullOrEmpty,
  optionsPaises,
  optionsUFs,
  optionsUFsExportacao,
} from '../../utils/utils';
import BarraBotoes from './../../components/BarraBotoes/BarraBotoes';
import './NFe.css';
import FormikPadrao from '../../components/FormikPadrao/FormikPadrao';
import {
  optionsCSOSN,
  optionsFinalidade,
  optionsFormaPagamento,
  optionsFrete,
  optionsIndicadorIE,
  optionsTipoNFe,
  optionsTipoPagamento,
} from './utils';
import TabelaInterna from '../../components/Tabela/TabelaInterna';
import PesquisaProdutos from '../../components/PesquisaProdutos/PesquisaProdutos';

function EditarNFe() {
  const navigate = useNavigate();
  const [email, setUser] = useState('lrgobatto@gmail.com');
  const emailUser = localStorage.getItem('user');

  useEffect(() => {
    if (emailUser) {
      const { email } = JSON.parse(emailUser);
      setUser(email);
    }
  }, [emailUser]);

  const [NFe, setNFe] = useState({
    GerenciadorId: '',
    NFeId: '',
    SerieNFe: 123,
    NFeXMLEnviado: '',
    NFeXMLRecebido: '',
    Operacao: 'Entrada',
    FinalidadeDoDocumento: '1',
    DataNFe: '0001-01-01',
    StatusProcessamento: '',
    DataStatusProcessamento: '0001-01-01',
    StatusSefaz: '',
    DataStatusSefaz: '0001-01-01',
    CodigoRetornoSefaz: 0,
    StatusDestinatario: '',
    DataStatusDestinatario: '0001-01-01',
    Origem: 'Avulsa',
    LoteId: '',
    LoteNumero: 0,
    SituacaoNfeXML: '',
    VendaId: '',
    NumeroNFe: 327,
    NumeroProtocolo: '',
    CNPJEmissor: '',
    Emissor: '33204005000195 | ERP Suite',
    EmissorId: '603f8f4f5e781113602e802f',
    CodigoUF_Recepcao: '',
    Cliente: '',
    DestinatarioEmails: '',
    DestinatarioRazaoSocial: '',
    DestinatarioCNPJ: '',
    ValorNota: 0,
    ICMS: 0,
    IPI: 0,
    ICMSST: 0,
    CFOP: '',
    CSOSN: 400,
    EhNFCe: false,
    Versao: '',
    DigVal: '',
    ValorMedioImpostos: 0,
    ValorMedioImpostosEstaduais: 0,
    ValorMedioImpostosMunicipais: 0,
    AmbienteDeEnvio: '',
    UF_Recepcao: '',
    JsonNFe: '',
    DadosNota: {
      Nota: {
        Numero: 1,
        Serie: 1,
        Modelo: 0,
        InformacoesComplementares: '',
        NaturezaDaOperacao: 'Venda',
        FinalidadeNF: 1,
        IndicacaoConsumidorFinal: 1,
        TipoNF: 1,
        ChaveAcessoReferenciada: '',
        PrecisaDestacarImpostos: false,
        UFExportacao: '',
        LocalExportacao: '',
      },
      Loja: {
        IE: '',
        RegimeTributario: 0,
        Nome: '',
        Endereco: {
          Logradouro: '',
          Numero: 0,
          Bairro: '',
          Municipio: '',
          CodigoIBGEMunicipio: 0,
          UF: '',
          CodigoIBGEUF: 0,
          CEP: '',
          CodigoPais: '1058',
          Pais: 'Brasil',
        },
        NumeroDocumento: '',
        PessoaFisica: false,
      },
      Cliente: {
        IE: '',
        IndicadorIE: 2,
        Telefone: '',
        Nome: '',
        Endereco: {
          Logradouro: '',
          Numero: 0,
          Bairro: '',
          Municipio: '',
          CodigoIBGEMunicipio: 0,
          UF: '',
          CodigoIBGEUF: 0,
          CEP: '',
          CodigoPais: '1058',
          Pais: 'Brasil',
        },
        NumeroDocumento: '',
        PessoaFisica: false,
      },
      Produtos: [],
      Pagamento: {
        Tipo: 'A vista',
        FormaPagamento: 'Dinheiro',
        Valor: 0,
        QuantidadeParcelas: 1,
        IntervaloDiasParcelas: 0,
        Cartao: {
          tpIntegra: 2,
          CNPJ: '',
          tBand: 2,
          cAut: '',
        },
      },
      Transportadora: {
        IE: '',
        Modalidade: 1,
        TemTransportadora: false,
        CodigoANTT: '',
        PlacaVeiculo: '',
        VeiculoUF: '',
        Volumes: [],
        Nome: '',
        Endereco: {
          Logradouro: '',
          Numero: 0,
          Bairro: '',
          Municipio: '',
          CodigoIBGEMunicipio: 0,
          UF: '',
          CodigoIBGEUF: 0,
          CEP: '',
          CodigoPais: '1058',
          Pais: 'Brasil',
        },
        NumeroDocumento: '',
        PessoaFisica: false,
      },
    },
    SimplesNacional: true,
    EmailRecebido: false,
    DataEmailRecebido: '0001-01-01',
    EmContingencia: false,
    EstoqueAtualizado: false,
    TransfId: '',
    DataUltimaConsulta: '0001-01-01',
    IgnoreOnMaxNum: false,
    ConsumidorFinal: false,
    IdXMLDominioContabil: '',
    ChaveIntegracaoDominioContabil: '',
    Id: '',
    Volumes: [],
    Produtos: [],
  });
  const [loading, setLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false });
  const [alertStatus, setAlertStatus] = useState('success');
  const [alertTitle, setAlertTitle] = useState('');

  let { id } = useParams();

  useEffect(() => {
    async function GetNFe() {
      let payload = {
        database: localStorage.getItem('database'),
        data: id,
      };
      let res = await api.post('/EditNFe/Get', payload);
      if (res.status === 200) {
        let data = res.data;
        let nfe = data.NFe;
        nfe.Volumes = data.NFe.DadosNota.Transportadora.Volumes
          ? data.NFe.DadosNota.Transportadora.Volumes
          : [];
        nfe.Produtos = data.NFe.DadosNota.Produtos
          ? data.NFe.DadosNota.Produtos
          : [];

        setNFe(data.NFe);
        setLoading(false);
      } else {
        alert('Erro ao buscar dados');
      }
    }
    GetNFe();
  }, [id]);

  let save = async value => {
    setLoading(true);
    let payload = {
      data: value,
      database: localStorage.getItem('database'),
    };
    let res = await api.post('/EditNFe/Save', payload);
    let data = res.data;
    setLoading(false);
    if (data.Success) {
      setAlertStatus('success');
      setAlertTitle(data.MSG);
      onOpen();
      addIDtoURL(id, data.ItemID);
    } else {
      setAlertStatus('error');
      setAlertTitle(data.MSG);
      onOpen();
    }
  };

  async function gerarDanfe(values) {
    setLoading(true);
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(values, null, 2),
      arg: email,
    };
    let res = await api.post('/EditNFe/GerarDANFE', payload);
    if (res.status === 200) {
      let data = res.data;
      let pdf = data.Retorno.MensagemRetornoSEFAZ;

      const downloadLink = document.createElement('a');
      downloadLink.href = `data:application/pdf;base64,${pdf}`;
      downloadLink.download = 'danfe_' + values.NFeId + '.pdf';
      downloadLink.click();

      const downloadLinkXML = document.createElement('a');
      downloadLinkXML.href =
        'data:application/xml,' + encodeURIComponent(data.XML);
      downloadLinkXML.download = values.NFeId + '.xml';
      downloadLinkXML.click();

      setLoading(false);
    } else {
      alert('Erro ao buscar dados');
    }
  }

  async function consultarNFe(chave, numeroDocumento, xml) {
    setLoading(true);
    let payload = {
      database: localStorage.getItem('database'),
      data: chave,
      arg: numeroDocumento,
      arg2: email,
      arg3: xml,
    };
    let res = await api.post('/EditNFe/ConsultaStatus', payload);
    if (res.status === 200) {
      let data = res.data;
      setLoading(false);
      if (data.Retorno.Success) {
        setAlertStatus('success');
        setAlertTitle(data.Retorno.ReturnIfSuccess);
        onOpen();
      } else {
        setAlertStatus('error');
        setAlertTitle(data.Retorno.ReturnIfFail);
        onOpen();
      }
    } else {
      alert('Erro ao buscar dados');
    }
  }

  async function emitirNFe(values) {
    setLoading(true);
    //Nome cliente
    values.DadosNota.Cliente.Nome = values.Cliente;
    //Nome município
    var municipio = document.getElementById(
      'DadosNota.Cliente.Endereco.CodigoIBGEMunicipio'
    ).options[
      document.getElementById('DadosNota.Cliente.Endereco.CodigoIBGEMunicipio')
        .selectedIndex
    ].text;
    if (isNotNullOrEmpty(municipio)) {
      values.DadosNota.Cliente.Endereco.CidadeEndereco = municipio.trim();
    }
    //Nome UF
    var estado = document.getElementById(
      'DadosNota.Cliente.Endereco.CodigoIBGEUF'
    ).options[
      document.getElementById('DadosNota.Cliente.Endereco.CodigoIBGEUF')
        .selectedIndex
    ].text;
    if (isNotNullOrEmpty(estado)) {
      values.DadosNota.Cliente.Endereco.UFEndereco = estado.trim();
    }
    //Nome Pais
    var pais = document.getElementById('DadosNota.Cliente.Endereco.CodigoPais')
      .options[
      document.getElementById('DadosNota.Cliente.Endereco.CodigoPais')
        .selectedIndex
    ].text;
    if (isNotNullOrEmpty(estado)) {
      values.DadosNota.Cliente.Endereco.Pais = pais.trim();
    }
    //Emissão
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(values, null, 2),
      arg: email,
    };
    let res = await api.post('/EditNFe/EmitirNotaAvulsa', payload);
    if (res.status === 200) {
      let data = res.data;
      setLoading(false);
      if (data.Success) {
        setAlertTitle(data.Retorno.MensagemRetornoSEFAZ);
        if (data.Retorno.SucessoEnvio) {
          setAlertStatus('success');
          onOpen();
        } else {
          if (data.Retorno.MensagemRetornoSEFAZ.includes('Autorizado o uso')) {
            setAlertStatus('success');
            onOpen();
          } else {
            setAlertStatus('error');
            onOpen();
          }
        }
      } else {
        setAlertStatus('error');
        setAlertTitle(data.MSG);
        onOpen();
      }
    } else {
      alert('Erro ao buscar dados');
    }
  }

  async function cancelarNFe() {
    setLoading(true);
    let payload = {
      database: localStorage.getItem('database'),
      data: id,
    };
    let res = await api.post('/EditNFe/Get', payload);
    if (res.status === 200) {
      let data = res.data;

      setLoading(false);
    } else {
      alert('Erro ao buscar dados');
    }
  }

  async function enviarCarta() {
    setLoading(true);
    let payload = {
      database: localStorage.getItem('database'),
      data: id,
    };
    let res = await api.post('/EditNFe/Get', payload);
    if (res.status === 200) {
      let data = res.data;

      setLoading(false);
    } else {
      alert('Erro ao buscar dados');
    }
  }

  async function enviarNotaDominioContabil() {
    setLoading(true);
    let payload = {
      data: id,
      database: localStorage.getItem('database'),
    };
    let res = await api.post('/EditNFe/EnviarNotaDominioContabil', payload);
    let data = res.data;
    setLoading(false);
    if (data.Success) {
      setAlertStatus('success');
      setAlertTitle(data.MSG);
      onOpen();
    } else {
      setAlertStatus('error');
      setAlertTitle(data.MSG);
      onOpen();
    }
  }

  async function consultarNotaDominioContabil() {
    setLoading(true);
    let payload = {
      data: id,
      database: localStorage.getItem('database'),
    };
    let res = await api.post('/EditNFe/ConsultarNotaDominioContabil', payload);
    let data = res.data;
    setLoading(false);
    if (data.Success) {
      setAlertStatus('success');
      setAlertTitle(data.MSG);
      onOpen();
    } else {
      setAlertStatus('error');
      setAlertTitle(data.MSG);
      onOpen();
    }
  }

  async function duplicarNFe(values) {
    setLoading(true);
    let payload = {
      data: JSON.stringify(values, null, 2),
      database: localStorage.getItem('database'),
    };
    console.log(payload);
    let res = await api.post('/EditNFe/DuplicarNota', payload);
    let data = res.data;
    setLoading(false);
    if (data.Success) {
      setAlertStatus('success');
      setAlertTitle(data.MSG);
      onOpen();
      addIDtoURL(id, data.ItemID);
    } else {
      setAlertStatus('error');
      setAlertTitle(data.MSG);
      onOpen();
    }
  }
  return (
    <>
      <Alerta
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        alertStatus={alertStatus}
        title={alertTitle}
      />
      <Pagina loading={loading}>
        <FormikPadrao
          enableReinitialize={true}
          initialValues={NFe}
          validationSchema={yup.object({
            //   // Nome: yup.string('O campo é obrigatório').required('O campo é obrigatório').nullable(),
            //   // TipoConta: yup.string('O campo é obrigatório').required('O campo é obrigatório').nullable(),
            //   // CodigoBancoCorrespondente: yup.string('O campo é obrigatório').required('O campo é obrigatório').nullable(),
            //   // EmiteBoletos: yup.boolean('O campo é obrigatório').required('O campo é obrigatório').nullable(),
            //   // Empresa: yup.string('O campo é obrigatório').required('O campo é obrigatório').nullable(),
            //   // SaldoInicial: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório').nullable(),
            //   // Agencia: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório').nullable(),
            //   // DigitoAgencia: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório').nullable(),
            //   // Conta: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório').nullable(),
            //   // DigitoConta: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório').nullable(),
          })}
          onSubmit={async values => {
            let nfe = values;
            nfe.DadosNota.Produtos = nfe.Produtos;
            nfe.DadosNota.Transportadora.Volumes = nfe.Volumes;
            //delete nfe.Produtos;
            //delete nfe.Volumes;

            //Nome cliente
            nfe.DadosNota.Cliente.Nome = nfe.Cliente;
            //Nome município
            var municipio = document.getElementById(
              'DadosNota.Cliente.Endereco.CodigoIBGEMunicipio'
            ).options[
              document.getElementById(
                'DadosNota.Cliente.Endereco.CodigoIBGEMunicipio'
              ).selectedIndex
            ].text;
            if (isNotNullOrEmpty(municipio)) {
              nfe.DadosNota.Cliente.Endereco.CidadeEndereco = municipio.trim();
            }
            //Nome UF
            var estado = document.getElementById(
              'DadosNota.Cliente.Endereco.CodigoIBGEUF'
            ).options[
              document.getElementById('DadosNota.Cliente.Endereco.CodigoIBGEUF')
                .selectedIndex
            ].text;
            if (isNotNullOrEmpty(estado)) {
              nfe.DadosNota.Cliente.Endereco.UFEndereco = estado.trim();
            }
            //Nome Pais
            var pais = document.getElementById(
              'DadosNota.Cliente.Endereco.CodigoPais'
            ).options[
              document.getElementById('DadosNota.Cliente.Endereco.CodigoPais')
                .selectedIndex
            ].text;
            if (isNotNullOrEmpty(estado)) {
              nfe.DadosNota.Cliente.Endereco.Pais = pais.trim();
            }

            await save(JSON.stringify(nfe, null, 2));
          }}
        >
          <Interno
            navigate={navigate}
            loading={loading}
            setLoading={setLoading}
            consultarNFe={consultarNFe}
            gerarDanfe={gerarDanfe}
            emitirNFe={emitirNFe}
            enviarNotaDominioContabil={enviarNotaDominioContabil}
            consultarNotaDominioContabil={consultarNotaDominioContabil}
            duplicarNFe={duplicarNFe}
          />
        </FormikPadrao>
      </Pagina>
    </>
  );
}

function Interno({
  navigate,
  loading,
  setLoading,
  consultarNFe,
  gerarDanfe,
  emitirNFe,
  enviarNotaDominioContabil,
  consultarNotaDominioContabil,
  duplicarNFe,
}) {
  const { values, setFieldValue } = useFormikContext();
  let titulo = `${values.Id !== '' ? 'Editar' : 'Nova'} NFe`;
  const [optionsCidade, setOptionsCidade] = useState([]);

  useEffect(() => {
    const fetchCidades = async () => {
      try {
        const response = await fetch(
          `https://erpsuite-ibge.s3.us-east-2.amazonaws.com/${values.DadosNota.Cliente.Endereco.CodigoIBGEUF}.json`
        );
        const json = await response.json();
        const cidadesData = json.map(cidade => ({
          name: cidade.nome,
          value: cidade.id,
        }));
        setOptionsCidade(cidadesData);
      } catch (error) {
        console.error('Error fetching cidades:', error);
      }
    };

    fetchCidades();
  }, [values.DadosNota.Cliente.Endereco.CodigoIBGEUF]);

  async function handleAddProduto(nomeProduto) {
    let payload = {
      database: localStorage.getItem('database'),
      data: nomeProduto,
      arg: values.DadosNota.Cliente.Endereco.CodigoIBGEUF,
    };
    let res = await api.post('/EditNFe/GetProdutoComImpostos', payload);
    if (res.status === 200) {
      let data = res.data;
      console.log(res.data);
      addProdutosCarrinho(data.Produtos);
    } else {
      alert('Erro ao buscar dados');
    }
  }

  function addProdutosCarrinho(produto) {
    var achou = false;
    var produtos = values.Produtos;
    for (var i = 0; i < produtos.length; i++) {
      if (produtos[i].Nome === produto.Nome) {
        achou = true;
        produtos[i].Quantidade += 1;
      }
    }

    if (!achou) {
      var pnovo = {};
      pnovo.Produto = produto;
      pnovo.Quantidade = 1;
      produtos.push(pnovo);
    }
    setFieldValue('Produtos', produtos);
  }

  function removeProdutoCarrinho(produto) {
    var produtos = values.Produtos;
    console.log(produtos);
    for (var i = 0; i < produtos.length; i++) {
      if (produtos[i].Nome === produto.Nome) {
        produtos.splice(i, 1);
        break;
      }
    }
    setFieldValue('Produtos', produtos);
  }

  function addVolume() {
    var volumes = values.Volumes;

    volumes.push({
      qVol: 1,
      esp: '',
      marca: '',
      nVol: '1',
      pesoL: 0.0,
      pesoB: 0.0,
    });

    setFieldValue('Volumes', volumes);
  }

  function removeVolume(item, index) {
    console.log(item, index);
    var volumes = values.Volumes;
    volumes.splice(index, 1);
    setFieldValue('Volumes', volumes);
  }

  return (
    <>
      <BarraBotoes
        botoes={[
          {
            variant: 'vermelho',
            onClick: () => navigate('/NFe/Listar'),
            children: 'Cancelar',
          },
          {
            variant: 'turquesa',
            onClick: () => gerarDanfe(values),
            visible:
              values.StatusSefaz.includes('Autorizado o uso') ||
              values.StatusSefaz.includes('denegad'),
            children: 'Gerar DANFE',
          },
          {
            variant: 'turquesa',
            onClick: () =>
              consultarNFe(
                values.NFeId,
                values.DadosNota.Loja.NumeroDocumento,
                values.NFeXMLEnviado
              ),
            visible: true,
            children: 'Consultar Status NFe',
          },
          {
            variant: 'turquesa',
            onClick: () => emitirNFe(values),
            visible: !(
              values.StatusSefaz.includes('Autorizado o uso') ||
              values.Id == null ||
              values.StatusSefaz.includes('Cancel')
            ),
            children: 'Emitir NFe',
          },
          {
            variant: 'turquesa',
            onClick: () => null,
            visible: !(
              values.StatusSefaz.includes('Autorizado o uso') ||
              values.StatusSefaz.includes('Cancel')
            ),
            type: 'submit',
            children: 'Salvar',
          },
          {
            variant: 'turquesa',
            onClick: () => null,
            visible:
              values.StatusSefaz.includes('Autorizado o uso') ||
              values.StatusSefaz.includes('denegad'),
            children: 'Cancelar NFe',
          },
          {
            variant: 'turquesa',
            onClick: () => null,
            visible: values.StatusSefaz.includes('Autorizado o uso'),
            children: 'Enviar Carta de Correção',
          },
          {
            variant: 'turquesa',
            onClick: () => enviarNotaDominioContabil(),
            visible: !(
              !values.StatusSefaz.includes('Autorizado o uso') ||
              values.ChaveIntegracaoDominioContabil === '' ||
              values.IdXMLDominioContabil !== ''
            ),
            children: 'Enviar XML para Domínio Contábil',
          },
          {
            variant: 'turquesa',
            onClick: () => consultarNotaDominioContabil(),
            visible: !(
              !values.StatusSefaz.includes('Autorizado o uso') ||
              values.ChaveIntegracaoDominioContabil === '' ||
              values.IdXMLDominioContabil === ''
            ),
            children: 'Consultar Status XML Domínio Contábil',
          },
          {
            variant: 'turquesa',
            onClick: () => duplicarNFe(values),
            visible: !(values.Id === null),
            children: 'Duplicar NFe',
          },
        ]}
        caminho={[{ nome: 'NFes', path: '/NFe/Listar' }, { nome: titulo }]}
      />
      <Card titulo={titulo}>
        {values.CodigoRetornoSefaz !== 0 && (
          <>
            <InputFormik
              titulo="Código Retorno Sefaz"
              id="CodigoRetornoSefaz"
              name="CodigoRetornoSefaz"
              component={InputTexto}
              gridColumn={gridColumn.gc4}
              disabled
            />
            <InputFormik
              titulo="Chave de Acesso"
              id="NFeId"
              name="NFeId"
              component={InputTexto}
              gridColumn={gridColumn.gc4}
            />
            <InputFormik
              titulo="Último Status Sefaz"
              id="StatusSefaz"
              name="StatusSefaz"
              component={InputTexto}
              gridColumn={gridColumn.gc4}
            />
          </>
        )}

        <InputFormik
          titulo="Natureza da Operação"
          id="DadosNota.Nota.NaturezaDaOperacao"
          name="DadosNota.Nota.NaturezaDaOperacao"
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />
        <InputFormik
          titulo="Finalidade"
          id="DadosNota.Nota.FinalidadeNF"
          name="DadosNota.Nota.FinalidadeNF"
          component={InputSelect}
          options={optionsFinalidade}
          gridColumn={gridColumn.gc4}
        />
        <InputFormik
          titulo="Precisa destacar Impostos?"
          id="DadosNota.Nota.PrecisaDestacarImpostos"
          name="DadosNota.Nota.PrecisaDestacarImpostos"
          component={InputToggle}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo="Número"
          id="NumeroNFe"
          name="NumeroNFe"
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo="Série"
          id="SerieNFe"
          name="SerieNFe"
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo="Tipo"
          id="DadosNota.Nota.TipoNF"
          name="DadosNota.Nota.TipoNF"
          component={InputSelect}
          options={optionsTipoNFe}
          gridColumn={gridColumn.gc4}
        />
        {(values.DadosNota.Nota.FinalidadeNF === 4 ||
          values.DadosNota.Nota.FinalidadeNF === 2) && (
          <InputFormik
            titulo="Chave de Acesso Referenciada"
            id="DadosNota.Nota.ChaveAcessoReferenciada"
            name="DadosNota.Nota.ChaveAcessoReferenciada"
            component={InputTexto}
            gridColumn={gridColumn.gc4}
          />
        )}

        <InputFormik
          titulo="Empresa"
          id="Emissor"
          name="Emissor"
          component={InputAutoComplete}
          gridColumn={gridColumn.gc4}
          path="Emissor"
          autocomplete={true}
          database={localStorage.getItem('database')}
        />

        <InputFormik
          titulo="CFOP"
          id="CFOP"
          name="CFOP"
          component={InputAutoComplete}
          gridColumn={gridColumn.gc4}
          path="CFOP"
          autocomplete={true}
          database={localStorage.getItem('database')}
        />

        <InputFormik
          titulo="CSOSN"
          id="CSOSN"
          name="CSOSN"
          component={InputSelect}
          options={optionsCSOSN}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo="Cliente"
          id="Cliente"
          name="Cliente"
          component={InputAutoComplete}
          gridColumn={gridColumn.gc4}
          path="Cliente"
          autocomplete={true}
          database={localStorage.getItem('database')}
        />

        <InputFormik
          titulo="CPF/CNPJ"
          id="DadosNota.Cliente.NumeroDocumento"
          name="DadosNota.Cliente.NumeroDocumento"
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo="Inscr. Estadual"
          id="DadosNota.Cliente.IE"
          name="DadosNota.Cliente.IE"
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo="Indicador IE"
          id="DadosNota.Cliente.IndicadorIE"
          name="DadosNota.Cliente.IndicadorIE"
          component={InputSelect}
          options={optionsIndicadorIE}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo="Telefone"
          id="DadosNota.Cliente.Telefone"
          name="DadosNota.Cliente.Telefone"
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo="CEP"
          id="DadosNota.Cliente.Endereco.CEP"
          name="DadosNota.Cliente.Endereco.CEP"
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo="Rua"
          id="DadosNota.Cliente.Endereco.Logradouro"
          name="DadosNota.Cliente.Endereco.Logradouro"
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo="Número"
          id="DadosNota.Cliente.Endereco.Numero"
          name="DadosNota.Cliente.Endereco.Numero"
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo="Bairro"
          id="DadosNota.Cliente.Endereco.Bairro"
          name="DadosNota.Cliente.Endereco.Bairro"
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo="Cidade"
          id="DadosNota.Cliente.Endereco.CodigoIBGEMunicipio"
          name="DadosNota.Cliente.Endereco.CodigoIBGEMunicipio"
          component={InputSelect}
          options={optionsCidade}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo="Estado"
          id="DadosNota.Cliente.Endereco.CodigoIBGEUF"
          name="DadosNota.Cliente.Endereco.CodigoIBGEUF"
          component={InputSelect}
          options={optionsUFs}
          gridColumn={gridColumn.gc4}
        />
        <InputFormik
          titulo="País"
          id="DadosNota.Cliente.Endereco.CodigoPais"
          name="DadosNota.Cliente.Endereco.CodigoPais"
          component={InputSelect}
          options={optionsPaises}
          gridColumn={gridColumn.gc4}
        />
        {values.DadosNota.Cliente.Endereco.CodigoPais &&
          values.DadosNota.Cliente.Endereco.CodigoPais !== '1058' && (
            <>
              <InputFormik
                titulo="Estado Exportação"
                id="DadosNota.Cliente.Nota.UFExportacao"
                name="DadosNota.Cliente.Nota.UFExportacao"
                component={InputSelect}
                options={optionsUFsExportacao}
                gridColumn={gridColumn.gc4}
              />
              <InputFormik
                titulo="Local Exportação"
                id="DadosNota.Cliente.Nota.LocalExportacao"
                name="DadosNota.Cliente.Nota.LocalExportacao"
                component={InputTexto}
                gridColumn={gridColumn.gc4}
              />
            </>
          )}
        <InputFormik
          titulo="Consumidor Final?"
          id="ConsumidorFinal"
          name="ConsumidorFinal"
          component={InputToggle}
          gridColumn={gridColumn.gc4}
        />
        <InputFormik
          titulo="Pessoa Física?"
          id="DadosNota.Cliente.PessoaFisica"
          name="DadosNota.Cliente.PessoaFisica"
          component={InputToggle}
          gridColumn={gridColumn.gc4}
        />
      </Card>
      <Card titulo={'Informações sobre o frete'}>
        <InputFormik
          titulo="Frete"
          id="DadosNota.Transportadora.Modalidade"
          name="DadosNota.Transportadora.Modalidade"
          component={InputSelect}
          options={optionsFrete}
          gridColumn={gridColumn.gc4}
        />
        {values.DadosNota.Transportadora.Modalidade !== '9' && (
          <InputFormik
            titulo="Frete feito por transportadora?"
            id="DadosNota.Transportadora.TemTransportadora"
            name="DadosNota.Transportadora.TemTransportadora"
            component={InputToggle}
            gridColumn={gridColumn.gc4}
          />
        )}
        {values.DadosNota.Transportadora.TemTransportadora && (
          <>
            <InputFormik
              titulo="Transportadora"
              id="DadosNota.Transportadora.Nome"
              name="DadosNota.Transportadora.Nome"
              component={InputAutoComplete}
              gridColumn={gridColumn.gc4}
              path="Transportadora"
              autocomplete={true}
              database={localStorage.getItem('database')}
            />
            <InputFormik
              titulo="CPF/CNPJ"
              id="DadosNota.Transportadora.NumeroDocumento"
              name="DadosNota.Transportadora.NumeroDocumento"
              component={InputTexto}
              gridColumn={gridColumn.gc4}
            />
            <InputFormik
              titulo="Inscr. Estadual"
              id="DadosNota.Transportadora.IE"
              name="DadosNota.Transportadora.IE"
              component={InputTexto}
              gridColumn={gridColumn.gc4}
            />
            <InputFormik
              titulo="CEP"
              id="DadosNota.Transportadora.Endereco.CEP"
              name="DadosNota.Transportadora.Endereco.CEP"
              component={InputTexto}
              gridColumn={gridColumn.gc4}
            />
            <InputFormik
              titulo="Rua"
              id="DadosNota.Transportadora.Endereco.Logradouro"
              name="DadosNota.Transportadora.Endereco.Logradouro"
              component={InputTexto}
              gridColumn={gridColumn.gc4}
            />
            <InputFormik
              titulo="Número"
              id="DadosNota.Transportadora.Endereco.Numero"
              name="DadosNota.Transportadora.Endereco.Numero"
              component={InputTexto}
              gridColumn={gridColumn.gc4}
            />
            <InputFormik
              titulo="Bairro"
              id="DadosNota.Transportadora.Endereco.Bairro"
              name="DadosNota.Transportadora.Endereco.Bairro"
              component={InputTexto}
              gridColumn={gridColumn.gc4}
            />
            <InputFormik
              titulo="Cidade"
              id="DadosNota.Transportadora.Endereco.Municipio"
              name="DadosNota.Transportadora.Endereco.Municipio"
              component={InputTexto}
              gridColumn={gridColumn.gc4}
            />
            <InputFormik
              titulo="UF"
              id="DadosNota.Transportadora.Endereco.UF"
              name="DadosNota.Transportadora.Endereco.UF"
              component={InputTexto}
              gridColumn={gridColumn.gc4}
            />
            <InputFormik
              titulo="Pessoa Física?"
              id="DadosNota.Transportadora.PessoaFisica"
              name="DadosNota.Transportadora.PessoaFisica"
              component={InputToggle}
              gridColumn={gridColumn.gc4}
            />
            <Botao type="button" variant="verde" onClick={() => addVolume()}>
              Adicionar Volume
            </Botao>
          </>
        )}

        {values.Volumes.length > 0 && (
          <TabelaInterna
            nomeLista="Volumes"
            columns={[
              {
                placeholder: 'Quantidade',
                name: 'qVol',
                type: 'number',
                editavel: true,
              },
              {
                placeholder: 'Espécie',
                name: 'esp',
                type: 'text',
                editavel: true,
              },
              {
                placeholder: 'Marca',
                name: 'marca',
                type: 'text',
                editavel: true,
              },
              {
                placeholder: 'Numeração',
                name: 'nVol',
                type: 'text',
                editavel: true,
              },
              {
                placeholder: 'Peso Líquido',
                name: 'pesoL',
                type: 'float',
                editavel: true,
              },
              {
                placeholder: 'Peso Bruto',
                name: 'pesoB',
                type: 'float',
                editavel: true,
              },
            ]}
            showDelete={true}
            handleDelete={removeVolume}
          />
        )}
        {/* <InputFormik
          titulo="RNTC"
          id="DadosNota.Transportadora.CodigoANTT"
          name="DadosNota.Transportadora.CodigoANTT"
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />
        <InputFormik
          titulo="Placa"
          id="DadosNota.Transportadora.PlacaVeiculo"
          name="DadosNota.Transportadora.PlacaVeiculo"
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />
        <InputFormik
          titulo="UF"
          id="DadosNota.Transportadora.VeiculoUF"
          name="DadosNota.Transportadora.VeiculoUF"
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />
        <InputFormik
          titulo="Cód. Volumes"
          id="Cliente"
          name="Cliente"
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        /> */}
      </Card>
      <Card titulo={'Informações sobre os produtos'}>
        <PesquisaProdutos funcao={handleAddProduto} />
        <TabelaInterna
          nomeLista="Produtos"
          columns={[
            {
              placeholder: 'Item',
              name: 'Nome',
              type: 'text',
            },
            {
              placeholder: 'Quantidade',
              name: 'Quantidade',
              type: 'number',
              editavel: true,
            },
            // {
            //   placeholder: 'Peso',
            //   name: 'PesoKG',
            //   type: 'float',
            // },
            {
              placeholder: 'Valor (R$)',
              name: 'ValorUnitario',
              type: 'money',
              editavel: true,
            },
            {
              placeholder: 'Frete (R$)',
              name: 'ValorFreteUnitario',
              type: 'money',
              editavel: true,
            },
            {
              placeholder: 'Outras despesas (R$)',
              name: 'ValorOutrasDespesas',
              type: 'money',
              editavel: true,
            },
            {
              placeholder: 'Desconto (R$)',
              name: 'DescontoTotal',
              type: 'money',
              editavel: true,
            },
            {
              placeholder: 'SubTotal (R$)',
              name: 'ValorTotal',
              type: 'money',
              editavel: true,
            },
          ]}
          hiddenColumns={[
            {
              placeholder: 'Chave NFe',
              name: 'chNFeExportacao',
              type: 'text',
              visible: true,
              editavel: true,
            },
            {
              placeholder: 'NCM',
              name: 'NCM',
              type: 'text',
              visible: true,
              editavel: true,
            },
            {
              placeholder: 'CEST',
              name: 'CEST',
              type: 'text',
              visible: true,
              editavel: true,
              maxlength: 8,
            },
            {
              placeholder: 'CFOP',
              name: 'CFOP',
              type: 'text',
              visible: true,
              editavel: true,
              maxlength: 4,
            },
            {
              placeholder: 'Unidade Comercial',
              name: 'UnidadeComercial',
              type: 'text',
              visible: true,
              editavel: true,
            },
            {
              placeholder: 'CSOSN',
              name: 'CSOSN',
              type: 'select',
              visible: !values.SimplesNacional,
              editavel: true,
              options: [
                {
                  value: '101',
                  label:
                    '101 - Tributada pelo Simples Nacional com permissão de crédito de ICMS',
                },
                {
                  value: '102',
                  label:
                    '102 - Tributada pelo Simples Nacional sem permissão de crédito',
                },
                {
                  value: '103',
                  label:
                    '103 - Isenção de ICMS no Simples Nacional na faixa de receita bruta',
                },
                {
                  value: '201',
                  label:
                    '201 - Tributada pelo Simples Nacional com permissão de crédito e cobrança do ICMS por ST',
                },
                {
                  value: '202',
                  label:
                    '202 - Tributada pelo Simples Nacional sem permissão de crédito e com cobrança do ICMS por ST',
                },
                {
                  value: '203',
                  label:
                    '203 - Isenção do ICMS no Simples Nacional para faixa de receita bruta e cobrança de ICMS por ST',
                },
                { value: '300', label: '300 - Imune de ICMS' },
                {
                  value: '400',
                  label: '400 - Não tributada pelo Simples Nacional',
                },
                {
                  value: '500',
                  label:
                    '500 - ICMS cobrado anteriormente por ST ou por antecipação',
                },
                { value: '900', label: '900 - Outros' },
              ],
            },
            {
              placeholder: 'X-PED',
              name: 'xPed',
              type: 'text',
              visible: true,
              editavel: true,
            },
            {
              placeholder: 'X-ITEM',
              name: 'nItemPed',
              type: 'number',
              visible: true,
              editavel: true,
            },
            {
              placeholder: 'Informação Adicional',
              name: 'InformacaoAdicional',
              type: 'text',
              visible: true,
              editavel: true,
            },
            {
              placeholder: 'Base ICMS reduzida?',
              name: 'Impostos.ICMS.BaseReduzida',
              type: 'toggle',
              visible: true,
              editavel: true,
            },
            {
              placeholder: 'Alíquota Base ICMS(%)',
              name: 'Impostos.ICMS.AliquotaBaseICMS',
              type: 'float',
              visible: true,
              editavel: true,
            },
            {
              placeholder: 'Alíquota Reduzida ICMS(%)',
              name: 'Impostos.ICMS.AliquotaAlvoICMS',
              type: 'float',
              visible: true,
              editavel: true,
            },
            {
              placeholder: 'Situação IPI',
              name: 'Impostos.IPI.CST_Ipi',
              type: 'select',
              visible: true,
              editavel: true,
              options: [
                {
                  value: '0',
                  label: '00 - Entrada com Recuperação de Crédito',
                },
                {
                  value: '4',
                  label: '01 - Entrada Tributável com Alíquota Zero',
                },
                { value: '5', label: '02 - Entrada Isenta' },
                { value: '6', label: '03 - Entrada Não-Tributada' },
                { value: '7', label: '04 - Entrada Imune' },
                { value: '8', label: '05 - Entrada com Suspensão' },
                { value: '1', label: '49 - Outras Entradas' },
                { value: '2', label: '50 - Saída Tributada' },
                {
                  value: '9',
                  label: '51 - Saída Tributável com Alíquota Zero',
                },
                { value: '10', label: '52 - Saida Isenta' },
                { value: '11', label: '53 - Saída Não-Tributada' },
                { value: '12', label: '54 - Saída Imune' },
                { value: '13', label: '55 - Saída com Suspensão' },
                { value: '3', label: '99 - Outras Saídas' },
              ],
            },
            {
              placeholder: 'IPI (%)',
              name: 'Impostos.IPI.Aliquota',
              type: 'float',
              visible: true,
              editavel: true,
            },
            {
              placeholder: 'Código de Enquadramento IPI',
              name: 'Impostos.IPI.EnquadramentoIPI',
              type: 'number',
              visible: true,
              editavel: true,
            },
            {
              placeholder: 'Situação ICMS',
              name: 'Impostos.ICMS.ICMSCST',
              type: 'select',
              visible: true,
              editavel: true,
              options: [
                { value: '0', label: '00 - Tributada integralmente' },
                {
                  value: '1',
                  label:
                    '10 - Tributada e com cobrança do ICMS por substituição tributária',
                },
                { value: '3', label: '20 - Com redução de base de cálculo' },
                {
                  value: '4',
                  label:
                    '30 - Isenta ou não tributada e com cobrança do ICMS por substituição tributária',
                },
                { value: '5', label: '40 - Isenta' },
                { value: '6', label: '41 - Não tributada' },
                { value: '8', label: '50 - Suspensão' },
                { value: '9', label: '51 - Diferimento' },
                {
                  value: '10',
                  label:
                    '60 - ICMS cobrado anteriormente por substituição tributária',
                },
                {
                  value: '12',
                  label:
                    '70 - Com redução de base de cálculo e cobrança do ICMS por substituição tributária',
                },
                { value: '13', label: '90 - Outros' },
              ],
            },
            {
              placeholder: 'Base de cálculo ICMS (R$)',
              name: 'Impostos.ICMS.ValorBaseCalculo',
              type: 'float',
              visible: true,
              editavel: true,
            },
            {
              placeholder: 'ICMS (R$)',
              name: 'Impostos.ICMS.ValorTotal',
              type: 'money',
              visible: true,
              editavel: true,
            },
            {
              placeholder: 'ICMS Alíquota(%)',
              name: 'Impostos.ICMS.Aliquota',
              type: 'float',
              visible: true,
              editavel: true,
            },
            {
              placeholder: 'Situação PIS',
              name: 'Impostos.PIS.CST_Pis',
              type: 'select',
              visible: true,
              editavel: true,
              options: [
                {
                  value: '1',
                  label: '01 - Operação Tributável com Alíquota Básica',
                },
                {
                  value: '2',
                  label: '02 - Operação Tributável com Alíquota Diferenciada',
                },
                {
                  value: '3',
                  label:
                    '03 - Operação Tributável com Alíquota por Unidade de Medida de Produto',
                },
                {
                  value: '4',
                  label:
                    '04 - Operação Tributável Monofásica - Revenda a Alíquota Zero',
                },
                {
                  value: '6',
                  label: '06 - Operação Tributável a Alíquota Zero',
                },
                { value: '7', label: '07 - Operação Isenta da Contribuição' },
                {
                  value: '8',
                  label: '08 - Operação sem Incidência da Contribuição',
                },
                {
                  value: '9',
                  label: '09 - Operação com Suspensão da Contribuição',
                },
                { value: '49', label: '49 - Outras Operações de Saída' },
                {
                  value: '51',
                  label:
                    '51 - Operação com Direito a Crédito - Vinculada Exclusivamente a Receita Não-Tributada no Mercado Interno',
                },
                {
                  value: '52',
                  label:
                    '52 - Operação com Direito a Crédito - Vinculada Exclusivamente a Receita de Exportação',
                },
                {
                  value: '53',
                  label:
                    '53 - Operação com Direito a Crédito - Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno',
                },
                {
                  value: '54',
                  label:
                    '54 - Operação com Direito a Crédito - Vinculada a Receitas Tributadas no Mercado Interno e de Exportação',
                },
                {
                  value: '55',
                  label:
                    '55 - Operação com Direito a Crédito - Vinculada a Receitas Não Tributadas no Mercado Interno e de Exportação',
                },
                {
                  value: '56',
                  label:
                    '56 - Operação com Direito a Crédito - Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno e de Exportação',
                },
                { value: '99', label: '99 - Outras Operações' },
              ],
            },
            {
              placeholder: 'PIS (%)',
              name: 'Impostos.PIS.Aliquota',
              type: 'float',
              visible: true,
              editavel: true,
            },
            {
              placeholder: 'Situação COFINS',
              name: 'Impostos.COFINS.CST_Cofins',
              type: 'select',
              visible: true,
              editavel: true,
              options: [
                {
                  value: '1',
                  label: '01 - Operação Tributável com Alíquota Básica',
                },
                {
                  value: '2',
                  label: '02 - Operação Tributável com Alíquota Diferenciada',
                },
                {
                  value: '3',
                  label:
                    '03 - Operação Tributável com Alíquota por Unidade de Medida de Produto',
                },
                {
                  value: '4',
                  label:
                    '04 - Operação Tributável Monofásica - Revenda a Alíquota Zero',
                },
                {
                  value: '6',
                  label: '06 - Operação Tributável a Alíquota Zero',
                },
                { value: '7', label: '07 - Operação Isenta da Contribuição' },
                {
                  value: '8',
                  label: '08 - Operação sem Incidência da Contribuição',
                },
                {
                  value: '9',
                  label: '09 - Operação com Suspensão da Contribuição',
                },
                { value: '49', label: '49 - Outras Operações de Saída' },
                {
                  value: '50',
                  label:
                    '50 - Operação com Direito a Crédito - Vinculada Exclusivamente a Receita Tributada no Mercado Interno',
                },
                {
                  value: '51',
                  label:
                    '51 - Operação com Direito a Crédito - Vinculada Exclusivamente a Receita Não-Tributada no Mercado Interno',
                },
                {
                  value: '52',
                  label:
                    '52 - Operação com Direito a Crédito - Vinculada Exclusivamente a Receita de Exportação',
                },
                {
                  value: '53',
                  label:
                    '53 - Operação com Direito a Crédito - Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno',
                },
                {
                  value: '54',
                  label:
                    '54 - Operação com Direito a Crédito - Vinculada a Receitas Tributadas no Mercado Interno e de Exportação',
                },
                {
                  value: '55',
                  label:
                    '55 - Operação com Direito a Crédito - Vinculada a Receitas Não Tributadas no Mercado Interno e de Exportação',
                },
                {
                  value: '56',
                  label:
                    '56 - Operação com Direito a Crédito - Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno e de Exportação',
                },
                { value: '99', label: '99 - Outras Operações' },
              ],
            },
            {
              placeholder: 'COFINS (%)',
              name: 'Impostos.COFINS.Aliquota',
              type: 'float',
              visible: true,
              editavel: true,
            },
          ]}
          showDelete={true}
          handleDelete={removeProdutoCarrinho}
        />
      </Card>
      <Card titulo={'Informações de pagamento'}>
        <InputFormik
          titulo="Forma de Pagamento"
          id="DadosNota.Pagamento.FormaPagamento"
          name="DadosNota.Pagamento.FormaPagamento"
          component={InputSelect}
          options={optionsFormaPagamento}
          gridColumn={gridColumn.gc4}
        />
        <InputFormik
          titulo="Tipo de Pagamento"
          id="DadosNota.Pagamento.Tipo"
          name="DadosNota.Pagamento.Tipo"
          component={InputSelect}
          options={optionsTipoPagamento}
          gridColumn={gridColumn.gc4}
        />
        {values.DadosNota.Pagamento.Tipo !== 'A vista' && (
          <>
            <InputFormik
              titulo="Intervalo entre parcelas(dias)"
              id="DadosNota.Pagamento.IntervaloDiasParcelas"
              name="DadosNota.Pagamento.IntervaloDiasParcelas"
              component={InputNumInt}
              gridColumn={gridColumn.gc4}
            />
            <InputFormik
              titulo="Quantidade de parcelas"
              id="DadosNota.Pagamento.QuantidadeParcelas"
              name="DadosNota.Pagamento.QuantidadeParcelas"
              component={InputNumInt}
              gridColumn={gridColumn.gc4}
            />
          </>
        )}
      </Card>
      <Card titulo={'Informações sobre a nota'}>
        <InputFormik
          titulo="Observações Obrigatórias"
          id="DadosNota.Nota.InformacoesComplementares"
          name="DadosNota.Nota.InformacoesComplementares"
          component={InputTextArea}
          gridColumn={gridColumn.gc12}
          disabled
        />
        <InputFormik
          titulo="Observações Complementares"
          id="DadosNota.Nota.InformacoesComplementares"
          name="DadosNota.Nota.InformacoesComplementares"
          component={InputTextArea}
          gridColumn={gridColumn.gc12}
        />
        <Box gridColumn={gridColumn.gc12}>
          <Text>
            * O Valor Aproximado dos Tributos é referente a Lei da Transparência
            12.741/2012, que estabelece que ao vender para consumidor final, os
            valores aproximados dos tributos precisam estar descriminados no
            documento fiscal. O valor aproximado do campo acima é meramente
            informativo."
          </Text>
        </Box>
      </Card>
    </>
  );
}

export default EditarNFe;
