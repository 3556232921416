import React, { useEffect, useState } from 'react';
import '../../App.css';
import Pagina from '../../components/Pagina/Pagina';
import api from '../../services/api';
import FormikPadrao from '../../components/FormikPadrao/FormikPadrao';
import BarraBotoes from '../../components/BarraBotoes/BarraBotoes';
import Tabela from '../../components/Tabela/Tabela';
import { baixarArquivoBase64 } from '../../utils/download';

function RelClientes() {
  const [relClientes, setRelClientes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchBar, setSearchBar] = useState('');

  useEffect(() => {
    async function GetRelClientes() {
      let payload = {
        database: localStorage.getItem('database'),
        data: '{}',
      };
      let res = await api.post('/ClientesReport/GetList', payload);

      if (res.status === 200) {
        let data = res.data;
        setRelClientes(data.Clientes);
        setLoading(false);
      } else {
        alert('Erro ao buscar dados');
      }
    }
    GetRelClientes();
  }, []);

  const getPDF = async () => {
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(relClientes, null, 2),
    };
    baixarArquivoBase64('/ClientesReport/GetPDF', payload);
  };

  const getExcel = async () => {
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(relClientes, null, 2),
    };
    baixarArquivoBase64('/ClientesReport/GetExcel', payload);
  };

  return (
    <Pagina loading={loading}>
      <FormikPadrao>
        <BarraBotoes
          maxWindowSize={1030}
          botoes={[
            {
              children: 'Exportar Planilha',
              onClick: getExcel,
              variant: 'verde',
            },
            {
              children: 'Exportar PDF',
              onClick: getPDF,
              variant: 'rosa',
            }
          ]}
          search={{
            placeholder: 'Procurar por clientes',
            onChange: e => setSearchBar(e.target.value),
          }}
        />
        <Tabela
          titulo={'RELATÓRIO DE CLIENTES'}
          setFunction={setRelClientes}
          data={relClientes}
          columns={[
            {
              placeholder: 'NOME FANTASIA',
              name: 'NomeFantasia',
              type: 'text',
            },
            {
              placeholder: 'RAZÃO SOCIAL',
              name: 'RazaoSocial',
              type: 'text',
            },
            {
              placeholder: 'CPF/CNPJ',
              name: 'CPF_CNPJ',
              type: 'text',
            },
            {
              placeholder: 'TELEFONE',
              name: 'Telefone',
              type: 'text',
            },
            {
              placeholder: 'CIDADE',
              name: 'CidadeEndereco',
              type: 'text',
            },
            {
              placeholder: 'ESTADO',
              name: 'UFEndereco',
              type: 'text',
            },
          ]}
          searchBar={searchBar}
        />
      </FormikPadrao>
    </Pagina>
  );
}

export default RelClientes;
