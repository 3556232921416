import { Input } from '@chakra-ui/react';
import React from 'react';
import './InputPassword.css';

function InputPassword({ placeholder, ...rest }) {
  
  return (
    <Input
      className="inputpassword"
      type="password"
      placeholder={placeholder}
      {...rest}
    />
  );
}

export default InputPassword;
