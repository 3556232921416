import { Box, Input } from '@chakra-ui/react';
import React from 'react';
import '../../App.css';
import BarraBotoes from '../../components/BarraBotoes/BarraBotoes';
import Card from '../../components/Card/Card';
import FormPadrao from '../../components/FormPadrao/FormPadrao';
import Pagina from '../../components/Pagina/Pagina';
import api from '../../services/api';
import { gridColumn } from '../../utils/gridColumn';
import Botao from '../Botao/Botao';

import './Importacao.css';
import { excel_base_cliente } from './arquivos_base';

function GetData(tipo) {
  switch (tipo) {
    case 'Lancamentos':
      return {
        titulo: 'Lançamentos',
        frases: [
          'Controle os lançamentos das suas empresas.',
          'Controle suas receitas e despesas.',
          'Importe os lançamentos e alimente seu sistema.',
        ],
        tituloBotao: 'Novo Lançamento',
        funcaoBaixar: async () => {
          let payload = {
            database: localStorage.getItem('database'),
          };
          let res = await api.post('/EditLancamentos/CreateExcel', payload);
          let data = res.data;

          const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${data.csv}`;
          const downloadLink = document.createElement('a');
          const fileName = 'Lançamentos.xlsx';

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        },
      };
    case 'Produtos':
      return {
        titulo: 'Produtos',
        frases: [
          'Controle os produtos das suas empresas.',
          'Controle os saldos dos produtos comprados.',
          'Importe os produtos e alimente seu sistema.',
        ],
        tituloBotao: 'Novo Produto',
        funcaoBaixar: async () => {
          let payload = {
            database: localStorage.getItem('database'),
          };
          let res = await api.post('/EditProdutos/CreateExcel', payload);
          let data = res.data;

          const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${data.csv}`;
          const downloadLink = document.createElement('a');
          const fileName = 'produtos.xlsx';

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        },
      };
    case 'Serviços':
      return {
        titulo: 'Serviços',
        frases: [
          'Controle os serviços das suas empresas.',
          'Controle os serviços.',
          'Importe os serviços e alimente seu sistema.',
        ],
        tituloBotao: 'Novo Serviço',
        funcaoBaixar: async () => {
          let payload = {
            database: localStorage.getItem('database'),
          };
          let res = await api.post('/EditProdutos/CreateExcel', payload);
          let data = res.data;

          const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${data.csv}`;
          const downloadLink = document.createElement('a');
          const fileName = 'servicos.xlsx';

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        },
      };
    case 'Clientes':
      return {
        titulo: 'Clientes',
        frases: [
          'Controle os clientes das suas empresas.',
          'Centralize as informações dos seus clientes em um só lugar.',
          'Importe seus clientes e alimente seu sistema.',
        ],
        tituloBotao: 'Novo Cliente',
        funcaoBaixar: () => {
          const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${excel_base_cliente}`;
          const downloadLink = document.createElement('a');
          const fileName = 'clientes.xlsx';

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        },
      };
    case 'Fornecedores':
      return {
        titulo: 'Fornecedores',
        frases: [
          'Controle os fornecedores das suas empresas.',
          'Centralize as informações dos seus fornecedores em um só lugar.',
          'Importe seus fornecedores e alimente seu sistema.',
        ],
        tituloBotao: 'Novo Cliente',
        funcaoBaixar: () => {
          const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${excel_base_cliente}`;
          const downloadLink = document.createElement('a');
          const fileName = 'fornecedores.xlsx';

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        },
      };
    case 'Transportadoras':
      return {
        titulo: 'Transportadoras',
        frases: [
          'Controle as transportadoras das suas empresas.',
          'Centralize as informações das suas transportadoras em um só lugar.',
          'Importe suas transportadoras e alimente seu sistema.',
        ],
        tituloBotao: 'Novo Cliente',
        funcaoBaixar: () => {
          const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${excel_base_cliente}`;
          const downloadLink = document.createElement('a');
          const fileName = 'transportadoras.xlsx';

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        },
      };
    case 'Funcionarios':
      return {
        titulo: 'Funcionários',
        frases: [
          'Controle os funcionários das suas empresas.',
          'Centralize as informações dos seus funcionários em um só lugar.',
          'Importe seus funcionários e alimente seu sistema.',
        ],
        tituloBotao: 'Novo Cliente',
        funcaoBaixar: () => {
          const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${excel_base_cliente}`;
          const downloadLink = document.createElement('a');
          const fileName = 'funcionarios.xlsx';

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        },
      };
    case 'Vendedores':
      return {
        titulo: 'Vendedores',
        frases: [
          'Controle os vendedores das suas empresas.',
          'Centralize as informações dos seus vendedores em um só lugar.',
          'Importe seus vendedores e alimente seu sistema.',
        ],
        tituloBotao: 'Novo Cliente',
        funcaoBaixar: () => {
          const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${excel_base_cliente}`;
          const downloadLink = document.createElement('a');
          const fileName = 'vendedores.xlsx';

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        },
      };
    default:
      return {};
  }
}

function Importacao({ loading, tipo, funcaoVoltar, funcaoNovo, funcaoUpload }) {
  const data = GetData(tipo);
  const fileInput = React.createRef();

  const triggerInputFile = () => {
    if (fileInput.current != undefined && fileInput.current.click != undefined)
      fileInput.current.click();
  };

  return (
    <Pagina loading={loading}>
      <FormPadrao>
        <BarraBotoes
          botoes={[
            {
              children: data.tituloBotao,
              onClick: () => funcaoNovo(),
              variant: 'turquesa',
            },
            {
              children: 'Voltar',
              onClick: () => funcaoVoltar(),
              variant: 'verde',
            },
          ]}
        />
        <Card titulo={data.titulo} className="cardPagina">
          <Box p={2} gridColumn={gridColumn.gc12} className="conteudoCard">
            <p>{data.frases[0]}</p>
            <p>{data.frases[1]}</p>
            <p>{data.frases[2]}</p>
          </Box>
        </Card>
        <Card titulo="Instruções" className="cardInstrucoes">
          <Box p={2} gridColumn={gridColumn.gc12} className="conteudoCard">
            <p>Clique no botão abaixo para baixar uma planilha modelo.</p>
            <Botao
              variant="amarelo"
              type={'button'}
              onClick={() => {
                data.funcaoBaixar();
              }}
            >
              Baixar Planilha
            </Botao>
            <ol>
              <li>
                Abra a planilha e insira as informações nas colunas
                correspondentes, sem modificar o cabeçalho.
              </li>
              <li>Em seguida salve sua planilha no seu computador.</li>
              <li>
                Clique no botão abaixo e localize a planilha no seu computador.
              </li>
              <li>Aguarde a confirmaçao de importação.</li>
              <li>
                Pronto! Os dados da planilha foram importados para o sistema.
              </li>
            </ol>
            <Botao
              variant="amarelo"
              type={'button'}
              onClick={() => triggerInputFile()}
            >
              Importar Planilha
            </Botao>
            <Input
              ref={fileInput}
              type="file"
              onChange={funcaoUpload}
              style={{ display: 'none' }}
            />
          </Box>
        </Card>
      </FormPadrao>
    </Pagina>
  );
}

export default Importacao;
