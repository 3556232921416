import { useDisclosure } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import '../../App.css';
import Alerta from '../../components/Alerta/Alerta';
import BarraBotoes from '../../components/BarraBotoes/BarraBotoes';
import Card from '../../components/Card/Card';
import FormText from '../../components/FormText/FormText';
import FormikPadrao from '../../components/FormikPadrao/FormikPadrao';
import InputAutoComplete from '../../components/Inputs/InputAutoComplete/InputAutoComplete';
import InputFormik from '../../components/Inputs/InputFormik/InputFormik';
import InputSelect from '../../components/Inputs/InputSelect/InputSelect';
import InputTexto from '../../components/Inputs/InputTexto/InputTexto';
import Pagina from '../../components/Pagina/Pagina';
import api from '../../services/api';
import { gridColumn } from '../../utils/gridColumn';
import './PlanoContas.css';

function EditarPlanoContas() {
  const navigate = useNavigate();

  const [planoContas, setPlanoContas] = useState({
    DataAlteracao: '0001-01-01',
    CodigoNatureza: 'ContasAtivo',
    TipodeConta: 'Sintetica',
    Nome: '',
    Tipo: 'Receita',
    Hierarquia: '',
    CodigoGrupo: '',
    GrupoDRE: '',
    GrupoDREID: '',
    PlanoPaiId: '',
    OrdemNivel: 0,
    Id: '',
  });
  const [loading, setLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false });
  const [alertStatus, setAlertStatus] = useState('success');
  const [alertTitle, setAlertTitle] = useState('');

  let { id } = useParams();

  useEffect(() => {
    async function GetPlanoContas() {
      let payload = {
        database: localStorage.getItem('database'),
        data: id,
      };
      let res = await api.post('/EditPlanoContas/Get', payload);
      if (res.status === 200) {
        let data = res.data;
        setPlanoContas(data.PlanoContas);
        setLoading(false);
      } else {
        alert('Erro ao buscar dados');
      }
    }
    GetPlanoContas();
  }, [id]);

  let save = async (value ,hierarquia) => {
    let payload = {
      data: value,
      arg: hierarquia,
      database: localStorage.getItem('database'),
    };
    let res = await api.post('/EditPlanoContas/Save', payload);
    let data = res.data;
    if (data.Success) {
      setAlertStatus('success');
      setAlertTitle(data.MSG);
      onOpen();
    } else {
      setAlertStatus('error');
      setAlertTitle(data.MSG);
      onOpen();
    }
  };

  return (
    <>
      <Alerta
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        alertStatus={alertStatus}
        title={alertTitle}
      />
      <Pagina loading={loading}>
        <FormikPadrao
          enableReinitialize={true}
          initialValues={planoContas}
          validationSchema={yup.object({
            // Hierarquia: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
            // Nome: yup.string().required('O campo é obrigatório.').nullable(),
            // CodigoNatureza: yup.string().required('O campo é obrigatório.').nullable(),
            // TipodeConta: yup.string().required('O campo é obrigatório.').nullable(),
            // GrupoDRE: yup.string().required('O campo é obrigatório.').nullable(),
          })}
          onSubmit={async values => {
            
            await save(JSON.stringify(values, null, 2), values.Hierarquia);
          }}
        >
          <Interno navigate={navigate} />
        </FormikPadrao>
      </Pagina>
    </>
  );
}

function Interno({ navigate, ...props }) {
  const [planoContasPai, setPlanoContasPai] = useState('');
  const optionsTipo = ['Receita', 'Despesa'];
  const optionsTipoConta = ['S-Sintética', 'A-Analítica'];
  const optionsNatureza = [
    '01-Contas de Ativo',
    '02-Contas de Passivo',
    '03-Patrimônio Líquido',
    '04-Contas de Resultado',
    '05-Contas de Compensação',
    '09-Outras',
  ];
  const {
    values: { Hierarquia, Id },
    setFieldValue,
  } = useFormikContext();

  let titulo = `${Id !== '' ? 'Editar' : 'Novo'
    } Plano de Contas`;

  useEffect(() => {
    let GerHierarquia = async () => {
      let payload = {
        data: Hierarquia,
        arg: Id,
        database: localStorage.getItem('database'),
      };
      let res = await api.post('/EditPlanoContas/GetHierarquia', payload);
      let data = res.data;
      if (data.Path.length > 0) {
        setPlanoContasPai(data.Path);
        if (data.PaiTipo) {
          setFieldValue('Tipo', data.PaiTipo);
        }
      }
    };
    GerHierarquia(Hierarquia);
  }, [Hierarquia, Id, setFieldValue]);

  return (
    <>
      <BarraBotoes
        botoes={[
          {
            variant: 'vermelho',
            onClick: () => navigate('/PlanoContas/Listar'),
            children: 'Cancelar',
          },
          {
            variant: 'turquesa',
            type: 'submit',
            children: 'Salvar',
          },
        ]}
        caminho={[
          { nome: 'Planos de Contas', path: '/PlanoContas/Listar' },
          { nome: titulo },
        ]}
      />

      <Card titulo="NOVO PLANO DE CONTAS">
        <InputFormik
          titulo="Hierarquia"
          id="Hierarquia"
          name="Hierarquia"
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />
        {planoContasPai.length > 0 && (
          <FormText texto={planoContasPai} gridColumn={gridColumn.gc4} />
        )}

        <InputFormik
          titulo="Nome"
          id="Nome"
          name="Nome"
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />
      </Card>
      <Card titulo="DETALHES FISCAIS">
        <InputFormik
          titulo="Tipo"
          id="Tipo"
          name="Tipo"
          component={InputSelect}
          gridColumn={gridColumn.gc4}
          options={optionsTipo}
        />
        <InputFormik
          titulo="Natureza"
          id="CodigoNatureza"
          name="CodigoNatureza"
          component={InputSelect}
          gridColumn={gridColumn.gc4}
          options={optionsNatureza}
        />
        <InputFormik
          titulo="Tipo de Conta"
          id="TipodeConta"
          name="TipodeConta"
          component={InputSelect}
          gridColumn={gridColumn.gc4}
          options={optionsTipoConta}
        />
        <InputFormik
          titulo="Grupo DRE"
          id="GrupoDRE"
          name="GrupoDRE"
          component={InputAutoComplete}
          gridColumn={gridColumn.gc4}
          path="GrupoDRE"
          autocomplete={true}
          database={localStorage.getItem('database')}
        />
      </Card>
    </>
  );
}

export default EditarPlanoContas;
